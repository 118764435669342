import SignOutPage from "./SignOutPage";
import i18n from "../../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "signOutPage", en);
i18n.addResourceBundle("ru", "signOutPage", ru);
i18n.addResourceBundle("kz", "signOutPage", kz);

const SignOutConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {
        navbar: {
          show: false,
          logo_position: "left",
        },
        footer: false,
      },
    },
  },
  auth: null,
  routes: [
    {
      path: "sign-out",
      element: <SignOutPage />,
    },
  ],
};

export default SignOutConfig;
