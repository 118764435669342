import i18n from "../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "layoutMain", en);
i18n.addResourceBundle("ru", "layoutMain", ru);
i18n.addResourceBundle("kz", "layoutMain", kz);

const config = {
  title: "Main Layout",
  defaults: {
    navbar: {
      show: true,
      isLoginForm: false,
    },
    footer: true,
  },
};

export default config;
