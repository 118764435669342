import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { step1Schema, step2Schema } from "./validationSchemas";
import { ListItemIcon } from "@mui/material";
import { Link } from "react-router-dom";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";

import "./sign-up.css";
import jwtService from "../../../auth/services/jwtService";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import LanguageSwitcher from "../../../theme-layouts/layout-main/components/LanguageSwitcher";
import { useTranslation } from "react-i18next";

const SignUp = () => {
  const [step, setStep] = useState(1);
  const { t } = useTranslation("signUpPage");
  const resolver = step === 1 ? step1Schema : step2Schema;
  const {
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(resolver),
    defaultValues: {
      userAgreement: false,
    },
  });

  const onSubmit = ({ phone, name, location, email, nameCompany }) => {
    // console.log(123);
    if (step === 2) {
      // Remove mask from phone number and account number
      phone = phone.replace(/\D/g, "");
      const data = {
        phone,
        name,
        location,
        email,
        nameCompany,
      };

      // Make call to jwtService and create user with data
      jwtService
        .createUser(data)
        .then((user) => {
          clearErrors("server");
          console.log("User created:", user);
        })
        .catch((_error) => {
          console.log("Error:", _error);
          setError("server", {
            type: "server",
            message: _error,
          });
        });
    } else {
      console.log("current step:", step);
      setStep((prevStep) => prevStep + 1);
    }
  };

  const goBack = () => {
    if (step > 1) setStep((prevStep) => prevStep - 1);
  };

  function mockCreateUser() {
    console.log("mockCreateUser");
    const data = {
      phone: "77014511844",
      password: "159951",
      name: "dev",
      location: "Location 1",
      accountNumber: "111111111",
      email: "great_alexe83g@mail.ru",
      news: true,
    };
    jwtService
      .createUser(data)
      .then((user) => {
        clearErrors("server");
        console.log("User created:", user);
      })
      .catch((_error) => {
        console.log("Error:", _error);
        setError("server", {
          type: "server",
          message: _error,
        });
      });
  }

  return (
    <div className="signUp">
      <div className="signUp__wrap">
        <div className="auth_header">
          <img src="/images/logo.svg" alt="logo" />
          <div style={{ display: "contents" }}>
            <LanguageSwitcher />
          </div>
        </div>
        <div className="sighIn__content">
          <h1 className="signUp__title">{t("TITLE")}</h1>

          {/* <div className="server-error">
						{errors.server && <p>{errors.server.message}</p>}
					</div> */}

          <p className="sighUp__subtitle">{t("SUBTITLE")}</p>

          <form onSubmit={handleSubmit(onSubmit)}>
            {step === 1 && <Step1 control={control} errors={errors} />}
            {step === 2 && <Step2 control={control} errors={errors} />}

            <div className="btnWrap">
              {step > 1 && (
                <button className={"btnBack"} type="button" onClick={goBack}>
                  <ListItemIcon style={{ minWidth: "25px" }}>
                    <KeyboardBackspaceIcon />
                  </ListItemIcon>
                  <span>{t("BUTTONS.GO_BACK")}</span>
                </button>
              )}
              <button
                className={step === 1 ? "btnNext" : "btnOrange"}
                type="submit"
              >
                {step === 1 ? t("BUTTONS.NEXT") : t("BUTTONS.SEND")}
              </button>
            </div>
          </form>

          {step === 1 && (
            <div className="signUp__footer">
              <p className="sighUp__subtitle-footer">{t("FOOTER")}</p>
              <Link className="link_register" to="/">
                {t("SIGN_IN")}
              </Link>
            </div>
          )}

          {step === 2 && (
            <div className="political">
              <p>
                {t("POLITICAL.TITLE")} <br />{" "}
                <Link href="#">{t("POLITICAL.CONFIDENTIALITY")} </Link>{" "}
                {t("POLITICAL.AND")}{" "}
                <Link href="#">{t("POLITICAL.TERMS")} </Link>.
              </p>
            </div>
          )}
        </div>
      </div>
      <img className="bg-img" src="images/sign-in-bg.png" alt="BG" />
    </div>
  );
};

export default SignUp;
