const locale = {
  TITLE: "Настройки профиля",
  EMPLOYEES: "Сотрудники",
  ACCOUNT_INFO: "Данные аккаунта",
  ERROR: "Error",
  FULL_NAME: "ФИО",
  POSITION: "Должность",
  PHONE_NO: "Номер телефона",
  NEW_EMPLOYEE: "Новый сотрудник",
  EDIT_EMPLOYEE: "Редактировать данные сотрудника",
  ADD_NEW_EMPLOYEE: "Добавить нового сотрудника",
  DELETE_EMPLOYEE: "Удалить сотрудника ?",
  DELETE_EMPLOYEE_DESCRIPTION: "Сотрудник {{full_name}} будет удален",
  CANCEL_BTN: "Отмена",
  DELETE_BTN: "Удалить",
  ACCOUNT_INFO_TAB: {
    EMAIL: "Email",
    PASSWORD: "Пароль",
    CHANGE: "Изменить",
    CHANGE_EMAIL: "Изменить email",
    CHANGE_PASSWORD: "Изменить пароль",
  },
  FORM_MODAL: {
    ENTER_FULL_NAME: "Введите ФИО сотрудника",
    SELECT_POSITION: "Выберите должность",
    DIRECTOR: "Директор",
    ENGINEER: "Инженер",
    OPERATOR: "Оператор",
    DISPATCHER: "Диспетчер",
    SAVE: "Сохранить",
    SEND: "Отправить",
  },
  FORM_CHANGE: {
    SAVE: "Сохранить",
    EMAIL: {
      NEW: "Новый email",
      NEW_PLACEHOLDER: "Введите новый email",
      CONFIRM: "Повторите email",
      CONFIRM_PLACEHOLDER: "Введите новый email еще раз",
      CURRENT_PASSWORD: "Пароль от аккаунта",
      CURRENT_PASSWORD_PLACEHOLDER: "Введите пароль от аккаунта",
    },
    PASSWORD: {
      CURRENT: "Текущий пароль",
      CURRENT_PLACEHOLDER: "Введите пароль",
      NEW: "Новый пароль",
      NEW_PLACEHOLDER: "Не менее 8 символов",
      REPEAT: "Повторите пароль",
    },
    VALIDATION: {
      EMAIL: "Адрес электронной почты должен содержать сивол «@».",
      REQUIRED: "Обязательное поле",
      EMAIL_NO_MATCH: "Адреса электронной почты не совпадают.",
      WRONG_PASSWORD: "Неверный текущий пароль",
      PASSWORD_LENGTH: "Пароль должен содержать не менее 8 символов",
      PASSWORD_NO_MATCH: "Пароли должны совпадать",
    },
  },
  UPDATE: {
    LOADING: "Обновление данных, пожалуйста подождите...",
    SUCCESS: "Данные успешно обновлены",
    ERROR: "Ошибка обновления данных",
  },
  ADD: {
    LOADING: "Добавление данных, пожалуйста подождите...",
    SUCCESS: "Данные успешно добавлены",
    ERROR: "Ошибка добавления данных",
  },
  DELETE: {
    LOADING: "Удаление данных, пожалуйста подождите...",
    SUCCESS: "Данные успешно удалены",
    ERROR: "Ошибка удаления данных",
  },
  EMAIL_CHANGE: {
    LOADING: "Изменение email, пожалуйста подождите...",
    SUCCESS: "Email успешно изменен",
    ERROR: "Ошибка изменения email",
  },
  PASSWORD_CHANGE: {
    LOADING: "Изменение пароля, пожалуйста подождите..",
    SUCCESS: "Пароль успешно изменен",
    ERROR: "Ошибка изменения пароля",
  },
};
export default locale;
