import DiagramLocale from "../../../../UI/Diagram/i18n/kz";
import CustomTableLocale from "../../../../UI/CustomTable/i18n/kz";
import FormExportLocale from "../../../../UI/FormExport/i18n/kz";

const locale = {
  NO_ACTUAL_DATA: "Ағымдағы деректер жоқ",
  NO_DATA: "Таңдалған күні үшін деректер жоқ. Кез келген қолайсыздық үшін кешірім сұраймыз.",
  TODAY: "Бүгін",
  TOMORROW: "Ертең",
  OTHER_DATE: "Басқа күн",
  CHART: "Диаграмма",
  TABLE: "Кесте",
  ACCURACY: "Нақтылық",
  TITLE: "Метеодеректер",
  CURRENT_DATA: "Ағымдағы метеодеректер",
  NO_CURRENT_DATA: "Ағымдағы метеодеректер жоқ",
  TABS: {
    TEMPERATURE: "Температура",
    IRRADIANCE: "Иррадиация",
    CLOUDINESS: "Бұлттылық",
    WIND_SPEED: "Жел жылдамдығы",
    PRECIPITATION: "Жауын-шашын мөлшері",
    TEMPERATURE_PANEL: "Температура ФЭМ"
  },
  IRRADIANCE_UNIT: "кВт-сағ/м²",
  WIND_SPEED_UNIT: "м/с",
  PRECIPITATION_UNIT: "мм",
  EXPORT_TABLE: "Кестені экспорттау",
  ...DiagramLocale,
  ...CustomTableLocale,
  ...CustomTableLocale,
};
export default locale;
