import "./styles/globals.css";
// import "../@mock-api";
import withAppProviders from "./withAppProviders";
import BrowserRouter from "../@fuse/core/BrowserRouter";
import FuseLayout from "../@fuse/core/FuseLayout";
import themeLayouts from "./theme-layouts/themeLayouts";
import { AuthProvider } from "./auth/AuthContext";
import { useSelector } from "react-redux";
import { selectUser } from "./store/user/userThunks";
import settingsConfig from "./configs/settingsConfig";
import FuseAuthorization from "../@fuse/core/FuseAuthorization";
import { SnackbarProvider } from "notistack";

import axios from "axios";
axios.defaults.baseURL = "https://api.intech-forecast.com";

function App() {
  const user = useSelector(selectUser);
  
  return (
    <AuthProvider>
      <BrowserRouter>
        <FuseAuthorization
          userRole={user.role}
          loginRedirectUrl={settingsConfig.loginRedirectUrl}
        >
          <SnackbarProvider
            autoHideDuration={1000}
            maxSnack={5}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            classes={{
              containerRoot:
                "bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99",
            }}
          >
            <FuseLayout layouts={themeLayouts} />
          </SnackbarProvider>
        </FuseAuthorization>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default withAppProviders(App)();
