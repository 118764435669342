import React, { useState } from "react";
import { Box, Tab, Tabs } from "@mui/material";
import { tabStyle } from "../../../../utils/stylesForMui";
import { tabsPower } from "../../../../utils/contstants/tabs";
import DayPowerStats from "./tabs/DayPowerStats";
import MonthOrYearPowerStats from "./tabs/MonthOrYearPowerStats";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";

const PowerGeneration = () => {
  const [activeTab, setActiveTab] = useState("day");
  const theme = useTheme();
  const { t } = useTranslation("powerGenerationPage");

  return (
    <div className="container-inner">
      <div className="DashboardMainConfig">
        <h1 className="title">{t("TITLE")}</h1>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "40px",
            maxWidth: "100vw", // Set maxWidth to 100vw
            overflowX: "auto",
          }}
        >
          <Tabs
            value={activeTab}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{
              "& .MuiTabs-indicator": {
                background: "#F07048",
              },
              // Add the following media query styles
              [theme.breakpoints.down("sm")]: {
                "& .MuiTabs-scroller": {
                  overflowX: "auto",
                },
                "& .MuiTabs-flexContainer": {
                  flexWrap: "nowrap",
                },
              },
            }}
          >
            {tabsPower.map((tab) => (
              <Tab
                sx={tabStyle}
                key={tab.value}
                value={tab.value}
                style={{ marginRight: "20px" }}
                label={t(tab.name)}
              />
            ))}
          </Tabs>
        </Box>

        {activeTab === "day" && <DayPowerStats />}
        {activeTab === "monthly" && (
          <MonthOrYearPowerStats activeTab={activeTab} />
        )}
        {activeTab === "yearly" && (
          <MonthOrYearPowerStats activeTab={activeTab} />
        )}
      </div>
    </div>
  );
};

export default PowerGeneration;
