import { authRoles } from "../../../auth";
import InfoEmployees from "./InfoEmployees";
import i18n from "../../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "infoEmployeesPage", en);
i18n.addResourceBundle("ru", "infoEmployeesPage", ru);
i18n.addResourceBundle("kz", "infoEmployeesPage", kz);

const InfoEmployeesConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/user/info-employees",
      element: <InfoEmployees />,
    },
  ],
};

export default InfoEmployeesConfig;
