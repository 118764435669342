import React, { useState } from "react";
import "./customTable.css";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableFooter,
  TablePagination,
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { tableHeaderCell } from "../../../utils/stylesForMui";

import { useLocation } from "react-router-dom";
import { getArrNotNull, total } from "../../../utils/utilsFunc";

const styleCell = {
  color: "#272933",
  fontWeight: 400,
  background: "white",
  textAlign: "center",
};

const CustomTable = ({
  data,
  type,
  date,
  totalActual,
  totalPrediction,
  apd,
  day,
  meteodataTab,
  t,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const months = [
    t("MONTHS.JANUARY"),
    t("MONTHS.FEBRUARY"),
    t("MONTHS.MARCH"),
    t("MONTHS.APRIL"),
    t("MONTHS.MAY"),
    t("MONTHS.JUNE"),
    t("MONTHS.JULY"),
    t("MONTHS.AUGUST"),
    t("MONTHS.SEPTEMBER"),
    t("MONTHS.OCTOBER"),
    t("MONTHS.NOVEMBER"),
    t("MONTHS.DECEMBER"),
  ];

  // ------------------------------
  const calculateAccuracy = (actualTotal, predictionTotal) => {
    if (actualTotal !== 0) {
      return Math.max(
        0,
        100 -
          (Math.abs(actualTotal - predictionTotal) /
            Math.abs((actualTotal + predictionTotal) / 2)) *
            100,
      ).toFixed(2);
    } else {
      return 0;
    }
  };

  const numOfNotFilledActualValues = data
    ?.find((item) => item.name === "Actual")
    ?.series.reduce(
      (accumulator, obj) =>
        obj.value !== null ? parseInt(accumulator) + 1 : accumulator,
      0,
    );

  const accuracies = data
    .map((model) => {
      if (model?.name !== "Actual") {
        const actualDataPrediction = model?.series?.slice(
          0,
          numOfNotFilledActualValues,
        );

        const totalPredictionForModel = actualDataPrediction?.reduce(
          (acc, val) => acc + parseInt(val.value),
          0,
        );

        const accuracy = calculateAccuracy(
          totalActual,
          totalPredictionForModel,
        );

        return {
          name: model.name,
          accuracy: accuracy,
        };
      }
      return null;
    })
    .filter(Boolean); // to remove the null values corresponding to the "actual" model

  const getAccuracy = (name) => {
    if (currentPath === "/user/meteodata") {
      return 0;
    }

    const modelAccuracy = accuracies.find((item) => item.name === name);
    return modelAccuracy ? modelAccuracy.accuracy : null;
  };

  const generateHeaders = (data) => {
    if (currentPath === "/user/meteodata") {
      return [
        "HEADER.TIME",
        "HEADER.ACTUAL",
        "HEADER.PREDICTION",
        "HEADER.ACCURACY",
      ];
    }

    const headers = ["HEADER.TIME", "HEADER.ACTUAL"];

    data
      .filter((d) => d.name !== "Actual")
      .forEach((predictionModel) => {
        headers.push(predictionModel.name);
        headers.push(`${predictionModel.name} Accuracy `);
      });

    return headers;
  };

  const headerTable = generateHeaders(data);

  const calculateErrorDeviation = (a, b) => {
    const numA = parseInt(a);
    const numB = parseInt(b);

    return numA === 0 || numB === 0
      ? 0
      : 100 - Math.ceil(Math.abs(((numA - numB) / ((numA + numB) / 2)) * 100));
  };
  // ------------------------------

  const updatedData = data.map((obj) => {
    const updatedObj = { ...obj };

    if (currentPath === "/user/meteodata") {
      if (updatedObj.actual === null) {
        updatedObj.actual = 0;
      }
      return updatedObj;
    }

    if (updatedObj.name === "Actual") {
      updatedObj.series = updatedObj.series.map((item) => {
        if (item.value === null) {
          return { ...item, value: "0" };
        }
        return item;
      });
    }

    return updatedObj;
  });

  const meteodataTableInfo = getArrNotNull(updatedData, meteodataTab, "actual");

  let abbreviation = "";

  if (meteodataTab === "temperature") {
    abbreviation = "°C";
  } else if (meteodataTab === "irradiance") {
    abbreviation = t("IRRADIANCE_UNIT");
  } else if (meteodataTab === "cloudiness") {
    abbreviation = "%";
  } else if (meteodataTab === "wind_speed") {
    abbreviation = t("WIND_SPEED_UNIT");
  } else if (meteodataTab === "precipitation") {
    abbreviation = t("PRECIPITATION_UNIT");
  }
  const [page, setPage] = useState(0);
  const rowsPerPage = 6;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <TableContainer
      sx={{
        borderRadius: "8px",
        border: "1px solid #E7EAF2",
      }}
    >
      <Table className="custom-table" aria-label="simple table">
        <TableHead sx={{ background: "rgba(243, 245, 250, 0.4)" }}>
          <TableRow>
            {headerTable.map((el, i) => {
              return (
                <TableCell sx={tableHeaderCell} key={i}>
                  {t(el)}
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>

        <TableBody>
          {(currentPath === "/user/meteodata"
            ? meteodataTableInfo
            : updatedData.find((d) => d.name === "Actual").series
          )
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((el, i) => {
              if (currentPath !== "/user/meteodata") {
                return (
                  <TableRow key={el.name}>
                    {/* Hour Column */}
                    <TableCell sx={tableHeaderCell} style={styleCell}>
                      {type === "yearly"
                        ? `${months[el.name - 1]} ${date}`
                        : type === "monthly"
                        ? `${el.name} / ${date}`
                        : !day && el.name >= 10
                        ? `${el.name}:00`
                        : `0${el.name}:00`}
                    </TableCell>

                    {/* Actual Value Column */}
                    <TableCell sx={tableHeaderCell} style={styleCell}>
                      {!day
                        ? Math.round(el.value).toLocaleString("ru-RU") +
                          ` ${t("KW_H")}`
                        : "-"}
                    </TableCell>

                    {/* Prediction Models Columns */}
                    {data
                      .filter((model) => model.name !== "Actual") // Exclude the "Actual" model
                      .map((predictionModel) => {
                        const predictionValue =
                          predictionModel.series[i + page * rowsPerPage]
                            ?.value || 0; // Handle missing values

                        return (
                          <React.Fragment key={predictionModel.name}>
                            <TableCell sx={tableHeaderCell} style={styleCell}>
                              {Math.round(predictionValue).toLocaleString(
                                "ru-RU",
                              ) + ` ${t("KW_H")}`}
                            </TableCell>

                            {/* Error Deviation Column */}
                            <TableCell sx={tableHeaderCell} style={styleCell}>
                              {!day
                                ? calculateErrorDeviation(
                                    el.value,
                                    predictionValue,
                                  )
                                    .toFixed(0)
                                    .toLocaleString("ru-RU") + "%"
                                : "-"}
                            </TableCell>
                          </React.Fragment>
                        );
                      })}
                  </TableRow>
                );
              } else {
                return (
                  <TableRow key={el.labels}>
                    <TableCell
                      key={el.labels}
                      sx={tableHeaderCell}
                      style={styleCell}
                    >
                      {i >= 10 ? `${i}:00` : `0${i}:00`}
                    </TableCell>

                    <TableCell sx={tableHeaderCell} style={styleCell}>
                      {Math.round(el.prediction).toLocaleString("ru-RU") +
                        ` ${abbreviation}`}
                    </TableCell>

                    <TableCell sx={tableHeaderCell} style={styleCell}>
                      {!day &&
                        Math.round(el.actual).toLocaleString("ru-RU") +
                          ` ${abbreviation}`}
                    </TableCell>

                    <TableCell sx={tableHeaderCell} style={styleCell}>
                      {!day &&
                        calculateErrorDeviation(el.actual, el.prediction)
                          .toFixed(0)
                          .toLocaleString("ru-RU") + "%"}
                    </TableCell>
                  </TableRow>
                );
              }
            })}
        </TableBody>

        {currentPath !== "/user/meteodata" && (
          <TableFooter>
            <TableRow>
              <TableCell
                sx={tableHeaderCell}
                style={{ color: "#272933", fontWeight: 400 }}
              >
                {t("TOTAL")}
              </TableCell>

              <TableCell
                sx={tableHeaderCell}
                style={{ color: "#272933", fontWeight: 400 }}
              >
                {!day && totalActual.toLocaleString("ru-RU") + t("KW_H")}
              </TableCell>

              {data
                .filter((d) => d.name !== "Actual")
                .map((predictionModel) => {
                  return (
                    <>
                      <TableCell
                        sx={tableHeaderCell}
                        style={{ color: "#272933", fontWeight: 400 }}
                      >
                        {!day && currentPath === "/user/meteodata"
                          ? apd + "%"
                          : total(data, predictionModel.name).toLocaleString(
                              "ru-RU",
                            ) + t("KW_H")}
                      </TableCell>

                      <TableCell
                        sx={tableHeaderCell}
                        style={{ color: "#272933", fontWeight: 400 }}
                      >
                        {!day && getAccuracy(predictionModel.name) + "%"}
                      </TableCell>
                    </>
                  );
                })}
            </TableRow>
          </TableFooter>
        )}

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={headerTable.length}
              count={
                currentPath === "/user/meteodata"
                  ? meteodataTableInfo.length
                  : updatedData.find((d) => d.name === "Actual").series.length
              }
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
};

export default CustomTable;
