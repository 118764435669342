const locale = {
  HEADER: {
    TIME: "Time",
    PREDICTION: "Prediction",
    ACTUAL: "Fact",
    ACCURACY: "Accuracy",
  },
  TOTAL: "Total",
};

export default locale;
