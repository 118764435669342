import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  getTeamMemberService,
  deleteTeamMemberService,
  updateTeamMemberService,
  addTeamMemberService,
} from "../../services/employeeService";

export const selectTeamMember = (state) => state.employee.team_members;

export const getTeamMember = createAsyncThunk(
  "user/getTeamMembers",
  async (_, { rejectWithValue }) => {
    try {
      return await getTeamMemberService();
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const deleteTeamMember = createAsyncThunk(
  "user/deleteTeamMember",
  async (id, { rejectWithValue }) => {
    try {
      const response = await deleteTeamMemberService(id);

      if (response.status === 200) {
        return id;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);

export const updateTeamMember = createAsyncThunk(
  "user/updateTeamMember",
  async ({ id, full_name, position, phone }, { rejectWithValue }) => {
    try {
      const response = await updateTeamMemberService(
        id,
        full_name,
        position,
        phone
      );

      if (response.status === 200) {
        return { id, full_name, position, phone };
      } else {
        return rejectWithValue(response.data);
      }
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const addTeamMember = createAsyncThunk(
  "user/addTeamMember",
  async ({ full_name, position, phone }, { rejectWithValue }) => {
    try {
      return await addTeamMemberService(full_name, position, phone);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
