import authRoles from "../../../auth/authRoles";
import SignUp from "./SignUp";
import i18n from "../../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "signUpPage", en);
i18n.addResourceBundle("ru", "signUpPage", ru);
i18n.addResourceBundle("kz", "signUpPage", kz);

const SignOutConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {
        navbar: {
          show: true,
          isLoginForm: true,
        },
        footer: false,
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/sign-up",
      element: <SignUp />,
    },
  ],
};

export default SignOutConfig;
