const locale = {
  TITLE: "Sign In",
  EMAIL_PLACEHOLDER: "Enter email",
  PASSWORD: "Password",
  PASSWORD_PLACEHOLDER: "Enter password",
  REMEMBER_ME: "Remember me",
  FORGOT_PASSWORD: "Forgot password?",
  SIGN_UP_TEXT:
    "Don't have an account? Contact us to get access or try the demo version",
  SIGN_UP: "Leave a request",
  SIGN_IN: "Sign in",
  VALIDATION: {
    EMAIL_FORMAT: "Invalid email. Please try again.",
    PASSWORD_FORMAT: "Password must contain at least 4 characters",
    REQUIRED: "Required field",
  },
  PARAGRAPH_1:"IT service for forecasting electricity generation by renewable energy facilities",
  PARAGRAPH_2:"Improving the efficiency of the electric power systems of Central Asian countries by generating accurate daily forecasts of electricity production from renewable energy sources"
};
export default locale;
