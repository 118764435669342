import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import ReactInputMask from "react-input-mask";
import { selectRole } from "../../../utils/contstants/selectRole";
import { reactSelectStyle } from "../../../utils/stylesForMui";
import { useDispatch } from "react-redux";
import {
  addTeamMember,
  updateTeamMember,
} from "../../../store/employee/employeeThunks";
import { useTranslation } from "react-i18next";

const FormModal = (props) => {
  const { value, onClose } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation("infoEmployeesPage");
  const translatedOptions = selectRole.map((option) => ({
    ...option,
    label: t(option.label), // Use the translation function to translate each label
  }));
  const position = translatedOptions.find((el) => el.value === value?.position);
  const [selectedRole, setSelectedRole] = useState(position);

  const defaultValues = value;

  const { control, register, handleSubmit, watch } = useForm({
    defaultValues,
  });

  const watchFields = watch(["full_name", "position", "phone"]);
  const isFormValid = watchFields.every((field) => field);

  const onSubmit = (data) => {
    data.id = value?.id;
    data.full_name = data.full_name.trim();
    data.position = selectedRole.value;
    data.phone = data.phone.toString().replace(/\D/g, "");
    // ------------------------------

    value ? dispatch(updateTeamMember(data)) : dispatch(addTeamMember(data));

    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="form-subtitle">{t("FULL_NAME")}</p>
      <input
        className="input"
        type="text"
        name="full_name"
        {...register("full_name")}
        placeholder={t("FORM_MODAL.ENTER_FULL_NAME")}
      />

      <p className="form-subtitle">{t("POSITION")}</p>

      <Controller
        control={control}
        name="position"
        render={({ field }) => {
          return (
            <Select
              {...field}
              styles={reactSelectStyle}
              placeholder={t("FORM_MODAL.SELECT_POSITION")}
              options={translatedOptions}
              value={selectedRole}
              onChange={(selectedOption) => {
                field.onChange(selectedOption.label);
                setSelectedRole(selectedOption);
              }}
            ></Select>
          );
        }}
      />

      <p className="form-subtitle">{t("PHONE_NO")}</p>

      <Controller
        control={control}
        name="phone"
        render={({ field }) => {
          return (
            <ReactInputMask
              {...field}
              value={field.value || ""}
              mask="+7 999 999 99 99"
              className="input"
              placeholder="+7 ___ ___ __ __"
              onChange={(e) => field.onChange(e.target.value)}
            />
          );
        }}
      />
      <button
        disabled={!isFormValid}
        className={!isFormValid ? "btn" : "valid"}
      >
        {value ? t("FORM_MODAL.SAVE") : t("FORM_MODAL.SEND")}
      </button>
    </form>
  );
};

export default FormModal;
