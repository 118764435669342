import { authRoles } from "../../../auth";
import InfoStation from "./InfoStation";
import i18n from "../../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "infoStationPage", en);
i18n.addResourceBundle("ru", "infoStationPage", ru);
i18n.addResourceBundle("kz", "infoStationPage", kz);

const InfoStationConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/user/info-station",
      element: <InfoStation />,
    },
  ],
};

export default InfoStationConfig;
