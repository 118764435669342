import jwtService from "../auth/services/jwtService";

export const updateEmailService = async (email, password) => {
  try {
    return await jwtService.updateEmail(email, password);
  } catch (err) {
    return err;
  }
};

export const updatePasswordService = async (password, newPassword) => {
  try {
    return await jwtService.updatePassword(password, newPassword);
  } catch (err) {
    return err;
  }
};
