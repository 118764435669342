import { authRoles } from "../../../../auth";
import Monitoring from "./Monitoring";
import i18n from "./../../../../../i18n";
import kz from "./i18n/kz";
import ru from "./i18n/ru";
import en from "./i18n/en";

i18n.addResourceBundle("kz", "monitoringPage", kz);
i18n.addResourceBundle("ru", "monitoringPage", ru);
i18n.addResourceBundle("en", "monitoringPage", en);

const MonitoringConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/user/monitoring",
      element: <Monitoring />,
    },
  ],
};

export default MonitoringConfig;
