import { Navigate } from "react-router-dom";
import errorPagesConfig from "../pages/error/ErrorPageConfig";
import settingsConfig from "./settingsConfig";
import FuseUtils from "../../@fuse/utils";
import SignInConfig from "../pages/auth/sign-in/SignInConfig";
import PerformanceConfig from "../pages/dashboard/main/Performance/PerformanceConfig";
import SignOutConfig from "../pages/auth/sign-out/SignOutConfig";
import SignUpConfig from "../pages/auth/sign-up/SignUpConfig";
import MeteodataConfig from "../pages/dashboard/main/Meteodata/MeteodataConfig";
import InfoStationConfig from "../pages/profile/info-station/InfoStationConfig";
import InfoEmployeesConfig from "../pages/profile/info-employees/InfoEmployeesConfig";
import PowerGenerationConfig from "../pages/dashboard/main/PowerGeneration/PowerGenerationConfig";
import forgotPasswordPagesConfig from "../pages/auth/forgot-password/forgotPasswordPagesConfig";
import MonitoringConfig from "../pages/dashboard/main/Monitoring/MonitoringConfig";

const routeConfigs = [
  SignInConfig,
  SignOutConfig,
  SignUpConfig,
  MeteodataConfig,
  PerformanceConfig,
  PowerGenerationConfig,
  InfoStationConfig,
  InfoEmployeesConfig,
  forgotPasswordPagesConfig,
  errorPagesConfig,
  MonitoringConfig
];

const routes = [
  ...FuseUtils.generateRoutesFromConfigs(
    routeConfigs,
    settingsConfig.defaultAuth
  ),
  {
    path: "*",
    element: <Navigate to="pages/error/404" />,
  },
];

export default routes;
