import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import _ from "../../../../@lodash";
import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import ru from "date-fns/locale/ru";
import "./form-export.css";
import { datePickerExportStyle } from "../../../utils/stylesForMui";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import { useLocation } from "react-router-dom";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import axios from "axios";
import { format } from "date-fns";

import { allFieldNames, months } from "../../../utils/contstants/formExport";
import { useSelector } from "react-redux";
import { downloadDayOrMonthStats } from "./downloadDayOrMonthStats";
import { downloadYearStats } from "./downloadYearStats";
import { useSnackbar } from "notistack";

const FormExport = (props) => {
  const {
    date,
    activeTab,
    meteodataTab,
    performanceTab,
    isPlannedChecked,
    isActualChecked,
    isMeteologicaChecked,
    t,
    currentLocale,
  } = props;

  const location = useLocation();
  const { mainStationInfo } = useSelector((state) => state.dashboard);

  const { enqueueSnackbar } = useSnackbar();

  const [selectedOption, setSelectedOption] = useState(activeTab);
  const [selectedDate, setSelectedDate] = useState(date);
  const [selectedYearsAndMonth, setSelectedYearsAndMonth] = useState(
    new Date(date.getFullYear(), date.getMonth(), 1),
  );
  const [selectedYears, setSelectedYears] = useState(date);

  const [open, setOpen] = useState(false);
  const [openPowerGeneration, setOpenPowerGeneration] = useState(false);
  const [openStationIndicators, setOpenStationIndicators] = useState(false);
  const [openMeteodata, setOpenMeteodata] = useState(false);

  const [selectedEndDate, setSelectedEndDate] = useState(null);
  const [endPickerOpen, setEndPickerOpen] = useState(false);

  const initialCheckboxValues = {
    powerGeneration: location.pathname === "/user/power-generation",
    plannedOutput:
      location.pathname === "/user/power-generation" && isPlannedChecked,
    actualOutput:
      location.pathname === "/user/power-generation" && isActualChecked,
    meteologicaOutput:
      location.pathname === "/user/power-generation" && isMeteologicaChecked,
    meteodata: location.pathname === "/user/meteodata", // Add more initial values for other checkboxes based on location
    temperature: meteodataTab === "temperature",
    tempForecast: meteodataTab === "temperature" && isPlannedChecked,
    tempFact: meteodataTab === "temperature" && isActualChecked,
    irradiation: meteodataTab === "irradiance",
    irradForecast: meteodataTab === "irradiance" && isPlannedChecked,
    irradFact: meteodataTab === "irradiance" && isActualChecked,
    cloudy: meteodataTab === "cloudiness",
    cloudForecast: meteodataTab === "cloudiness" && isPlannedChecked,
    cloudFact: meteodataTab === "cloudiness" && isActualChecked,
    windSpeed: meteodataTab === "wind_speed",
    wsForecast: meteodataTab === "wind_speed" && isPlannedChecked,
    wsFact: meteodataTab === "wind_speed" && isActualChecked,
    precipitation: meteodataTab === "precipitation",
    precForecast: meteodataTab === "precipitation" && isPlannedChecked,
    precFact: meteodataTab === "precipitation" && isActualChecked,

    stationIndicators: location.pathname === "/user/station-performance",
    profit: performanceTab === "revenue",
    profitForecast: performanceTab === "revenue",
    profitForecastMeteologica: performanceTab === "revenue",
    profitFact: performanceTab === "revenue",
    KIOM: performanceTab === "kium",
    KIOMForecast: performanceTab === "kium",
    KIOMFact: performanceTab === "kium",
    KIOMForecastMeteologica: performanceTab === "kium",
    energyConsumption: performanceTab === "energy_consumption",
    energyConsumptionForecast: performanceTab === "energy_consumption",
    energyConsumptionForecastMeteologica:
      performanceTab === "energy_consumption",
    energyConsumptionFact: performanceTab === "energy_consumption",
    reductionOfCO2Emissions: performanceTab === "emissions",
    reductionOfCO2EmissionsForecast: performanceTab === "emissions",
    reductionOfCO2EmissionsForecastMeteologica: performanceTab === "emissions",
    reductionOfCO2EmissionsFact: performanceTab === "emissions",
  };

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm();

  const powerGeneration = watch("powerGeneration") || false;
  const plannedOutput = watch("plannedOutput") || false;
  const actualOutput = watch("actualOutput") || false;
  const meteologicaOutput = watch("meteologicaOutput") || false;
  const stationIndicators = watch("stationIndicators") || false;
  const profit = watch("profit") || false;
  const profitForecast = watch("profitForecast") || false;
  const profitForecastMeteologica = watch("profitForecastMeteologica") || false;
  const profitFact = watch("profitFact") || false;
  const KIOM = watch("KIOM") || false;
  const KIOMForecast = watch("KIOMForecast") || false;
  const KIOMForecastMeteologica = watch("KIOMForecastMeteologica") || false;
  const KIOMFact = watch("KIOMFact") || false;
  const energyConsumption = watch("energyConsumption") || false;
  const energyConsumptionForecast = watch("energyConsumptionForecast") || false;
  const energyConsumptionFact = watch("energyConsumptionFact") || false;
  const reductionOfCO2Emissions = watch("reductionOfCO2Emissions") || false;
  const reductionOfCO2EmissionsForecast =
    watch("reductionOfCO2EmissionsForecast") || false;
  const reductionOfCO2EmissionsForecastMeteologica =
    watch("reductionOfCO2EmissionsForecastMeteologica") || false;
  const reductionOfCO2EmissionsFact =
    watch("reductionOfCO2EmissionsFact") || false;

  const meteodata = watch("meteodata") || false;
  const temperature = watch("temperature") || false;
  const tempForecast = watch("tempForecast") || false;
  const tempFact = watch("tempFact") || false;

  const irradiation = watch("irradiation") || false;
  const irradForecast = watch("irradForecast") || false;
  const irradFact = watch("irradFact") || false;

  const cloudy = watch("cloudy") || false;
  const cloudForecast = watch("cloudForecast") || false;
  const cloudFact = watch("cloudFact") || false;

  const windSpeed = watch("windSpeed") || false;
  const wsForecast = watch("wsForecast") || false;
  const wsFact = watch("wsFact") || false;

  const precipitation = watch("precipitation") || false;
  const precForecast = watch("precForecast") || false;
  const precFact = watch("precFact") || false;

  const watchFields = watch(
    location.pathname !== "/user/meteodata"
      ? ["powerGeneration", "stationIndicators"]
      : ["powerGeneration", "meteodata"],
  );

  const [disableMonthYearSelector, setDisableMonthYearSelector] =
    useState(false);
  const [disableDateSelector, setDisableDateSelector] = useState(
    stationIndicators && selectedOption === "selectDate",
  );
  const isFormValid = watchFields.some((field) => field);

  const powerGenerationArr = [
    {
      mainCheckbox: t("POWER_GENERATION_ARR.POWER_GENERATION"),
      mainName: "powerGeneration",
      watched: powerGeneration,
      children: [
        {
          mainCheckbox: "Intech",
          mainName: "plannedOutput",
          watched: plannedOutput,
        },
        {
          mainCheckbox: t("POWER_GENERATION_ARR.ACTUAL_OUTPUT"),
          mainName: "actualOutput",
          watched: actualOutput,
        },
        {
          mainCheckbox: "Meteologica",
          mainName: "meteologicaOutput",
          watched: meteologicaOutput,
        },
      ],
    },
  ];

  const stationIndicatorsArr = [
    {
      mainCheckbox: t("STATION_INDICATORS_ARR.STATION_INDICATORS"),
      mainName: "stationIndicators",
      watched: stationIndicators,
      children: [
        {
          mainCheckbox: t("STATION_INDICATORS_ARR.PROFIT"),
          mainName: "profit",
          watched: profit,
          children: [
            {
              mainCheckbox: "Intech",
              mainName: "profitForecast",
              watched: profitForecast,
            },
            {
              mainCheckbox: t("STATION_INDICATORS_ARR.FACT"),
              mainName: "profitFact",
              watched: profitFact,
            },
            {
              mainCheckbox: "Meteologica",
              mainName: "profitForecastMeteologica",
              watched: profitForecastMeteologica,
            },
          ],
        },
        {
          mainCheckbox: t("STATION_INDICATORS_ARR.CUF"),
          mainName: "KIOM",
          watched: KIOM,
          children: [
            {
              mainCheckbox: "Intech",
              mainName: "KIOMForecast",
              watched: KIOMForecast,
            },
            {
              mainCheckbox: t("STATION_INDICATORS_ARR.FACT"),
              mainName: "KIOMFact",
              watched: KIOMFact,
            },
            {
              mainCheckbox: "Meteologica",
              mainName: "KIOMForecastMeteologica",
              watched: KIOMForecastMeteologica,
            },
          ],
        },
        // {
        //   mainCheckbox: t("STATION_INDICATORS_ARR.ENERGY_CONSUMPTION"),
        //   mainName: "energyConsumption",
        //   watched: energyConsumption,
        //   children: [
        //     {
        //       mainCheckbox: t("STATION_INDICATORS_ARR.FORECAST"),
        //       mainName: "energyConsumptionForecast",
        //       watched: energyConsumptionForecast,
        //     },
        //     {
        //       mainCheckbox: t("STATION_INDICATORS_ARR.FACT"),
        //       mainName: "energyConsumptionFact",
        //       watched: energyConsumptionFact,
        //     },
        //   ],
        // },
        {
          mainCheckbox: t("STATION_INDICATORS_ARR.EMISSIONS_REDUCTION"),
          mainName: "reductionOfCO2Emissions",
          watched: reductionOfCO2Emissions,
          children: [
            {
              mainCheckbox: "Intech",
              mainName: "reductionOfCO2EmissionsForecast",
              watched: reductionOfCO2EmissionsForecast,
            },
            {
              mainCheckbox: t("STATION_INDICATORS_ARR.FACT"),
              mainName: "reductionOfCO2EmissionsFact",
              watched: reductionOfCO2EmissionsFact,
            },
            {
              mainCheckbox: "Meteologica",
              mainName: "reductionOfCO2EmissionsForecastMeteologica",
              watched: reductionOfCO2EmissionsForecastMeteologica,
            },
          ],
        },
      ],
    },
  ];

  const meteodataArr = [
    {
      mainCheckbox: t("METEODATA_ARR.METEODATA"),
      mainName: "meteodata",
      watched: meteodata,
      children: [
        {
          mainCheckbox: t("METEODATA_ARR.TEMPERATURE"),
          mainName: "temperature",
          watched: temperature,
          children: [
            {
              mainCheckbox: t("METEODATA_ARR.FORECAST"),
              mainName: "tempForecast",
              watched: tempForecast,
            },
            {
              mainCheckbox: t("METEODATA_ARR.FACT"),
              mainName: "tempFact",
              watched: tempFact,
            },
          ],
        },
        {
          mainCheckbox: t("METEODATA_ARR.IRRADIANCE"),
          mainName: "irradiation",
          watched: irradiation,
          children: [
            {
              mainCheckbox: t("METEODATA_ARR.FORECAST"),
              mainName: "irradForecast",
              watched: irradForecast,
            },
            {
              mainCheckbox: t("METEODATA_ARR.FACT"),
              mainName: "irradFact",
              watched: irradFact,
            },
          ],
        },
        {
          mainCheckbox: t("METEODATA_ARR.CLOUDINESS"),
          mainName: "cloudy",
          watched: cloudy,
          children: [
            {
              mainCheckbox: t("METEODATA_ARR.FORECAST"),
              mainName: "cloudForecast",
              watched: cloudForecast,
            },
            {
              mainCheckbox: t("METEODATA_ARR.FACT"),
              mainName: "cloudFact",
              watched: cloudFact,
            },
          ],
        },
        {
          mainCheckbox: t("METEODATA_ARR.WIND_SPEED"),
          mainName: "windSpeed",
          watched: windSpeed,
          children: [
            {
              mainCheckbox: t("METEODATA_ARR.FORECAST"),
              mainName: "wsForecast",
              watched: wsForecast,
            },
            {
              mainCheckbox: t("METEODATA_ARR.FACT"),
              mainName: "wsFact",
              watched: wsFact,
            },
          ],
        },
        {
          mainCheckbox: t("METEODATA_ARR.PRECIPITATION"),
          mainName: "precipitation",
          watched: precipitation,
          children: [
            {
              mainCheckbox: t("METEODATA_ARR.FORECAST"),
              mainName: "precForecast",
              watched: precForecast,
            },
            {
              mainCheckbox: t("METEODATA_ARR.FACT"),
              mainName: "precFact",
              watched: precFact,
            },
          ],
        },
      ],
    },
  ];

  const disableDate = (date) =>
    date > new Date(Date.now() + 1 * 24 * 60 * 60 * 1000);

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
    setValue("selectRadio", e.target.value);
  };

  const handleEndDateChange = (newValue) => {
    setSelectedEndDate(newValue);
    if (selectedOption === "selectMonth") {
      setSelectedEndDate(
        new Date(newValue.getFullYear(), newValue.getMonth() + 1, 0),
      );
    }
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);

    setSelectedYearsAndMonth(new Date(date.getFullYear(), date.getMonth(), 1));
    setSelectedYears(date);

    setValue("selectedDate", date);
  };

  useEffect(() => {
    setValue("powerGeneration", initialCheckboxValues.powerGeneration);
    setValue("stationIndicators", initialCheckboxValues.stationIndicators);
    setValue("meteodata", initialCheckboxValues.meteodata);

    allFieldNames.forEach((name) => {
      setValue(name, initialCheckboxValues[name]);
    });
  }, []);

  useEffect(() => {
    setDisableDateSelector(
      stationIndicators && selectedOption === "selectDate",
    );
  }, [stationIndicators, selectedOption]);

  useEffect(() => {
    setDisableMonthYearSelector(
      meteodata &&
        (selectedOption === "selectMonth" || selectedOption === "selectYear"),
    );
  }, [meteodata, selectedOption]);

  const handleMainCheckboxChange = (fieldName, isChecked, children) => {
    setValue(fieldName, isChecked);

    children.forEach((child) => {
      child.children?.forEach((grandchild) => {
        setValue(grandchild.mainName, isChecked);
      });

      setValue(child.mainName, isChecked);
    });
  };

  const handleSecondLevelCheckboxChange = (
    mainName,
    fieldName,
    isChecked,
    arr,
    grandChildren,
  ) => {
    setValue(fieldName, isChecked);

    grandChildren.forEach((child) => {
      setValue(child.mainName, isChecked);
    });
    if (isChecked) {
      setValue(mainName, true);
      return;
    }

    const isAnyFieldChecked = arr.every((child) => child.watched === true);

    if (!isAnyFieldChecked) {
      setValue(mainName, isAnyFieldChecked);
    }
  };

  const handleCheckboxChange = (mainName, fieldName, isChecked, arr) => {
    setValue(fieldName, isChecked);

    if (isChecked) {
      setValue(mainName, true);
      setValue(arr.mainName, true);
      return;
    }

    const arrItem = arr.children.find((item) => item.mainName === mainName);

    if (arrItem?.children) {
      const isAnyFieldChecked = arrItem.children.every(
        (child) => child.watched === true,
      );
      if (!isAnyFieldChecked) {
        setValue(mainName, isAnyFieldChecked);
      }
    }

    const isAnyFieldChecked = arr.children.every(
      (child) => child.watched === true,
    );

    if (!isAnyFieldChecked) {
      setValue(arr.mainName, isAnyFieldChecked);
    }
  };

  const dataConverters = {
    revenue: (v) => (v * 30).toFixed(2),
    kium: (v) =>
      (((parseInt(v) / (24 * mainStationInfo.capacity)) * 100) / 1000).toFixed(
        2,
      ),
    emissions: (v) => (v * 0.0006).toFixed(2),
  };

  const formatteData = (data) => {
    if (
      (selectedOption === "selectDate" || selectedOption === "selectMonth") &&
      (powerGeneration || stationIndicators) &&
      selectedEndDate
    ) {
      return data
        .find((item) => item.name === "Actual")
        .series.map((date, index) => {
          return {
            date: date.dt,
            data: date.data.map((item, indexItem) => {
              const predictionItem = data.find((item) => item.name === "Intech")
                .series[index].data[indexItem];
              const meteologicaItem = data.find(
                (item) => item.name === "Meteologica",
              ).series[index].data[indexItem];
              return {
                labels: item.name,
                actual: parseInt(item.value),
                prediction: parseInt(predictionItem.value),
                meteologica: parseInt(meteologicaItem.value),
              };
            }),
          };
        });
    }
    return data
      .find((item) => item.name === "Actual")
      .series.map((actualItem, index) => {
        const predictionItem = data.find((item) => item.name === "Intech")
          .series[index];
        const meteologicaItem = data.find((item) => item.name === "Meteologica")
          .series[index];
        return {
          labels: actualItem.name,
          actual: parseInt(actualItem.value),
          prediction: parseInt(predictionItem.value),
          meteologica: parseInt(meteologicaItem.value),
        };
      });
  };

  const onSubmit = async () => {
    let powerOrStationData = [];
    let meteoData = [];

    if (powerGeneration || stationIndicators) {
      if (selectedOption === "selectDate" && powerGeneration) {
        try {
          const { data } = await axios.get(
            "/api/v1/power-plant/day-stats-range",
            {
              params: {
                power_plant_id: mainStationInfo.id,
                startDate: format(selectedDate, "yyyy-MM-dd"),
                endDate: format(selectedEndDate, "yyyy-MM-dd"),
              },
            },
          );

          powerOrStationData = [...formatteData(data)];
        } catch (error) {
          console.error("Error fetching day stats:", error);

          // Use enqueueSnackbar to show the error message
          enqueueSnackbar(t("REPORT.ERROR"), { variant: "error" });
          return;
        }
      }

      if (selectedOption === "selectMonth") {
        try {
          const { data } = await axios.get(
            "/api/v1/power-plant/day-stats-range",
            {
              params: {
                power_plant_id: mainStationInfo.id,
                startDate: format(selectedYearsAndMonth, "yyyy-MM-dd"),
                endDate: format(selectedEndDate, "yyyy-MM-dd"),
              },
            },
          );

          powerOrStationData = [...formatteData(data)];
        } catch (error) {
          console.error("Error fetching month stats:", error);

          // Use enqueueSnackbar to show the error message
          enqueueSnackbar(t("REPORT.ERROR"), { variant: "error" });
          return;
        }
      }

      if (selectedOption === "selectYear") {
        try {
          const { data } = await axios.get("/api/v1/power-plant/year-stats", {
            params: {
              power_plant_id: mainStationInfo.id,
              timestamp: format(selectedYears, "yyyy"),
            },
          });

          powerOrStationData = [...formatteData(data)];
        } catch (error) {
          console.error("Error fetching year stats:", error);

          // Use enqueueSnackbar to show the error message
          enqueueSnackbar(t("REPORT.ERROR"), { variant: "error" });
          return;
        }
      }
    }

    if (meteodata) {
      try {
        const { data } = await axios.get("/api/v1/meteo/day-stats", {
          params: {
            power_plant_id: mainStationInfo.id,
            timestamp: format(selectedDate, "yyyy-MM-dd"),
          },
        });
        meteoData = [...data];
      } catch (error) {
        console.error("Error fetching year stats:", error);

        // Use enqueueSnackbar to show the error message
        enqueueSnackbar(t("REPORT.ERROR"), { variant: "error" });
        return;
      }
    }

    const newData = [];

    if (selectedOption === "selectYear") {
      newData.push(
        powerOrStationData.map((item) => ({
          ...{
            "REPORT.TIME": months[item.labels - 1],
          },
          ...(actualOutput && { "REPORT.ACTUAL_OUTPUT": item.actual }),
          ...(plannedOutput && {
            "REPORT.PLANNED_OUTPUT_INTECH": item.prediction,
          }),
          ...(meteologicaOutput && {
            "REPORT.PLANNED_OUTPUT_METEOLOGICA": item.meteologica,
          }),
          ...(tempFact && {
            "REPORT.TEMPERATURE_FACT": item.temperature.actual,
          }),
          ...(tempForecast && {
            "REPORT.TEMPERATURE_PREDICTION": item.temperature.prediction,
          }),
          ...(wsFact && { "REPORT.WIND_SPEED_FACT": item.wind_speed.actual }),
          ...(wsForecast && {
            "REPORT.WIND_SPEED_PREDICTION": item.wind_speed.prediction,
          }),
          ...(irradFact && {
            "REPORT.IRRADIANCE_FACT": item.irradiance.actual,
          }),
          ...(irradForecast && {
            "REPORT.IRRADIANCE_PREDICTION": item.irradiance.prediction,
          }),
          ...(precFact && {
            "REPORT.PRECIPITATION_FACT": item.precipitation.actual,
          }),
          ...(precForecast && {
            "REPORT.PRECIPITATION_PREDICTION": item.precipitation.prediction,
          }),
          ...(cloudFact && {
            "REPORT.CLOUDINESS_FACT": item.cloudiness.actual,
          }),
          ...(cloudForecast && {
            "REPORT.CLOUDINESS_PREDICTION": item.cloudiness.prediction,
          }),
          ...(profitFact && {
            "REPORT.PROFIT_FACT": dataConverters.revenue(item.actual),
          }),
          ...(profitForecast && {
            "REPORT.PROFIT_PREDICTION_INTECH": dataConverters.revenue(
              item.prediction,
            ),
          }),
          ...(profitForecastMeteologica && {
            "REPORT.PROFIT_PREDICTION_METEOLOGICA": dataConverters.revenue(
              item.meteologica,
            ),
          }),
          ...(reductionOfCO2EmissionsFact && {
            "REPORT.EMISSIONS_REDUCTION_FACT": dataConverters.emissions(
              item.actual,
            ),
          }),
          ...(reductionOfCO2EmissionsForecast && {
            "REPORT.EMISSIONS_REDUCTION_PREDICTION_INTECH":
              dataConverters.emissions(item.prediction),
          }),
          ...(reductionOfCO2EmissionsForecastMeteologica && {
            "REPORT.EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA":
              dataConverters.emissions(item.meteologica),
          }),
          ...(KIOMFact && {
            "REPORT.CUF_FACT": dataConverters.kium(item.actual),
          }),
          ...(KIOMForecast && {
            "REPORT.CUF_PREDICTION_INTECH": dataConverters.kium(
              item.prediction,
            ),
          }),
          ...(KIOMForecastMeteologica && {
            "REPORT.CUF_PREDICTION_METEOLOGICA": dataConverters.kium(
              item.meteologica,
            ),
          }),
        })),
      );
    } else {
      const dataArrayToUse =
        powerOrStationData.length >= meteoData.length
          ? powerOrStationData
          : meteoData;

      dataArrayToUse?.map((item, index) => {
        const joined = _.merge(
          _.keyBy(meteoData[index]?.data, "labels"),
          _.keyBy(powerOrStationData[index]?.data, "labels"),
        );

        const joinedArray = _.values(joined);

        newData.push({
          date: item.date,
          data: joinedArray.map((item) => ({
            ...{
              "REPORT.TIME": item.labels,
            },
            ...(actualOutput && { "REPORT.ACTUAL_OUTPUT": item.actual }),
            ...(plannedOutput && {
              "REPORT.PLANNED_OUTPUT_INTECH": item.prediction,
            }),
            ...(meteologicaOutput && {
              "REPORT.PLANNED_OUTPUT_METEOLOGICA": item.meteologica,
            }),
            ...(tempFact && {
              "REPORT.TEMPERATURE_FACT": item.temperature.actual,
            }),
            ...(tempForecast && {
              "REPORT.TEMPERATURE_PREDICTION": item.temperature.prediction,
            }),
            ...(wsFact && { "REPORT.WIND_SPEED_FACT": item.wind_speed.actual }),
            ...(wsForecast && {
              "REPORT.WIND_SPEED_PREDICTION": item.wind_speed.prediction,
            }),
            ...(irradFact && {
              "REPORT.IRRADIANCE_FACT": item.irradiance.actual,
            }),
            ...(irradForecast && {
              "REPORT.IRRADIANCE_PREDICTION": item.irradiance.prediction,
            }),
            ...(precFact && {
              "REPORT.PRECIPITATION_FACT": item.precipitation.actual,
            }),
            ...(precForecast && {
              "REPORT.PRECIPITATION_PREDICTION": item.precipitation.prediction,
            }),
            ...(cloudFact && {
              "REPORT.CLOUDINESS_FACT": item.cloudiness.actual,
            }),
            ...(cloudForecast && {
              "REPORT.CLOUDINESS_PREDICTION": item.cloudiness.prediction,
            }),
            ...(profitFact && {
              "REPORT.PROFIT_FACT": dataConverters.revenue(item.actual),
            }),
            ...(profitForecast && {
              "REPORT.PROFIT_PREDICTION_INTECH": dataConverters.revenue(
                item.prediction,
              ),
            }),
            ...(profitForecastMeteologica && {
              "REPORT.PROFIT_PREDICTION_METEOLOGICA": dataConverters.revenue(
                item.meteologica,
              ),
            }),
            ...(reductionOfCO2EmissionsFact && {
              "REPORT.EMISSIONS_REDUCTION_FACT": dataConverters.emissions(
                item.actual,
              ),
            }),
            ...(reductionOfCO2EmissionsForecast && {
              "REPORT.EMISSIONS_REDUCTION_PREDICTION_INTECH":
                dataConverters.emissions(item.prediction),
            }),
            ...(reductionOfCO2EmissionsForecastMeteologica && {
              "REPORT.EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA":
                dataConverters.emissions(item.meteologica),
            }),
            ...(KIOMFact && {
              "REPORT.CUF_FACT": dataConverters.kium(item.actual),
            }),
            ...(KIOMForecast && {
              "REPORT.CUF_PREDICTION_INTECH": dataConverters.kium(
                item.prediction,
              ),
            }),
            ...(KIOMForecastMeteologica && {
              "REPORT.CUF_PREDICTION_METEOLOGICA": dataConverters.kium(
                item.meteologica,
              ),
            }),
          })),
        });
      });
    }

    const stationTitle = mainStationInfo.name;

    if (selectedOption === "selectYear") {
      downloadYearStats(selectedYears, newData[0], stationTitle, t);
    } else {
      downloadDayOrMonthStats(newData, stationTitle, t);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <p className="form-subtitle" style={{ marginBottom: "16px" }}>
        {t("EXPORT.TITLE")}
      </p>
      <div className="radio-block export__radio-block">
        <label
          className={
            selectedOption === "selectDate"
              ? "selected export__selected"
              : "label export__label"
          }
        >
          <input
            type="radio"
            value="selectDate"
            {...register("selectRadio")}
            checked={selectedOption === "selectDate"}
            onChange={handleRadioChange}
          />
          {t("EXPORT.DAY")}
        </label>

        <label
          className={
            selectedOption === "selectMonth"
              ? "selected export__selected"
              : "label export__label"
          }
        >
          <input
            type="radio"
            value="selectMonth"
            {...register("selectRadio")}
            checked={selectedOption === "selectMonth"}
            onChange={handleRadioChange}
          />
          {t("EXPORT.MONTH")}
        </label>
        <label
          className={
            selectedOption === "selectYear"
              ? "selected export__selected"
              : "label export__label"
          }
        >
          <input
            type="radio"
            value="selectYear"
            {...register("selectRadio")}
            checked={selectedOption === "selectYear"}
            onChange={handleRadioChange}
          />
          {t("EXPORT.YEAR")}
        </label>
      </div>
      <>
        {selectedOption === "selectDate" && (
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={currentLocale}
          >
            {/* Start Date Picker */}
            <label className="form-label">{t("EXPORT.START_DATE")}</label>
            <Controller
              control={control}
              name="selectedDate"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <DesktopDatePicker
                      {...field}
                      sx={datePickerExportStyle}
                      disabled={disableDateSelector}
                      value={selectedDate}
                      onChange={handleDateChange}
                      shouldDisableDate={disableDate}
                      open={open}
                      onClose={() => setOpen(false)}
                      slotProps={{
                        textField: {
                          onClick: () => setOpen(true),
                        },
                        OpenPickerIcon: CalendarTodayIcon,
                      }}
                      orientation="portrait"
                    />
                    {fieldState?.error && (
                      <span>{fieldState.error.message}</span>
                    )}
                  </>
                );
              }}
            />

            {/* End Date Picker */}
            <label className="form-label">{t("EXPORT.END_DATE")}</label>
            <Controller
              control={control}
              name="selectedEndDate"
              render={({ field, fieldState }) => {
                return (
                  <>
                    <DesktopDatePicker
                      {...field}
                      sx={datePickerExportStyle}
                      disabled={disableDateSelector}
                      value={selectedEndDate}
                      minDate={selectedDate}
                      onChange={handleEndDateChange}
                      shouldDisableDate={disableDate}
                      open={endPickerOpen}
                      onClose={() => setEndPickerOpen(false)}
                      slotProps={{
                        textField: {
                          onClick: () => setEndPickerOpen(true),
                        },
                        OpenPickerIcon: CalendarTodayIcon,
                      }}
                      orientation="portrait"
                    />
                    {fieldState?.error && (
                      <span>{fieldState.error.message}</span>
                    )}
                  </>
                );
              }}
            />
          </LocalizationProvider>
        )}
      </>

      {selectedOption === "selectMonth" && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={currentLocale}
        >
          <label className="form-label">{t("EXPORT.START_DATE")}</label>
          <Controller
            control={control}
            name="selectedDate"
            render={({ field }) => {
              return (
                <DesktopDatePicker
                  {...field}
                  disabled={disableMonthYearSelector}
                  sx={datePickerExportStyle}
                  views={["month", "year"]}
                  value={selectedYearsAndMonth}
                  onYearChange={handleDateChange}
                  minDate={new Date("2021-01-01")}
                  maxDate={new Date()}
                  open={open}
                  onClose={() => setOpen(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setOpen(true),
                    },
                    OpenPickerIcon: CalendarTodayIcon,
                  }}
                  orientation="portrait"
                />
              );
            }}
          />

          {/* End Date Picker */}
          <label className={"form-label"}>{t("EXPORT.END_DATE")}</label>
          <Controller
            control={control}
            name="selectedEndDate"
            render={({ field, fieldState }) => {
              return (
                <>
                  <DesktopDatePicker
                    {...field}
                    sx={datePickerExportStyle}
                    views={["month", "year"]}
                    minDate={selectedDate}
                    maxDate={new Date()}
                    disabled={disableDateSelector}
                    value={selectedEndDate}
                    onChange={handleEndDateChange}
                    shouldDisableDate={disableDate}
                    open={endPickerOpen}
                    onClose={() => setEndPickerOpen(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setEndPickerOpen(true),
                      },
                      OpenPickerIcon: CalendarTodayIcon,
                    }}
                    orientation="portrait"
                  />
                  {fieldState?.error && <span>{fieldState.error.message}</span>}
                </>
              );
            }}
          />
        </LocalizationProvider>
      )}
      {selectedOption === "selectYear" && (
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={currentLocale}
        >
          <Controller
            control={control}
            name="selectedDate"
            render={({ field }) => {
              return (
                <DesktopDatePicker
                  {...field}
                  disabled={disableMonthYearSelector}
                  sx={datePickerExportStyle}
                  views={["year"]}
                  value={selectedYears}
                  onYearChange={handleDateChange}
                  minDate={new Date("2021-01-01")}
                  maxDate={new Date()}
                  open={open}
                  onClose={() => setOpen(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setOpen(true),
                    },
                    OpenPickerIcon: CalendarTodayIcon,
                  }}
                  orientation="portrait"
                />
              );
            }}
          />
        </LocalizationProvider>
      )}
      <p className="form-subtitle" style={{ marginBottom: "16px" }}>
        {t("EXPORT.SUBTITLE")}
      </p>
      <div className="export__data">
        {powerGenerationArr.map((el, i) => {
          return (
            <div key={i}>
              <div
                className="export__data-head"
                style={
                  {
                    // display: "flex",
                    // alignItems: "center",
                    // flexWrap: "nowrap",
                  }
                }
              >
                <Controller
                  name={el.mainName}
                  control={control}
                  render={({ field }) => (
                    <FormControl>
                      <FormControlLabel
                        label={el.mainCheckbox}
                        control={
                          <Checkbox
                            size="small"
                            {...field}
                            onChange={(e) =>
                              handleMainCheckboxChange(
                                el.mainName,
                                e.target.checked,
                                el.children,
                              )
                            }
                            checked={el.watched}
                            style={{ color: "#F07048" }}
                          />
                        }
                      />
                    </FormControl>
                  )}
                />
                <IconButton
                  onClick={() => setOpenPowerGeneration(!openPowerGeneration)}
                >
                  {openPowerGeneration ? (
                    <KeyboardArrowUpOutlinedIcon />
                  ) : (
                    <KeyboardArrowDownOutlinedIcon />
                  )}
                </IconButton>
              </div>

              {openPowerGeneration && (
                <div className="export__data-body">
                  {el.children.map((child, j) => {
                    return (
                      <Controller
                        key={j}
                        name={child.mainName}
                        control={control}
                        render={({ field }) => (
                          <FormControl>
                            <FormControlLabel
                              label={child.mainCheckbox}
                              control={
                                <Checkbox
                                  size="small"
                                  {...field}
                                  onChange={(e) =>
                                    handleCheckboxChange(
                                      `${el.mainName}`,
                                      `${child.mainName}`,
                                      e.target.checked,
                                      el,
                                    )
                                  }
                                  checked={child.watched}
                                  style={{ color: "#F07048" }}
                                />
                              }
                            />
                          </FormControl>
                        )}
                      />
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}

        {location.pathname !== "/user/meteodata" &&
          stationIndicatorsArr.map((el, i) => {
            return (
              <div key={i}>
                <div className="export__data-head">
                  <Controller
                    name={el.mainName}
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <FormControlLabel
                          label={el.mainCheckbox}
                          control={
                            <Checkbox
                              size="small"
                              {...field}
                              onChange={(e) =>
                                handleMainCheckboxChange(
                                  el.mainName,
                                  e.target.checked,
                                  el.children,
                                )
                              }
                              checked={el.watched}
                              style={{ color: "#F07048" }}
                            />
                          }
                        />
                      </FormControl>
                    )}
                  />
                  <IconButton
                    onClick={() =>
                      setOpenStationIndicators(!openStationIndicators)
                    }
                  >
                    {openPowerGeneration ? (
                      <KeyboardArrowUpOutlinedIcon />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon />
                    )}
                  </IconButton>
                </div>

                {openStationIndicators && (
                  <div className="export__data-body">
                    {el.children.map((child, j) => {
                      return (
                        <React.Fragment key={j}>
                          <Controller
                            name={child.mainName}
                            control={control}
                            render={({ field }) => (
                              <FormControl>
                                <FormControlLabel
                                  label={child.mainCheckbox}
                                  control={
                                    <Checkbox
                                      size="small"
                                      {...field}
                                      onChange={(e) =>
                                        handleSecondLevelCheckboxChange(
                                          `${el.mainName}`,
                                          `${child.mainName}`,
                                          e.target.checked,
                                          el.children,
                                          child.children,
                                        )
                                      }
                                      checked={child.watched}
                                      style={{ color: "#F07048" }}
                                    />
                                  }
                                />
                              </FormControl>
                            )}
                          />

                          <div className="export__body-child">
                            {child.children.map((ch, l) => {
                              return (
                                <Controller
                                  key={l}
                                  name={ch.mainName}
                                  control={control}
                                  render={({ field }) => (
                                    <FormControl>
                                      <FormControlLabel
                                        label={ch.mainCheckbox}
                                        control={
                                          <Checkbox
                                            size="small"
                                            {...field}
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                `${child.mainName}`,
                                                `${ch.mainName}`,
                                                e.target.checked,
                                                el,
                                              )
                                            }
                                            checked={ch.watched}
                                            style={{ color: "#F07048" }}
                                          />
                                        }
                                      />
                                    </FormControl>
                                  )}
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}

        {location.pathname === "/user/meteodata" &&
          meteodataArr.map((el, i) => {
            return (
              <div key={i}>
                <div className="export__data-head">
                  <Controller
                    name={el.mainName}
                    control={control}
                    render={({ field }) => (
                      <FormControl>
                        <FormControlLabel
                          label={el.mainCheckbox}
                          control={
                            <Checkbox
                              size="small"
                              {...field}
                              onChange={(e) =>
                                handleMainCheckboxChange(
                                  el.mainName,
                                  e.target.checked,
                                  el.children,
                                )
                              }
                              checked={el.watched}
                              style={{ color: "#F07048" }}
                            />
                          }
                        />
                      </FormControl>
                    )}
                  />
                  <IconButton onClick={() => setOpenMeteodata(!openMeteodata)}>
                    {openPowerGeneration ? (
                      <KeyboardArrowUpOutlinedIcon />
                    ) : (
                      <KeyboardArrowDownOutlinedIcon />
                    )}
                  </IconButton>
                </div>

                {openMeteodata && (
                  <div className="export__data-body">
                    {el.children.map((child, j) => {
                      return (
                        <React.Fragment key={j}>
                          <Controller
                            name={child.mainName}
                            control={control}
                            render={({ field }) => (
                              <FormControl>
                                <FormControlLabel
                                  label={child.mainCheckbox}
                                  control={
                                    <Checkbox
                                      size="small"
                                      {...field}
                                      onChange={(e) =>
                                        handleSecondLevelCheckboxChange(
                                          `${el.mainName}`,
                                          `${child.mainName}`,
                                          e.target.checked,
                                          el.children,
                                          child.children,
                                        )
                                      }
                                      checked={child.watched}
                                      style={{ color: "#F07048" }}
                                    />
                                  }
                                  sx={{
                                    "& .MuiFormControlLabel-label": {
                                      fontWeight: 400,
                                      fontSize: "16px",
                                      lineHeight: "24px",
                                    },
                                  }}
                                />
                              </FormControl>
                            )}
                          />

                          <div className="export__body-child">
                            {child.children.map((ch, l) => {
                              return (
                                <Controller
                                  key={l}
                                  name={ch.mainName}
                                  control={control}
                                  render={({ field }) => (
                                    <FormControl>
                                      <FormControlLabel
                                        label={ch.mainCheckbox}
                                        control={
                                          <Checkbox
                                            size="small"
                                            {...field}
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                `${child.mainName}`,
                                                `${ch.mainName}`,
                                                e.target.checked,
                                                el,
                                              )
                                            }
                                            checked={ch.watched}
                                            style={{ color: "#F07048" }}
                                          />
                                        }
                                      />
                                    </FormControl>
                                  )}
                                />
                              );
                            })}
                          </div>
                        </React.Fragment>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })}
      </div>
      <button
        type="submit"
        disabled={
          !isFormValid || disableDateSelector || disableMonthYearSelector
        }
        className={
          !isFormValid || disableDateSelector || disableMonthYearSelector
            ? "btn"
            : "valid"
        }
      >
        {t("EXPORT.EXPORT")}
      </button>
    </form>
  );
};

export default FormExport;
