import { authRoles } from "../../../../auth";
import PowerGeneration from "./PowerGeneration";
import i18n from "../../../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "powerGenerationPage", en);
i18n.addResourceBundle("ru", "powerGenerationPage", ru);
i18n.addResourceBundle("kz", "powerGenerationPage", kz);

const PowerGenerationConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/user/power-generation",
      element: <PowerGeneration />,
    },
  ],
};

export default PowerGenerationConfig;
