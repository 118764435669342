import { authRoles } from "../../../../auth";
import Meteodata from "./Meteodata";
import i18n from "./../../../../../i18n";
import kz from "./i18n/kz";
import ru from "./i18n/ru";
import en from "./i18n/en";

i18n.addResourceBundle("kz", "meteodataPage", kz);
i18n.addResourceBundle("ru", "meteodataPage", ru);
i18n.addResourceBundle("en", "meteodataPage", en);

const MeteodataConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/user/meteodata",
      element: <Meteodata />,
    },
  ],
};

export default MeteodataConfig;
