const locale = {
  MONTHS: {
    JANUARY: "January",
    FEBRUARY: "February",
    MARCH: "March",
    APRIL: "April",
    MAY: "May",
    JUNE: "June",
    JULY: "July",
    AUGUST: "August",
    SEPTEMBER: "September",
    OCTOBER: "October",
    NOVEMBER: "November",
    DECEMBER: "December",
  },
  ACTUAL: "Actual",
  PREDICTION: "Forecast",
  ACTUAL_METEO: "Actual",
  IRRADIANCE_UNIT: "kW‧h/m²",
  WIND_SPEED_UNIT: "m/s",
  PRECIPITATION_UNIT: "mm",
  KW_H: " kW‧h",
  MW_H: " MW‧h",
  KW: " kW",
  SHOW: "Show",
  PLANNED_PRODUCTION: "Planned production",
  ACTUAL_PRODUCTION: "Actual production",
  TIME: "time",
};

export default locale;
