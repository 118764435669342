import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchComponentsService } from '../../services/monitoringService';

export const fetchComponentsAsync = createAsyncThunk(
  'components',
  async () => {
    try {
      const response = await fetchComponentsService("http://localhost:8000/api/v1/components");
      return response.data; 
    } catch (error) {
      throw Error('Failed to fetch components'); 
    }
  }
);

