import DiagramLocale from "../../../../UI/Diagram/i18n/kz";
import TableLocale from "../../../../UI/CustomTable/i18n/kz";
import FormExportLocale from "../../../../UI/FormExport/i18n/kz";
const locale = {
  TITLE: "Станциялардың өнімділігі",
  NO_DATA: "Таңдалған күні үшін деректер жоқ. Кез келген қолайсыздық үшін кешірім сұраймыз.",
  REVENUE: "Табыс",
  REVENUE_LOWERCASE: "табыс",
  CAPACITY_FACTOR: "ҚПК",
  EMISSIONS_REDUCTION: "CO2 көлемінің төмендеуі",
  EMISSIONS_ABBREVIATION: "тонна",
  TOTAL_MONTH: "Ай бойынша жалпы",
  TOTAL_YEAR: "Жыл бойынша жалпы",
  HIGHEST_DAILY: "Максималды күнделікті {{title}}",
  HIGHEST_MONTHLY: "Максималды айлық {{title}}",
  REVENUE_DESCRIPTION: "{{date}} (тариф 30 тг болған жағдайда)",
  AVERAGE: "Орташа {{title}}",
  LOWEST_DAILY: "Күнделікті ең төменгі {{title}}",
  LOWEST_MONTHLY: "Айлық ең төменгі {{title}}",
  MONTH: "Ай",
  YEAR: "Жыл",
  EXPORT_TABLE: "Кестені экспорттау",
  CHART: "График",
  TABLE: "Кесте",
  ...DiagramLocale,
  ...TableLocale,
  ...FormExportLocale,
};
export default locale;
