import Error404Page from "./404/Error404Page";
import { Navigate } from "react-router-dom";
import i18n from "../../../i18n";
import en from "./i18n/en";
import kz from "./i18n/kz";
import ru from "./i18n/ru";

i18n.addResourceBundle("en", "errorPages", en);
i18n.addResourceBundle("kz", "errorPages", kz);
i18n.addResourceBundle("ru", "errorPages", ru);

const errorPagesConfig = {
  settings: {
    layout: {
      config: {},
    },
  },
  routes: [
    {
      path: "pages/error",
      children: [
        {
          path: "",
          element: <Navigate to="404" />,
        },
        {
          path: "404",
          element: <Error404Page />,
        },
      ],
    },
  ],
};

export default errorPagesConfig;
