import DiagramLocale from "../../../../UI/Diagram/i18n/kz";
import TableLocale from "../../../../UI/CustomTable/i18n/kz";
import FormExportLocale from "../../../../UI/FormExport/i18n/kz";
const locale = {
  NO_ACTUAL_DATA: "Ағымдағы деректер жоқ",
  NO_DATA: "Таңдалған күні үшін деректер жоқ. Кез келген қолайсыздық үшін кешірім сұраймыз.",
  TITLE: "Қуат өндірісі",
  TODAY: "Бүгін",
  TOMORROW: "Ертең",
  OTHER_DATE: "Басқа күн",
  CHART: "График",
  TABLE: "Кесте",
  DAY_ACCURACY: "Күнделікті нақтылық",
  AVERAGE_POWER_DEVIATION: "Орташа сағаттық нақтылық",
  REVENUE: "Табыс",
  CAPACITY_FACTOR: "ҚПК",
  EMISSIONS_REDUCTION: "CO2 көлемінің төмендеуі",
  EMISSIONS_ABBREVIATION: "тонна",
  MONTH_ACCURACY: "Ай сайын нақтылық",
  YEAR_ACCURACY: "Жылдық сайын нақтылық",
  MONTH_AVERAGE_ACCURACY: "Орташа күнделік нақтылық",
  YEAR_AVERAGE_ACCURACY: "Орташа ай сайын нақтылық",
  TOTAL_MONTH: "Ай бойынша жалпы",
  TOTAL_YEAR: "Жыл бойынша жалпы",
  UNIT: "кВт-сағ",
  MONTH_OR_YEAR_UNIT: "МВт-сағ",
  DEVIATION_UNIT: "МВт-сағ", 
  HIGHEST: "Жоғары өндірic",
  REVENUE_DESCRIPTION: "({{tariff}} теңге тарифі бойынша)",
  LOWEST: "Төмен өндіріс",
  TABS: {
    DAILY: "Тәулік статистикасы",
    MONTHLY: "Айлық статистика",
    YEARLY: "Жылдық статистика",
  },
  EXPORT_TABLE: "Кестені экспорттау",
  ...DiagramLocale,
  ...TableLocale,
  ...FormExportLocale,
};
export default locale;
