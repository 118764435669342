import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Grid, Box } from "@mui/material";
import "./Error404Page.css";
import { useTranslation } from "react-i18next";
import {errorBox} from "../../../utils/stylesForMui";

const NotFoundPage = () => {
  const { t } = useTranslation("errorPages");

  return (
    <Box
      sx={errorBox}
    >
      <Grid container justifyContent="center" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <img alt="Error" src="/images/404.jpg" className="error-img" />
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Typography
            variant="h4"
            className="error-title"
            align="center"
            gutterBottom
          >
            {t("NOT_FOUND")}
          </Typography>

          <Typography
            variant="subtitle1"
            className="error-subtitle"
            align="center"
            gutterBottom
          >
            {t("DESCRIPTION")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            <Button
              component={Link}
              to="/"
              variant="contained"
              color="success"
              className="button"
            >
              {t("GO_HOME")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NotFoundPage;
