export const tabsMeteodataSPP = [
  { value: "irradiance", name: "TABS.IRRADIANCE" },
  { value: "temperature", name: "TABS.TEMPERATURE" },
  // { value: "cloudiness", name: "TABS.CLOUDINESS" },
  { value: "temperature_panel", name: "TABS.TEMPERATURE_PANEL"}
  // { value: "wind_speed", name: "TABS.WIND_SPEED" },
  // { value: "precipitation", name: "TABS.PRECIPITATION" },
];

export const tabsMeteodataWPP = [
  { value: "windSpeed", name: "TABS.WIND_SPEED" },
  { value: "windDirection", name: "TABS.WIND_DIRECTION" },
  { value: "airDensity", name: "TABS.AIR_DENSITY" },
  { value: "airTemperature", name: "TABS.AIR_TEMPERATURE" },
  { value: "atmosphericPressure", name: "TABS.PRESSURE" },
  { value: "precipitation", name: "TABS.PRECIPITATION" },
];

export const tabsPower = [
  { value: "day", name: "TABS.DAILY" },
  { value: "monthly", name: "TABS.MONTHLY" },
  { value: "yearly", name: "TABS.YEARLY" },
];

export const tabsStationStatistics = [
  { value: "revenue", name: "REVENUE" },
  { value: "kium", name: "CAPACITY_FACTOR" },
  { value: "emissions", name: "EMISSIONS_REDUCTION" },
];
