// Path: src\app\auth\services\jwtService\jwtService.js
import FuseUtils from "../../../../@fuse/utils/FuseUtils";
import axios from "axios";
import jwtDecode from "jwt-decode";
import jwtServiceConfig from "./jwtServiceConfig";

/* eslint-disable camelcase */

class JwtService extends FuseUtils.EventEmitter {
  init() {
    this.setInterceptors();
    this.handleAuthentication();
  }

  setInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (err) => {
        return new Promise((resolve, reject) => {
          if (
            err.response &&
            err.response.status === 401 &&
            err.config &&
            !err.config.__isRetryRequest
          ) {
            // if you ever get an unauthorized response, logout the user
            this.emit("onAutoLogout", "Invalid access_token");
            this.setSession(null, null);
          }
          throw err;
        });
      },
    );
  };

  handleAuthentication = () => {
    const refresh_token = this.getRefreshToken();
    const access_token = this.getAccessToken();
    console.log(access_token);
    if (!access_token) {
      this.emit("onNoAccessToken");
      return;
    }
    if (!refresh_token) {
      this.emit("onNoRefreshToken");
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(refresh_token, access_token);
      this.emit("onAutoLogin", true);
    } else {
      this.setSession(null);
      this.emit("onAutoLogout", "access_token expired");
    }
  };

  getTeamMember = () => {
    return axios
      .get(jwtServiceConfig.getTeamMember)
      .then((response) => response.data);
  };

  // Delete team member
  deleteTeamMember = (teamMemberId) => {
    return axios
      .delete(`${jwtServiceConfig.deleteTeamMember}/${teamMemberId}`)
      .then((response) => response.data);
  };

  updateTeamMember = (id, full_name, position, phone) => {
    return axios
      .patch(jwtServiceConfig.postTeamMember, {
        id,
        full_name,
        position,
        phone,
      })
      .then((response) => response.data);
  };

  // Add team member
  addTeamMember = (full_name, position, phone) => {
    return axios
      .post(jwtServiceConfig.postTeamMember, { full_name, position, phone })
      .then((response) => response.data);
  };

  // Update user email
  updateEmail = (email, password) => {
    return axios
      .patch(jwtServiceConfig.patchUserEmail, { email, password })
      .then((response) => response.data);
  };

  // Update user password
  updatePassword = (currentPassword, newPassword) => {
    return axios
      .patch(jwtServiceConfig.patchUserPassword, {
        currentPassword,
        newPassword,
      })
      .then((response) => response.data);
  };

  signInWithEmailAndPassword = (email, password) => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.signIn, {
          email: email,
          password: password,
        })
        .then((response) => {
          console.log("signInWithEmailAndPassword response: ", response);
          if (response.data.user) {
            const {refresh_token, access_token} = response.data;
            this.setSession(refresh_token, access_token);
            resolve(response.data.user);
            this.emit("onLogin", response.data.user);
          } else {
            reject(response.data.error);
          }
        });
    });
  };

  signInWithToken = () => {
    return new Promise((resolve, reject) => {
      axios
        .post(jwtServiceConfig.accessToken, {
          refresh_token: this.getRefreshToken(),
        })
        .then((response) => {
          if (response.data.user) {
            const {refresh_token, access_token} = response.data;
            this.setSession(refresh_token, access_token);
            resolve(response.data.user);
          } else {
            this.logout();
            reject(new Error("Failed to login with token."));
          }
        })
        .catch((error) => {
          this.logout();
          reject(new Error("Failed to login with token."));
        });
    });
  };
  
  setSession = (refresh_token, access_token) => {
    if (refresh_token && access_token) {
      localStorage.setItem("jwt_refresh_token", refresh_token);
      localStorage.setItem("jwt_access_token", access_token);
      axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
    } else {
      // Clean up the localStorage
      // localStorage.removeItem("jwt_refresh_token");
      // localStorage.removeItem("jwt_access_token");
      // delete axios.defaults.headers.common.Authorization;
    }
  };

  logout = () => {
    this.setSession(null, null);
    this.emit("onLogout", "Logged out");
  };

  isAuthTokenValid = (access_token) => {
    if (!access_token) {
      return false;
    }
    const decoded = jwtDecode(access_token);
    const currentTime = Date.now() / 1000;

    console.log("isAuthTokenValid currentTime: ", currentTime);
    console.log("isAuthTokenValid decoded: ", decoded);
    if (decoded.exp < currentTime) {
      console.warn("access token expired");
      return false;
    }

    return true;
  };

  getAccessToken = () => window.localStorage.getItem("jwt_access_token");

  getRefreshToken = () => window.localStorage.getItem("jwt_refresh_token");
}

const instance = new JwtService();

export default instance;
