import SignInPage from "./SignInPage";
import authRoles from "../../../auth/authRoles";
import i18n from "../../../../i18n";
import en from "./i18n/en";
import ru from "./i18n/ru";
import kz from "./i18n/kz";

i18n.addResourceBundle("en", "signInPage", en);
i18n.addResourceBundle("ru", "signInPage", ru);
i18n.addResourceBundle("kz", "signInPage", kz);

const SignInConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {
        navbar: {
          show: true,
          isLoginForm: true,
        },
        footer: false,
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/",
      element: <SignInPage />,
    },
  ],
};

export default SignInConfig;
