// Path: src\app\store\userSlice.js
import { createSlice } from "@reduxjs/toolkit";
import { updateEmail, updatePassword, setUser } from "./userThunks";
import { handleStatusChange } from "../reduxHelpers";

const initialState = {
  role: [], // guest
  emailChangeStatus: "idle",
  passwordChangeStatus: "idle",
  error: null,
};

const emailChangeStatusHandlers = handleStatusChange("emailChangeStatus");
const passwordChangeStatusHandlers = handleStatusChange("passwordChangeStatus");

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLoggedOut: (state, action) => initialState,
    getSelectStation(state, action) {
      state.stationInfo = action.payload;
    },
    resetUpdateStatus(state, action) {
      state.emailChangeStatus = "idle";
      state.passwordChangeStatus = "idle";
      state.error = null;
    },
  },
  extraReducers: {
    [setUser.fulfilled]: (state, action) => action.payload,
    [updateEmail.fulfilled]: (state, action) => {
      state.email = action.payload;
      emailChangeStatusHandlers.fulfilled(state, action);
    },
    [updateEmail.pending]: emailChangeStatusHandlers.pending,
    [updateEmail.rejected]: emailChangeStatusHandlers.rejected,
    [updatePassword.fulfilled]: (state, action) => {
      state.password = action.payload;
      passwordChangeStatusHandlers.fulfilled(state, action);
    },
    [updatePassword.pending]: passwordChangeStatusHandlers.pending,
    [updatePassword.rejected]: passwordChangeStatusHandlers.rejected,
  },
});

export const { userLoggedOut, getSelectStation, resetUpdateStatus } =
  userSlice.actions;

export default userSlice.reducer;
