import { Box, IconButton } from "@mui/material";
import React from "react";
import { modalNewStationStyle } from "../../../../utils/stylesForMui";
import CloseIcon from "@mui/icons-material/Close";
import "./modal-base.css";

const ModalBase = React.forwardRef((props, ref) => {
  const { onClose, children, title } = props;

  return (
    <Box style={modalNewStationStyle}>
      <div className="ModalSetStation__closeButton">
        <IconButton onClick={onClose} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className="ModalSetStation__header">
        <p className="ModalSetStation__title">{title}</p>
      </div>
      {children}
    </Box>
  );
});

export default ModalBase;
