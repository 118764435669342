import jwtService from "../auth/services/jwtService";


export  const getTeamMemberService = async ()=> {
    return await jwtService.getTeamMember();
}

export const deleteTeamMemberService = async (id)=> {
    return  await jwtService.deleteTeamMember(id);

}
export  const  updateTeamMemberService = async (id, full_name, position, phone) =>{
    return  await jwtService.updateTeamMember(id, full_name, position, phone);
}

export  const  addTeamMemberService = async (full_name, position, phone) =>{
   return await jwtService.addTeamMember(full_name, position, phone);

}