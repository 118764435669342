import { Fragment, useState, useEffect } from "react";
import {
  GoogleMap,
  InfoWindowF,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

const Monitoring = () => {
  const { t } = useTranslation("monitoringPage");
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyAsS0xIEJePMD2LUOCzjxu4NzeA_FKKhFk'
  });

  const [activeMarker, setActiveMarker] = useState(null);
  const [components, setComponents] = useState([]);
  const [lat, setLat] = useState(46.940539);
  const [lng, setLng] = useState(75.000926);
  const [selectedName, setSelectedName] = useState(t("AVERAGE_READINGS"));
  const [sensorData, setSensorData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  useEffect(() => {
    if (!isChanged) {
      setSelectedName(t("AVERAGE_READINGS"))
    }
  }, [t])

  useEffect(() => {
    const setInitialSensorData = (components) => {
      let redCount = 0;
      let greenCount = 0;
      let CBCount = 0;
      let SCUCount = 0;
      for (let obj of components) {
        if (obj.color == 'RED') redCount += 1;
        if (obj.color == 'GREEN') greenCount += 1;
        if (obj.type == 'CB') CBCount += 1;
        if (obj.type == 'SCU') SCUCount += 1;
      }
      const now = new Date();
      const hours = String(now.getHours()).padStart(2, '0');
      const minutes = String(now.getMinutes()).padStart(2, '0');
      const stationDescription = [
        {
          description: "Количество компонентов на станции",
          t: `${hours}:${minutes}`,
          v: components.length
        },
        {
          description: "Количество инвенторов на станции",
          t: `${hours}:${minutes}`,
          v: SCUCount
        },
        {
          description: "Количество комбайнер боксов на станции",
          t: `${hours}:${minutes}`,
          v: CBCount
        },
        {
          description: "Количество компонентов, работающих стандартно",
          t: `${hours}:${minutes}`,
          v: greenCount
        },
        {
          description: "Количество компонентов, работающих нестандартно",
          t: `${hours}:${minutes}`,
          v: redCount
        }
      ]
      setSensorData(stationDescription)
    }
    fetch(`${process.env.REACT_APP_MAP_API_URL}/components`)
      .then(response => response.json())
      .then(data => {
        setComponents(data);
        setInitialSensorData(data);
      })
      .catch(error => console.error('Error fetching components:', error));
    
    // fetch(`${process.env.REACT_APP_MAP_API_URL}/components/average`)
    //   .then(response => response.json())
    //   .then(data => setSensorData(data))
    //   .catch(error => console.error('Error fetching initial sensor data:', error));
  }, [process.env.REACT_APP_MAP_API_URL]);



  const handleActiveMarker = (marker, latitude, longitude, name) => {
    if (marker === activeMarker) {
      return;
    }
    setLat(latitude);
    setLng(longitude);
    setActiveMarker(marker);
  };

  const getData = async (id) => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_MAP_API_URL}/trends/components/${id}`);
      const data = await response.json();
      for (let obj of data) {
        obj.v = 0
      }
      setSensorData(data);
      setIsChanged(true)
    } catch (error) {
      console.error('Error fetching sensor data:', error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Fragment>
      <div className="container-inner">
        <div style={{ position: "relative", width: "100%", height: "80vh" }}>
          {isLoaded ? (
            <GoogleMap
              center={{ lat, lng}}
              zoom={16}
              onClick={() => setActiveMarker(null)}
              mapContainerStyle={{ width: "calc(100% - 400px)", height: "105%" }}
              mapTypeId="satellite"
            >
              {components.map(({ id, name, latitude, longitude, color }) => (
                <MarkerF
                  key={id}
                  position={{ lat: latitude, lng: longitude }}
                  onClick={() => handleActiveMarker(id, latitude, longitude, name)}
                  icon={{
                    path: window.google.maps.SymbolPath.CIRCLE,
                    fillColor: color === 'RED' ? '#FF0000' : (color === 'GREEN' ? '#00FF00' : (color === 'PURPLE' ? '#800080' : '#000000')),
                    fillOpacity: 0.8,
                    strokeColor: color === 'RED' ? '#FF0000' : (color === 'GREEN' ? '#00FF00' : (color === 'PURPLE' ? '#800080' : '#000000')),
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    scale: 3
                  }}
                >
                  {activeMarker === id && (
                    <InfoWindowF onCloseClick={() => setActiveMarker(null)}>
                      <div>
                        <p>{name}</p>
                        <button className="google-button" onClick={() => {
                          console.log(name);
                          setActiveMarker(null);
                          setSelectedName(name);
                          setSensorData([]);
                          getData(id);
                        }}>get data</button>
                      </div>
                    </InfoWindowF>
                  )}
                </MarkerF>
              ))}
            </GoogleMap>
          ) : null}
          <div className="sidebar" style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <h2>{selectedName}</h2>
            {loading ? (
              <div className="loading-container">
                <CircularProgress size="5rem" />
              </div>
            ) : (
              <table className="sensor-table">
                <thead>
                  <tr>
                    <th>{t("NAME")}</th>
                    <th>{t("VALUE")}</th>
                    <th>{t("TIME")}</th>
                  </tr>
                </thead>
                <tbody>
                  {sensorData.map((sensor, index) => (
                    <tr key={index}>
                      <td>{sensor.description}</td>
                      <td>{sensor.v}</td>
                      <td>{sensor.t}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default Monitoring;