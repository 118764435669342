import DiagramLocale from "../../../../UI/Diagram/i18n/ru";
import FormExportLocale from "../../../../UI/FormExport/i18n/ru";
import TableLocale from "../../../../UI/CustomTable/i18n/ru";
const locale = {
  TITLE: "Показатели станции",
  NO_DATA: "Данные для выбранной даты недоступны. Приносим извинения за доставленные неудобства.",
  REVENUE: "Доход",
  REVENUE_LOWERCASE: "доход",
  CAPACITY_FACTOR: "КИУМ",
  EMISSIONS_REDUCTION: "Снижение выбросов CO2",
  EMISSIONS_ABBREVIATION: "тонн",
  TOTAL_MONTH: "Всего за месяц",
  TOTAL_YEAR: "Всего за год",
  HIGHEST_DAILY: "Максимальн{{ending}} суточн{{ending}} {{title}}",
  HIGHEST_MONTHLY: "Максимальн{{ending}} месячн{{ending}} {{title}}",
  REVENUE_DESCRIPTION: "{{date}} (при тарифе 20 тг)",
  AVERAGE: "Средн{{ending}} {{title}}",
  LOWEST_DAILY: "Минимальный суточн{{ending}} {{title}}",
  LOWEST_MONTHLY: "Минимальный месячн{{ending}} {{title}}",
  MONTH: "Месяц",
  YEAR: "Год",
  EXPORT_TABLE: "Экспорт таблицы",
  CHART: "График",
  TABLE: "Таблица",
  ...DiagramLocale,
  ...FormExportLocale,
  ...TableLocale,
};
export default locale;
