import { Tabs, Tab, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { tabStyle } from "../../../../utils/stylesForMui";
import {
  tabsMeteodataSPP,
  tabsMeteodataWPP,
} from "../../../../utils/contstants/tabs";
import "./meteodata.css";
import MeteoDataStats from "./tab/MeteoDataStats";
import { useTheme } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { selectStationInfo } from "../../../../store/user/userThunks";
import { useSelector } from "react-redux";
import { setMainStationInfo } from "../../../../store/diagram/diagramSlice";

const Meteodata = () => {
  const theme = useTheme();
  const { t } = useTranslation("meteodataPage");

  const { mainStationInfo } = useSelector((state) => state.dashboard);
  const [tabsMeteodata, setTabsMeteodata] = useState(tabsMeteodataSPP);
  const [activeTab, setActiveTab] = useState("irradiance");

  useEffect(() => {
    if (mainStationInfo.type === "SPP") {
      setTabsMeteodata(tabsMeteodataSPP);
      setActiveTab("temperature");
    } else if (mainStationInfo.type === "WPP") {
      setTabsMeteodata(tabsMeteodataWPP);
      setActiveTab("windSpeed");
    }
  }, [mainStationInfo]);

  return (
    <div className="container-inner">
      <div className="DashboardMainConfig">
        <h1 className="title">{t("TITLE")} </h1>
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "40px",
            maxWidth: "100vw", // Set maxWidth to 100vw
            overflowX: "auto",
          }}
        >
          <Tabs
            value={activeTab}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            onChange={(_, newValue) => setActiveTab(newValue)}
            sx={{
              "& .MuiTabs-indicator": {
                background: "#F07048",
              },
              // Add the following media query styles
              [theme.breakpoints.down("sm")]: {
                "& .MuiTabs-scroller": {
                  overflowX: "auto",
                },
                "& .MuiTabs-flexContainer": {
                  flexWrap: "nowrap",
                },
              },
            }}
          >
            {tabsMeteodata.map((tab) => (
              <Tab
                sx={tabStyle}
                key={tab.value}
                value={tab.value}
                label={t(tab.name)}
              />
            ))}
          </Tabs>
        </Box>

        <MeteoDataStats meteodataTab={activeTab} />

        {/*<div style={{ position: "relative" }}>*/}
        {/*  {<CardMeteodata data={lastObject} />}*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

export default Meteodata;
