import DiagramLocale from "../../../../UI/Diagram/i18n/ru";
import CustomTableLocale from "../../../../UI/CustomTable/i18n/ru";
import FormExportLocale from "../../../../UI/FormExport/i18n/ru";

const locale = {
  NO_ACTUAL_DATA: "Нету актуальных данных",
  NO_DATA: "Данные для выбранной даты недоступны. Приносим извинения за доставленные неудобства.",
  TODAY: "Сегодня",
  TOMORROW: "Завтра",
  OTHER_DATE: "Другая дата",
  CHART: "Диаграмма",
  TABLE: "Таблица",
  ACCURACY: "Оправдываемость",
  TITLE: "Метеоданные",
  CURRENT_DATA: "Текущие метеоданные",
  NO_CURRENT_DATA: "Текущих метеоданных нет",
  TABS: {
    TEMPERATURE: "Температура",
    IRRADIANCE: "Иррадиация",
    CLOUDINESS: "Облачность",
    WIND_SPEED: "Скорость ветра",
    PRECIPITATION: "Осадки",
    WIND_DIRECTION: "Направление ветра",
    AIR_DENSITY: "Плотность воздуха",
    AIR_TEMPERATURE: "Температура воздуха",
    PRESSURE: "Атмосферное давление",
    TEMPERATURE_PANEL: "Температура ФЭМ"
  },
  IRRADIANCE_UNIT: "кВт‧ч/м²",
  WIND_SPEED_UNIT: "м/с",
  PRECIPITATION_UNIT: "мм",
  EXPORT_TABLE: "Экспорт таблицы",
  ...DiagramLocale,
  ...CustomTableLocale,
  ...FormExportLocale,
};
export default locale;
