import * as React from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { NavLink } from "react-router-dom";
import { btnExit, profileStyle } from "../../../utils/stylesForMui";
import { ListItemIcon } from "@mui/material";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import KeyboardArrowUpOutlinedIcon from "@mui/icons-material/KeyboardArrowUpOutlined";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useTranslation } from "react-i18next";

export default function AccountMenu() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [arrow, setArrow] = React.useState(true);
  const { t } = useTranslation("layoutMain");
  const handleClick = (event) => {
    setArrow(!arrow);
    if (anchorEl !== event.currentTarget) {
      setAnchorEl(event.currentTarget);
    }
  };
  const handleClose = () => {
    setArrow(!arrow);

    setAnchorEl(null);
  };

  return (
    <>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        sx={profileStyle}
        style={arrow ? null : { background: "#F3F5FA", color: "#272933" }}
      >
        <ListItemIcon
          sx={{
            minWidth: 25,
          }}
          style={arrow ? null : { background: "#F3F5FA", color: "#272933" }}
        >
          <AccountCircleOutlinedIcon />
        </ListItemIcon>
        {t("ACCOUNT_MENU.PROFILE")}
        <ListItemIcon
          sx={{
            minWidth: 0,
          }}
          style={arrow ? null : { background: "#F3F5FA", color: "#272933" }}
        >
          {arrow ? (
            <KeyboardArrowDownOutlinedIcon />
          ) : (
            <KeyboardArrowUpOutlinedIcon />
          )}
        </ListItemIcon>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          onMouseLeave: handleClose, // Close the menu when the mouse leaves the menu list
        }}
        sx={{
          top: "4px",
          left: "5px",
          "& .MuiMenu-list": {
            padding: "20px 0 12px",
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          <NavLink to="/user/info-employees">
            {t("ACCOUNT_MENU.PROFILE_SETTINGS")}
          </NavLink>
        </MenuItem>

        <MenuItem onClick={handleClose}>
          <NavLink to="/user/info-station">
            {t("ACCOUNT_MENU.STATION_SETTINGS")}
          </NavLink>
        </MenuItem>

        <MenuItem onClick={handleClose} sx={btnExit}>
          <NavLink className="btnExit" to="/sign-out">
            <ListItemIcon style={{ minWidth: "25px" }}>
              <ExitToAppIcon />
            </ListItemIcon>
            {t("ACCOUNT_MENU.LOGOUT")}
          </NavLink>
        </MenuItem>
      </Menu>
    </>
  );
}
