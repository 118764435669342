import { combineReducers, configureStore } from "@reduxjs/toolkit";
import settings from "./settingsSlice";
import user from "./user/userSlice";
import signUpSlice from "./signUpSlice";
import dashboard from './diagram/diagramSlice'
import employeeSlice from "./employee/employeeSlice";
import i18nSlice from "./i18n/i18nSlice";
import monitoringSlice from "./monitoring/monitoringSlice";

const createReducer = (asyncReducers) => (state, action) => {
	const combinedReducer = combineReducers({
		settings: settings,
		user: user,
		signUp: signUpSlice,
		employee: employeeSlice,
		i18n: i18nSlice,
		dashboard,
		monitoring: monitoringSlice,
		...asyncReducers,
	});

	return combinedReducer(state, action);
};

const store = configureStore({
	reducer: createReducer(),
});

export default store;
