import { createSlice } from "@reduxjs/toolkit";

import { format } from "date-fns";

import { loadStats } from "./diagramThunks";

const initialState = {
  apd: 0,
  mainStationInfo: {},
  lastRequest: {
    statBlock: "stats",
    statType: "today",
    date: format(new Date(), "yyyy-MM-dd"),
  },
  selectedPowerPlant: null,
  showApd: false,
  stats: {
    today: { loaded: false },
    tomorrow: { loaded: false },
    otherDate: { loaded: false },
  },
  summary: {
    monthly: { loaded: false },
    yearly: { loaded: false },
  },
  meteo: {
    today: { loaded: false },
    tomorrow: { loaded: false },
    otherDate: { loaded: false },
  },
  performance: {
    monthly: { loaded: false },
    yearly: { loaded: false },
  },
};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,

  reducers: {
    setApd(state, action) {
      state.apd = action.payload;
    },
    setMainStationInfo(state, action) {
      state.mainStationInfo = action.payload;
    },
    updateLastRequestState(state, action) {
      // console.log("updateLastRequestState", action.payload);

      state["lastRequest"]["statBlock"] = action.payload.statBlock;
      state["lastRequest"]["statType"] = action.payload.statType;
      state["lastRequest"]["date"] = action.payload.date;
    },
    updateSelectedPowerPlant: (state, action) => {
      state.selectedPowerPlant = action.payload;
    },
    resetLoadState: (state, action) => {
      state[action.payload.statBlock][action.payload.statType].date = undefined;
      state[action.payload.statBlock][action.payload.statType].loaded = false;
    },
    show(state, action) {
      state.showApd = action.payload;
    },
  },
  extraReducers: {
    [loadStats.fulfilled]: (state, action) => {
      state[action.payload.statBlock][action.payload.statType] =
        action.payload.statData;
    },
  },
});

export const {
  resetLoadState,
  show,
  updateLastRequestState,
  updateSelectedPowerPlant,
  setMainStationInfo,
  setApd,
} = dashboardSlice.actions;
export default dashboardSlice.reducer;
