const locale = {
  TITLE: "Присоединиться",
  SUBTITLE:
    "Пожалуйста, оставьте свои контакты. В ближайшее время наш менеджер\n" +
    "            свяжется с вами.",
  BUTTONS: {
    SEND: "Отправить",
    NEXT: "Далее",
    GO_BACK: "Назад",
  },
  FOOTER: "Уже есть аккаунт?",
  SIGN_IN: "Войти",
  POLITICAL: {
    TITLE: "Нажимая кнопку «Отправить», вы принимаете условия",
    CONFIDENTIALITY: "Политики конфиденциальности",
    AND: "и",
    TERMS: "Пользовательского соглашения",
  },

  STEP_1: {
    PHONE_NO: "Номер телефона",
    PHONE_NO_REQUIRED: "Номер телефона обязателен",
    EMAIL_PLACEHOLDER: "Введите email",
    VALIDATION: {
      EMAIL_FORMAT: "Неверный формат email.  Пожалуйста, попробуйте еще раз.",
      REQUIRED: "Обязательное поле",
    },
  },
  STEP_2: {
    REGIONS: {
      ABAY: "Абайская область",
      AKMOLA: "Акмолинская область",
      AKTOBE: "Актюбинская область",
      ALMATY: "Алматинская область",
      ATYRAU: "Атырауская область",
      EAST_KAZAKHSTAN: "Восточно-Казахстанская область",
      ZHAMBYL: "Жамбылская область",
      ZHETYSSU: "Жетысуская область",
      WEST_KAZAKHSTAN: "Западно-Казахстанская область",
      KARAGANDA: "Карагандинская область",
      KYZYLORDA: "Кызылординская область",
      MANGYSTAU: "Мангистауская область",
      PAVLODAR: "Павлодарская область",
      NORTH_KAZAKHSTAN: "Северо-Казахстанская область",
      TURKESTAN: "Туркестанская область",
      ULYTAU: "Улытауская область",
    },
    NAME: "Ваше имя",
    NAME_PLACEHOLDER: "Введите имя",
    COMPANY: "Компания",
    COMPANY_PLACEHOLDER: "Введите название компании",
    LOCATION: "Местоположение станции",
    LOCATION_PLACEHOLDER: "Выберите регион",
    VALIDATION: {
      REQUIRED: "Обязательное поле",
    },
  },
};
export default locale;
