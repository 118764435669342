import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FormControl,
  InputLabel,
  ListItemIcon,
  MenuItem,
  IconButton,
  Menu,
  Select,
  AppBar,
  Grid,
  useTheme,
  useMediaQuery,
  Dialog,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AddIcon from "@mui/icons-material/Add";
import AccountMenu from "./AccountMenu";
import {
  btnExit,
  selectNewStationStyle,
  selectStationStyle,
} from "../../../utils/stylesForMui";
import ModalSetStation from "../../../pages/dashboard/shared-component/ModalSetStation/ModalSetStation";
import { useDispatch, useSelector } from "react-redux";
import { getSelectStation } from "../../../store/user/userSlice";
import { selectUser, setUser } from "../../../store/user/userThunks";
import { menuStyle } from "../../../utils/stylesForMui";

import {
  setMainStationInfo,
  updateSelectedPowerPlant,
} from "../../../store/diagram/diagramSlice";
import { changePowerPlant } from "../../../store/diagram/diagramThunks";
import { selectTypeStation } from "../../../utils/contstants/selectTypeStation";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import LanguageSwitcher from "./LanguageSwitcher";
import { useTranslation } from "react-i18next";
import { selectCurrentLanguage } from "../../../store/i18n/i18nThunks";

function NavbarLayout() {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const currentLanguage = useSelector(selectCurrentLanguage);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { t } = useTranslation("layoutMain");
  const {i18n} = useTranslation();
  console.log(t("ACCOUNT_MENU.LOGOUT"));
  const selectedPowerPlant = useSelector(
    (state) => state.dashboard.selectedPowerPlant
  );

  const [station, setStation] = useState(
    selectedPowerPlant
      ? user.stations.find((item) => item.id === selectedPowerPlant).id
      : user.stations[0].id
  );
  const [open, setOpen] = useState(false);
  //const stationInfo = useSelector(selectStationInfo);

  const selectStation = user.stations.find((st) => st.id === station);
  // const matchedType = selectTypeStation.find(
  //   (el) => el.typeEn === selectStation?.type.name.en
  // );

  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleSelect = (e) => {
    const value = e.target.value;
    if (value === "newStation") {
      return;
    }
    setStation(value);
    dispatch(changePowerPlant(parseInt(value)));
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // const changeBorderRout = (isActive) =>
  //   isActive ? "2px solid #F07048" : "1px ";

  useEffect(() => {
    dispatch(setUser());
    dispatch(getSelectStation(station));
    dispatch(updateSelectedPowerPlant(station));
    console.log(currentLanguage);
  }, []);

  useEffect(() => {
    dispatch(getSelectStation(station));
    dispatch(setMainStationInfo(selectStation));
    console.log(user);
    console.log(station);
    console.log(selectStation);
  }, [station]);



  return (
    <div className="container">
      <header className="header">
        <AppBar color="transparent" position="absolute">
          <Grid container>
            <Grid item xs={8} md={4}>
              <div className="header__wrap">

                  <div className="logo">
                    <img src="/images/logo-main.svg" alt="Logo main" />
                  </div>

                <FormControl
                  variant="filled"
                  sx={{
                    width: "200px", // Set a default width for smaller screens


                    "@media (max-width: 600px)": {
                      // Adjust the breakpoint value as needed
                      width: "180px",
                    // Set a different width for screens smaller than 1100px
                    },
                    "@media (max-width: 400px)": {
                        width: "inherit",
                    },
                    borderRight: "1px solid #E7EAF2",
                  }}
                >
                  <InputLabel id="demo-simple-select-filled-label">
                    {/* {
                      matchedType[
                        `type${
                          currentLanguage.id.charAt(0).toUpperCase() +
                          currentLanguage.id.slice(1)
                        }`
                      ]
                    } */}
                    {selectStation.type.name[currentLanguage.id]}
                  </InputLabel>

                  <Select
                    value={station}
                    onChange={handleSelect}
                    sx={selectStationStyle}
                    IconComponent={KeyboardArrowUpIcon}
                    MenuProps={{
                      sx: { marginTop: "4px" },
                    }}
                  >
                    {user.stations.map((el) => (
                      <MenuItem key={el.id} value={el.id}>
                        {/* {
                          matchedType[
                            `type${
                              currentLanguage.id.charAt(0).toUpperCase() +
                              currentLanguage.id.slice(1)
                            }`
                          ]
                        } */}
                        {selectStation.type.name[currentLanguage.id]}
                         - {selectStation.capacity} 
                         {t("STATION.MW")}
                      </MenuItem>
                    ))}
                    <MenuItem
                      value={"newStation"}
                      sx={selectNewStationStyle}
                      onClick={handleOpen}
                    >
                      <ListItemIcon style={{ minWidth: "20px" }}>
                        <AddIcon />
                      </ListItemIcon>
                      {t("STATION.NEW_STATION")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
            </Grid>

            {isMobile ? (
              <>
                <Grid
                  item
                  xs={4}
                  md={2}
                  style={{ display: "flex", justifyContent: "flex-end", overflow: "hidden" }}
                >
                  <LanguageSwitcher />

                  <IconButton
                    edge="end"
                    onClick={handleOpenMenu}
                    aria-label="menu"
                    aria-controls="menu"
                    aria-haspopup="true"
                    sx={menuStyle}
                  >
                    <MenuIcon style={{ fontSize: "25px", marginRight: "10px", padding: "0 8px 0 10px"}} />
                  </IconButton>

                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleCloseMenu}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "right",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    style={{ marginTop: "10px" }}
                  >
                    <MenuItem
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to="/user/power-generation"
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#f0f0f0" : "initial",
                      })}
                    >
                      {t("NAVBAR.POWER_GENERATION")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to="/user/meteodata"
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#f0f0f0" : "initial",
                      })}
                    >
                      {t("NAVBAR.METEODATA")}
                    </MenuItem>
                    <MenuItem
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to="/user/station-performance"
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#f0f0f0" : "initial",
                      })}
                    >
                      {t("NAVBAR.STATION_PERFORMANCE")}
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to="/user/info-employees"
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#f0f0f0" : "initial",
                      })}
                    >
                      {t("ACCOUNT_MENU.PROFILE_SETTINGS")}
                    </MenuItem>

                    <MenuItem
                      onClick={handleCloseMenu}
                      component={NavLink}
                      to="/user/info-station"
                      style={({ isActive }) => ({
                        backgroundColor: isActive ? "#f0f0f0" : "initial",
                      })}
                    >
                      {t("ACCOUNT_MENU.STATION_SETTINGS")}
                    </MenuItem>
                    <MenuItem onClick={handleClose} sx={btnExit}>
                      <NavLink className="btnExit" to="/sign-out">
                        <ListItemIcon style={{ minWidth: "25px" }}>
                          <ExitToAppIcon />
                        </ListItemIcon>
                        {t("ACCOUNT_MENU.LOGOUT")}
                      </NavLink>
                    </MenuItem>
                  </Menu>
                </Grid>
              </>
            ) : (
              <>
                <Grid item md={5} l={6}>
                  <nav className="nav">
                    <NavLink
                      to="/user/power-generation"
                      className="link"
                      isActive={(match) =>
                        match && match.url === "/user/power-generation"
                      }
                      style={({ isActive }) => ({
                        borderBottom: isActive ? "2px solid red" : "none",
                      })}
                    >
                      {t("NAVBAR.POWER_GENERATION")}
                    </NavLink>
                    <NavLink
                      to="/user/meteodata"
                      className="link"
                      isActive={(match) =>
                        match && match.url === "/user/meteodata"
                      }
                      style={({ isActive }) => ({
                        borderBottom: isActive ? "2px solid red" : "none",
                      })}
                    >
                      {t("NAVBAR.METEODATA")}
                    </NavLink>
                    <NavLink
                      to="/user/station-performance"
                      className="link"
                      isActive={(match) =>
                        match && match.url === "/user/station-performance"
                      }
                      style={({ isActive }) => ({
                        borderBottom: isActive ? "2px solid red" : "none",
                      })}
                    >
                      {t("NAVBAR.STATION_PERFORMANCE")}
                    </NavLink>
                    <NavLink
                        to="/user/monitoring"
                        className="link"
                        isActive={(match) =>
                          match && match.url === "/user/monitoring"
                        }
                        style={({ isActive }) => ({
                          borderBottom: isActive ? "2px solid red" : "none",
                        })}
                      >
                        {t("NAVBAR.MONITORING")}
                    </NavLink>
                  </nav>
                </Grid>

                <Grid
                  item
                  md={3}
                  l={2}
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <LanguageSwitcher />
                  <AccountMenu />
                </Grid>
              </>
            )}
          </Grid>
        </AppBar>
      </header>
      <Dialog open={open} onClose={handleClose} maxWidth="xs">
        <ModalSetStation onClose={handleClose} />
      </Dialog>
    </div>
  );
}

export default NavbarLayout;
