const locale = {
  TITLE: "Join us",
  SUBTITLE:
    "Please leave your contact details. Our manager will contact you shortly.",
  BUTTONS: {
    SEND: "Send",
    NEXT: "Next",
    GO_BACK: "Go back",
  },
  FOOTER: "Already have an account?",
  SIGN_IN: "Sign in",
  POLITICAL: {
    TITLE: "By clicking the “Send” button, you accept the terms of the",
    CONFIDENTIALITY: "Privacy Policy",
    AND: "and",
    TERMS: "User Agreement",
  },

  STEP_1: {
    PHONE_NO: "Phone number",
    PHONE_NO_REQUIRED: "Phone number is required",
    EMAIL_PLACEHOLDER: "Enter email",
    VALIDATION: {
      EMAIL_FORMAT: "Invalid email format. Please try again.",
      REQUIRED: "Required field",
    },
  },
  STEP_2: {
    REGIONS: {
      ABAY: "Abay region",
      AKMOLA: "Akmola region",
      AKTOBE: "Aktobe region",
      ALMATY: "Almaty region",
      ATYRAU: "Atyrau region",
      EAST_KAZAKHSTAN: "East Kazakhstan region",
      ZHAMBYL: "Zhambyl region",
      ZHETYSSU: "Zhetyssu region",
      WEST_KAZAKHSTAN: "West Kazakhstan region",
      KARAGANDA: "Karaganda region",
      KYZYLORDA: "Kyzylorda region",
      MANGYSTAU: "Mangystau region",
      PAVLODAR: "Pavlodar region",
      NORTH_KAZAKHSTAN: "North Kazakhstan region",
      TURKESTAN: "Turkestan region",
      ULYTAU: "Ulytau region",
    },
    NAME: "Your name",
    NAME_PLACEHOLDER: "Enter name",
    COMPANY: "Company",
    COMPANY_PLACEHOLDER: "Enter company name",
    LOCATION: "Station location",
    LOCATION_PLACEHOLDER: "Select region",
    VALIDATION: {
      REQUIRED: "Required field",
    },
  },
};
export default locale;
