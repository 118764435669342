import history from "../../../@history";
import { setInitialSettings } from "../settingsSlice";
import settingsConfig from "../../configs/settingsConfig";
import { userLoggedOut } from "./userSlice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  updateEmailService,
  updatePasswordService,
} from "../../services/userService";

export const selectUser = ({ user }) => user;

export const selectStationInfo = ({ user }) => user.stationInfo;

export const updateEmail = createAsyncThunk(
  "user/updateEmail",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      return await updateEmailService(email, password);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const updatePassword = createAsyncThunk(
  "user/updatePassword",
  async ({ currentPassword, newPassword }, { rejectWithValue }) => {
    try {
      return await updatePasswordService(currentPassword, newPassword);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  },
);

export const setUser = createAsyncThunk("user/setUser", async (user, {}) => {
  if (user.loginRedirectUrl) {
    settingsConfig.loginRedirectUrl = user.loginRedirectUrl;
  }
  return user;
});

export const logoutUser = () => async (dispatch, getState) => {
  const { user } = getState();

  if (!user.role || user.role.length === 0) {
    // is guest
    return null;
  }

  history.push({
    pathname: "/",
  });

  dispatch(setInitialSettings());
  return dispatch(userLoggedOut());
};
