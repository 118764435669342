export const selectStationStyle = {
  color: "#272933",
  lineHeight: "24px",
  fontSize: "16px",
  fontWeight: 500,
  height: "100%",
  "@media (max-width: 600px)": {
    lineHeight: "20px",
    fontSize: "14px",
  },
  // width: "inherit",
  backgroundColor: "white",
  "&::after": {
    borderBottom: "none",
  },
  "&::before": {
    borderBottom: "none",
  },
  width: "inherit", // Set a default width for smaller screens
  "@media (max-width: 600px): ": {
    lineHeight: "20px",
    fontSize: "12px",
  },
};

export const selectNewStationStyle = {
  borderTop: "1px solid #E7EAF2",
  color: "#737486",
};

export const modalNewStationStyle = {
  position: "relative",
  margin: "0",
  width: "inherit",
  background: "#FFFFFF",
  border: "none",
  boxShadow: "-8px 16px 48px rgba(169, 171, 184, 0.4)",
  padding: "24px",
  borderRadius: "16px",
};

export const reactSelectStyle = {
  control: (provided, state) => ({
    ...provided,
    backgroundColor: state.isFocused ? "#E7EAF2" : "#F3F5FA",
    border: "none",
    borderRadius: "6px",
    boxShadow: "none",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
    padding: "9px",
    "&:hover": {
      border: "none",
    },
  }),
  placeholder: (provided) => ({
    ...provided,
    color: "#737486",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
};
export const profileStyle = {
  fontWeight: 500,

  margin: "0 10px",
  lineHeight: "20px",
  fontSize: "14px",
  color: "#737486",
  textTransform: "none",
  borderRadius: "6px",
};

export const menuStyle = {
  color: "inherit",
  borderRadius: 0,
  "&:hover": {
    background: "#F3F5FA",
    color: "#272933",
  },
};

export const btnExit = {
  borderTop: "1px solid #E7EAF2",
  color: "#272933",
  padding: "10px",
  paddingBottom: 0,
  "&:hover": {
    backgroundColor: "#FFFFFF",
  },
};

export const tabStyle = {
  fontWeight: 500,
  minWidth: "25%",
  fontSize: "14px",
  lineHeight: "20px",
  textTransform: "none",
  padding: 0,
  whiteSpace: "normal",
  "&.Mui-selected": {
    color: "#272933",
  },
  "@media (max-width: 800px)": {
    minWidth: "45%",
  },
  "@media (max-width: 450px)": {
    minWidth: "50%",
  },
};

export const dailyStateCalendar = {
  "& .MuiTabs-indicator": {
    height: "0",
  },
};

export const dailyStateCalendarTabRight = {
  "&.Mui-selected": {
    color: "#272933",
  },
  border: "1px solid #E7EAF2",
  borderRadius: "6px 0px 0px 6px",
  overflow: "hidden",
  textTransform: "none",
};

export const dailyStateCalendarTabCenter = {
  "&.Mui-selected": {
    color: "#272933",
  },
  borderTop: "1px solid #E7EAF2",
  borderBottom: "1px solid #E7EAF2",
  textTransform: "none",
};

export const dailyStateCalendarTabLeft = {
  "&.Mui-selected": {
    color: "#272933",
  },
  border: "1px solid #E7EAF2",
  borderRadius: "0 6px 6px 0",
  overflow: "hidden",
  textTransform: "none",
};

export const tableHeaderCell = {
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#737486",
  padding: "10px 5px",
  "@media (max-width: 500px)": {
    fontSize: "12px",
    lineHeight: "20px",
  },
  textAlign: "center",
  width: "25%",
  overflowWrap: "break-word",
};

export const tableEmployeeHeaderCell = {
  fontFamily: "Inter",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  color: "#737486",
  padding: "10px 5px",
  "@media (max-width: 500px)": {
    fontSize: "13px",
    lineHeight: "20px",
  },
  "@media (max-width: 400px)": {
    fontSize: "11px",
    lineHeight: "20px",
  },
  textAlign: "center",
  width: "25%",
  overflowWrap: "break-word",
};
export const selectDateStyle = {
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#272933",
  "&::after": {
    borderBottom: "none",
  },
  "&::before": {
    borderBottom: "none",
  },
  "&:hover": {
    borderBottom: "none",
  },
  "& .MuiFilledInput-input": {
    padding: "10px",
  },
};

export const datePickerStyle = {
  width: "190px",
  background: "#F3F5FA",
  borderRadius: "6px",
  fontWeight: 400,
  fontSize: "14px",
  lineHeight: "20px",
  color: "#272933",
  "& .MuiInputBase-input": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#272933",
    padding: "14px 0px 14px 14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#272933",
  },
};

export const datePickerExportStyle = {
  width: "100%",
  background: "#F3F5FA",
  borderRadius: "6px",
  fontWeight: 400,
  marginBottom: "10px",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#272933",
  "& .MuiInputBase-input": {
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#272933",
    p: "14px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiInputLabel-root": {
    fontFamily: "Inter",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#272933",
  },
};

export const selectSetStationStyle = {
  color: "#272933",
  lineHeight: "24px",
  fontSize: "16px",
  fontWeight: 400,
  borderRadius: "6px",
  backgroundColor: "white",
  overflow: "hidden",
  "&::after": {
    borderBottom: "none",
  },
  "&::before": {
    borderBottom: "none",
  },
};

export const errorBox = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
};
