import { CategoryScale } from "chart.js";
import Chart from "chart.js/auto";
import React, { useEffect, useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListItemIcon } from "@mui/material";

import "./line-diagram.css";
import { useLocation } from "react-router-dom";

Chart.register(CategoryScale);

const colors = ["#F07048", "#3b819a", "#f52e4b"];
const LineDiagram = ({
  data,
  day,
  setIsPlannedChecked,
  setIsActualChecked,
  setIsMeteologicaChecked,

  meteodataArr,
  meteodataTab,
  t,
}) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const [openSelect, setOpenSelect] = useState(false);
  const dropdownRef = useRef(null);
  // ------------------------------------

  // Helper to create initial checkbox state
  const generateInitialCheckState = (datasets) => {
    const initialState = {};

    if (currentPath === "/user/meteodata") {
      initialState["Planned"] = true;
      initialState["Actual"] = true;
    } else {
      datasets.forEach((dataset) => {
        if (dataset.series.every((item) => item.value === null)) {
          initialState[dataset.name] = false;
        } else {
          initialState[dataset.name] = true; // set only the first model as checked by default
        }
      });
    }
    return initialState;
  };

  const [isChecked, setIsChecked] = useState(generateInitialCheckState(data));
  setIsActualChecked(isChecked["Actual"]);
  if (currentPath === "/user/meteodata") {
    setIsPlannedChecked(isChecked["Planned"]);
  } else {
    setIsPlannedChecked(isChecked["Intech"]);
    setIsMeteologicaChecked(isChecked["Meteologica"]);
  }

  // ------------------------------------
  // ------------------------------------

  const meteodataActual = Array.isArray(meteodataArr)
    ? meteodataArr.map((item) => item.actual)
    : [];

  const meteodataPrediction = Array.isArray(meteodataArr)
    ? meteodataArr.map((item) => item.prediction)
    : [];

  const handleCheckboxChange = (type, event) => {
    setIsChecked((prevState) => ({
      ...prevState,
      [type]: event.target.checked,
    }));

    if (type === "Actual") {
      setIsActualChecked(isChecked["Actual"]);
    }
    if (type === "Planned") {
      setIsPlannedChecked(isChecked["Planned"]);
    }
    if (type === "Intech") {
      setIsPlannedChecked(isChecked["Intech"]);
    }
    if (type === "Meteologica") {
      setIsMeteologicaChecked(isChecked["Meteologica"]);
    }
  };

  const labels =
    currentPath === "/user/meteodata"
      ? data.map((item) => item.labels)
      : data[0]?.series.map((item) => item.name);

  // ------------------------------------

  const generateChartData = () => {
    if (currentPath === "/user/meteodata") {
      return [
        {
          label: "Actual",
          borderColor: "#A9ABB8",
          data: meteodataActual,
          tension: 0.1,
          hidden: !isChecked["Actual"],
        },
        {
          label: "Prediction",
          borderColor: "#F07048",
          data: meteodataPrediction,
          tension: 0.1,
          hidden: !isChecked["Planned"],
        },
      ];
    }
    return data?.map((item, index) => {
      const isActual = item.name === "Actual";
      console.log("item: ",item);
      return {
        label: item.name,
        borderColor: isActual ? "#A9ABB8" : colors.at(index - 1),
        data: item.series.map((subItem) => parseInt(subItem.value, 10)),

        tension: 0.1,
        hidden: !isChecked[item.name] || (!isActual && day === "tomorrow"),
      };
    });
  };

  // Function to compute the total of an array
  const getTotal = (dataArray) => {
    console.log("dataArray", dataArray);
    return dataArray.filter((val) => !Number.isNaN(val))
        .reduce((acc, val) => acc + val, 0);
  }

  // Get the totals
  const chartData = generateChartData();

  // Get total for actual data
  const totalDataActual = getTotal(
    chartData.find((dataset) => dataset.label === "Actual").data,
  );

  // Get totals for prediction models
  const totalsDataPrediction = chartData
    .filter((dataset) => dataset.label !== "Actual")
    .map((dataset) => getTotal(dataset.data));

  // Format the totals
  console.log("totalDataActual", totalDataActual);
  const formatTotalDataActual = totalDataActual.toLocaleString("ru-RU");
  const formatTotalsDataPrediction = totalsDataPrediction.map((total) =>
    total.toLocaleString("ru-RU"),
  );

  // const formatTotalMeteodataActual =
  //   getTotal(meteodataActual).toLocaleString("ru-RU");
  // const formatTotalMeteodataPrediction =
  //   getTotal(meteodataPrediction).toLocaleString("ru-RU");
  // // ------------------------------------

  let abbreviation = "";

  if (meteodataTab === "temperature") {
    abbreviation = "°C";
  } else if (meteodataTab === "irradiance") {
    abbreviation = t("IRRADIANCE_UNIT");
  } else if (meteodataTab === "cloudiness") {
    abbreviation = "%";
  } else if (meteodataTab === "wind_speed") {
    abbreviation = t("WIND_SPEED_UNIT");
  } else if (meteodataTab === "precipitation") {
    abbreviation = t("PRECIPITATION_UNIT");
  }

  useEffect(() => {
    const handleClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpenSelect(false);
      }
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [dropdownRef]);

  useEffect(() => {
    setIsChecked(generateInitialCheckState(data));
  }, [data]);

  return (
    <div className="diagram" ref={dropdownRef}>
      <div className="diagram__header">
        <div className="diagram__header-info">
          {chartData.map((dataset, index) => (
            <div
              key={index}
              className="diagram__header-title"
              style={{
                display: dataset.hidden ? "none" : "flex",
                flexDirection: "column",
              }}
            >
              <div>
                {/* Conditional styling based on dataset type. This can be adjusted as per requirements */}
                <span
                  className="diagram__line"
                  style={{
                    backgroundColor: dataset.borderColor,
                  }}
                ></span>
                <span className="diagram__title-name">{dataset.label}</span>
              </div>
              <p>
                {currentPath === "/user/meteodata"
                  ? null
                  : dataset.label === "Actual"
                  ? formatTotalDataActual + t("MW_H")
                  : formatTotalsDataPrediction[index - 1] + t("MW_H")}
              </p>
            </div>
          ))}
        </div>

        {!day && (
          <div className="diagram__select__container">
            {/* <div
              className="diagram__select"
              onClick={() => setOpenSelect(!openSelect)}
            >
              <p style={{ margin: 0 }}>{t("SHOW")}</p>
              <ListItemIcon style={{ minWidth: 0, fontSize: "16px" }}>
                {openSelect ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemIcon>
            </div> */}

            {openSelect && (
              <div className="select__menu">
                {/* Dynamically rendered checkboxes for prediction models */}
                {Object.keys(isChecked).map((name, index) => (
                  <label
                    key={index}
                    className="select__label"
                    style={{
                      color: data?.[index]?.series.every(
                        (item) => item.value === null,
                      )
                        ? "#A9ABB8"
                        : undefined, // Цвет будет установлен в исходный, если условие не выполнится
                    }}
                  >
                    <input
                      type="checkbox"
                      checked={isChecked[name]}
                      onChange={(e) => handleCheckboxChange(name, e)}
                      name={name}
                      disabled={data?.[index]?.series.every(
                        (item) => item.value === null,
                      )}
                    />
                    {name}
                  </label>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <p className="lineY">
        {currentPath !== "/user/meteodata" ? t("MW_H") : abbreviation}
      </p>
      <div className="wrap-diagram">
        <div className="chart-container">
          <Line
            data={{
              labels: labels.map((hour) => `${hour}:00`),
              datasets: generateChartData(),
            }}
            options={{
              plugins: {
                legend: {
                  display: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
            }}
          />
        </div>

        <p className="lineX">{t("TIME")}</p>
      </div>
    </div>
  );
};

export default LineDiagram;
