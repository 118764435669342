import { createSelector} from "@reduxjs/toolkit";
import { languageChanged } from "./i18nSlice";
import i18n from "../../../i18n";

export const changeLanguage = (languageId) => (dispatch, getState) => {
  i18n.changeLanguage(languageId).then(() => {
    dispatch(languageChanged(languageId));
  });
};

export const selectCurrentLanguageId = ({ i18n: _i18n }) => _i18n.language;

export const selectLanguages = ({ i18n: _i18n }) => _i18n.languages;

export const selectCurrentLanguage = createSelector(
  [selectCurrentLanguageId, selectLanguages],
  (id, languages) => {
    return languages.find((lng) => lng.id === id);
  }
);
