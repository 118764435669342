import React from 'react';
import './Spinner.css';

const Spinner = () => {
	return ( 
		<div className="Spinner">Loading...</div>
	);
}
 
export default Spinner;
