import React from "react";
import "./widget.css";

const Widget = (props) => {
	const { title, data, dataName, date, className } = props;
	return (
		<div className={`widget ${className}`}>
			<p className="widget__title">{title}</p>
			<p className="widget__data">
				{data} <span className="dataName">{dataName}</span>
			</p>
			<p className="widget__title widget__date">{date}</p>
		</div>
	);
};

export default Widget;
