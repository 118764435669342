import {
  Tab,
  Tabs,
  Box,
  Skeleton,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  TableHead,
  TableRow,
  TableContainer,
  Table,
  TableBody,
  TablePagination,
  TableFooter,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import { tableEmployeeHeaderCell, tabStyle } from "../../../utils/stylesForMui";
import "./info-employees.css";
import Spinner from "../../UI/Spinner/Spinner";
import { useDispatch, useSelector } from "react-redux";
import ModalBase from "../../dashboard/shared-component/ModalBase/ModalBase";
import FormModal from "../../UI/FormModal/FormModal";
import FormChange from "../../UI/FormChange/FormChange";
import AddIcon from "@mui/icons-material/Add";
import { ListItemIcon } from "@mui/material";
import { selectRole } from "../../../utils/contstants/selectRole";
import { useTranslation } from "react-i18next";

import {
  deleteTeamMember,
  getTeamMember,
  selectTeamMember,
} from "../../../store/employee/employeeThunks";
import { resetTeamMemberStatus } from "../../../store/employee/employeeSlice";
import { resetUpdateStatus } from "../../../store/user/userSlice";

import { useSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import TableCell from "@mui/material/TableCell";
import { useTheme } from "@mui/material/styles";

const styleCell = {
  color: "#272933",
  fontWeight: 400,
  background: "white",
  textAlign: "center",
};

const useSnackbarByStatus = (status, messageObject, enqueueSnackbar) => {
  useEffect(() => {
    if (status === "loading") {
      enqueueSnackbar(messageObject.loading, { variant: "info" });
    } else if (status === "succeeded") {
      enqueueSnackbar(messageObject.success, { variant: "success" });
    } else if (status === "failed") {
      enqueueSnackbar(messageObject.fail, { variant: "error" });
    }
  }, [status, enqueueSnackbar, messageObject]);
};

const InfoEmployees = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("infoEmployeesPage");

  const team_members = useSelector(selectTeamMember);
  const getTeamMemberStatus = useSelector(
    (state) => state.employee.getTeamMemberStatus,
  );
  // const [select, setSelect] = useState("employees");
  const [select, setSelect] = useState("accountInfo");
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [openModalAdd, setOpenModalAdd] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [openModalChangeEmail, setOpenModalChangeEmail] = useState(false);
  const [openModalChangePassword, setOpenModalChangePassword] = useState(false);
  const [infoEmployee, setInfoEmployee] = useState({});
  const {
    updateTeamMemberStatus,
    addTeamMemberStatus,
    deleteTeamMemberStatus,
  } = useSelector((state) => state.employee);

  const { email, emailChangeStatus, passwordChangeStatus } = useSelector(
    (state) => state.user,
  );
  const userEmailAndPassword = {
    email: email,
    password: "admin",
  };
  const hiddenPassword = "•".repeat(userEmailAndPassword.password.length);

  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  useSnackbarByStatus(
    updateTeamMemberStatus,
    {
      loading: t("UPDATE.LOADING"),
      success: t("UPDATE.SUCCESS"),
      fail: t("UPDATE.ERROR"),
    },
    enqueueSnackbar,
  );

  useSnackbarByStatus(
    addTeamMemberStatus,
    {
      loading: t("ADD.LOADING"),
      success: t("ADD.SUCCESS"),
      fail: t("ADD.ERROR"),
    },
    enqueueSnackbar,
  );

  useSnackbarByStatus(
    deleteTeamMemberStatus,
    {
      loading: t("DELETE.LOADING"),
      success: t("DELETE.SUCCESS"),
      fail: t("DELETE.ERROR"),
    },
    enqueueSnackbar,
  );

  useSnackbarByStatus(
    emailChangeStatus,
    {
      loading: t("EMAIL_CHANGE.LOADING"),
      success: t("EMAIL_CHANGE.SUCCESS"),
      fail: t("EMAIL_CHANGE.ERROR"),
    },
    enqueueSnackbar,
  );

  useSnackbarByStatus(
    passwordChangeStatus,
    {
      loading: t("PASSWORD_CHANGE.LOADING"),
      success: t("PASSWORD_CHANGE.SUCCESS"),
      fail: t("PASSWORD_CHANGE.ERROR"),
    },
    enqueueSnackbar,
  );

  useEffect(() => {
    dispatch(resetTeamMemberStatus());
    dispatch(resetUpdateStatus());
  }, [team_members, dispatch]);

  const handleChange = (_, newValue) => {
    setSelect(newValue);
    dispatch(resetTeamMemberStatus());
  };

  const edit = (id) => {
    const info = team_members.find((el) => el.id === id);
    setInfoEmployee(info);
    setOpenModalEdit(true);
  };

  const OpenRemoveModal = (id) => {
    const info = team_members.find((el) => el.id === id);
    setInfoEmployee(info);
    setOpenModalDelete(true);
  };

  const removeEmployee = (id) => {
    dispatch(deleteTeamMember(id));
    setOpenModalDelete(false);
  };

  useEffect(() => {
    dispatch(getTeamMember());
  }, []);

  const [page, setPage] = useState(0);
  const rowsPerPage = 5;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <div className="container-inner">
      <div className="infoEmployees">
        <h1 className="title">{t("TITLE")}</h1>

        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            marginBottom: "40px",
          }}
        >
          <Tabs
            value={select}
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
            sx={{
              "& .MuiTabs-indicator": {
                background: "#F07048",
              },
              // Add the following media query styles
              [theme.breakpoints.down("sm")]: {
                "& .MuiTabs-scroller": {
                  overflowX: "auto",
                },
                "& .MuiTabs-flexContainer": {
                  flexWrap: "nowrap",
                },
              },
            }}
          > 

            <Tab
              sx={tabStyle}
              value={"accountInfo"}
              label={t("ACCOUNT_INFO")}
              style={{ marginRight: "48px" }}
            />

            {/* <Tab
              sx={tabStyle}
              value={"employees"}
              label={t("EMPLOYEES")}
              style={{ marginRight: "48px" }}
            /> */}

          </Tabs>
        </Box>

        {select === "employees" && (
          <>
            {getTeamMemberStatus === "loading" && (
              <Skeleton variant="text" width={"100%"} height={30} />
            )}

            {getTeamMemberStatus === "error" && <p>{t("ERROR")}</p>}

            {getTeamMemberStatus === "succeeded" && (
              <>
                <TableContainer
                  sx={{
                    borderRadius: "8px",
                    border: "1px solid #E7EAF2",
                  }}
                >
                  <Table aria-label="simple table" className="custom-table">
                    <TableHead sx={{ background: "rgba(243, 245, 250, 0.4)" }}>
                      <TableRow>
                        <TableCell sx={tableEmployeeHeaderCell}>
                          {t("FULL_NAME")}
                        </TableCell>

                        <TableCell sx={tableEmployeeHeaderCell}>
                          {t("POSITION")}
                        </TableCell>
                        <TableCell sx={tableEmployeeHeaderCell}>
                          {t("PHONE_NO")}
                        </TableCell>
                        <TableCell sx={tableEmployeeHeaderCell}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {updateTeamMemberStatus === "loading" ||
                      addTeamMemberStatus === "loading" ||
                      deleteTeamMemberStatus === "loading" ? (
                        <TableRow>
                          <TableCell colSpan={4}>
                            <Spinner />
                          </TableCell>
                        </TableRow>
                      ) : (
                        <>
                          {team_members
                            ?.slice(
                              page * rowsPerPage,
                              page * rowsPerPage + rowsPerPage,
                            )
                            .map((el) => (
                              <TableRow
                                key={el.id}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={tableEmployeeHeaderCell}
                                  style={styleCell}
                                >
                                  {el.full_name}
                                </TableCell>
                                <TableCell
                                  sx={tableEmployeeHeaderCell}
                                  style={styleCell}
                                >
                                  {selectRole.map((emp) =>
                                    emp.value === el.position
                                      ? t(emp.label)
                                      : null,
                                  )}
                                </TableCell>
                                <TableCell
                                  sx={tableEmployeeHeaderCell}
                                  style={styleCell}
                                >
                                  {el.phone}
                                </TableCell>
                                <TableCell>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      flexDirection: "row",
                                    }}
                                  >
                                    <button
                                      onClick={() => edit(el.id)}
                                      className="infoEmployees__btn"
                                    >
                                      <img
                                        src="/images/edit-icon2.svg"
                                        alt="Edit"
                                      />
                                    </button>
                                    <button
                                      className="infoEmployees__btn"
                                      onClick={() => OpenRemoveModal(el.id)}
                                    >
                                      <img
                                        src="/images/delete-icon.svg"
                                        alt="Delete"
                                      />
                                    </button>
                                  </div>
                                </TableCell>
                              </TableRow>
                            ))}
                        </>
                      )}
                    </TableBody>
                    <TableFooter
                      sx={{ background: "rgba(243, 245, 250, 0.4)" }}
                    >
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[]}
                          count={team_members?.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          onPageChange={handleChangePage}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>

                <button
                  onClick={() => setOpenModalAdd(true)}
                  className="btn-add-new-employee"
                >
                  <ListItemIcon sx={{ minWidth: 0, mr: "10px" }}>
                    <AddIcon />
                  </ListItemIcon>
                  {t("NEW_EMPLOYEE")}
                </button>

                <Dialog
                  open={openModalEdit}
                  onClose={() => setOpenModalEdit(false)}
                  maxWidth="xs"
                >
                  <ModalBase
                    onClose={() => setOpenModalEdit(false)}
                    title={t("EDIT_EMPLOYEE")}
                  >
                    <FormModal
                      value={infoEmployee}
                      onClose={() => setOpenModalEdit(false)}
                    />
                  </ModalBase>
                </Dialog>

                <Dialog
                  open={openModalAdd}
                  onClose={() => setOpenModalAdd(false)}
                  maxWidth="xs"
                >
                  <ModalBase
                    onClose={() => setOpenModalAdd(false)}
                    title={t("ADD_NEW_EMPLOYEE")}
                  >
                    <FormModal onClose={() => setOpenModalAdd(false)} />
                  </ModalBase>
                </Dialog>

                <Dialog
                  open={openModalDelete}
                  onClose={() => setOpenModalDelete(false)}
                  maxWidth="xs"
                >
                  <ModalBase
                    onClose={() => setOpenModalDelete(false)}
                    title={t("DELETE_EMPLOYEE")}
                  >
                    <div>
                      <p className="modal-delete-text">
                        {t("DELETE_EMPLOYEE_DESCRIPTION", {
                          full_name: infoEmployee.full_name,
                        })}
                      </p>

                      <div className="btn-block">
                        <button
                          className="modal-delete-btn"
                          onClick={() => setOpenModalDelete(false)}
                        >
                          {t("CANCEL_BTN")}
                        </button>
                        <button
                          className="modal-delete-btn"
                          onClick={() => removeEmployee(infoEmployee.id)}
                        >
                          {t("DELETE_BTN")}
                        </button>
                      </div>
                    </div>
                  </ModalBase>
                </Dialog>
              </>
            )}
          </>
        )}

        {select === "accountInfo" && (
          <>
            {emailChangeStatus === "loading" ||
            passwordChangeStatus === "loading" ? (
              <Spinner />
            ) : (
              <>
                <div className="infoEmployees__row">
                  <p> {t("ACCOUNT_INFO_TAB.EMAIL")}</p>
                  <p>{email}</p>

                  <div>
                    <button
                      className="accountInfo__btn"
                      onClick={() => setOpenModalChangeEmail(true)}
                    >
                      {t("ACCOUNT_INFO_TAB.CHANGE")}
                    </button>
                  </div>
                  <p></p>
                </div>
                <div className="infoEmployees__row">
                  <p>{t("ACCOUNT_INFO_TAB.PASSWORD")}</p>
                  <p>{hiddenPassword}</p>

                  <div>
                    <button
                      className="accountInfo__btn"
                      onClick={() => setOpenModalChangePassword(true)}
                    >
                      {t("ACCOUNT_INFO_TAB.CHANGE")}
                    </button>
                    <p></p>
                  </div>
                </div>

                <Dialog
                  open={openModalChangeEmail}
                  onClose={() => setOpenModalChangeEmail(false)}
                  maxWidth="sm"
                >
                  <DialogTitle>
                    {t("ACCOUNT_INFO_TAB.CHANGE_EMAIL")}
                    <IconButton
                      aria-label="close"
                      onClick={() => setOpenModalChangeEmail(false)}
                      style={{ position: "absolute", right: "8px", top: "8px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <FormChange
                      password={userEmailAndPassword.password}
                      onClose={() => setOpenModalChangeEmail(false)}
                      change="email"
                    />
                  </DialogContent>
                </Dialog>

                <Dialog
                  open={openModalChangePassword}
                  onClose={() => setOpenModalChangePassword(false)}
                  maxWidth="sm"
                >
                  <DialogTitle>
                    {t("ACCOUNT_INFO_TAB.CHANGE_PASSWORD")}
                    <IconButton
                      aria-label="close"
                      onClick={() => setOpenModalChangePassword(false)}
                      style={{ position: "absolute", right: "8px", top: "8px" }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </DialogTitle>
                  <DialogContent>
                    <FormChange
                      password={userEmailAndPassword.password}
                      onClose={() => setOpenModalChangePassword(false)}
                      change="password"
                    />
                  </DialogContent>
                </Dialog>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default InfoEmployees;
