const locale = {
  POWER_GENERATION_ARR: {
    POWER_GENERATION: "Выработка электроэнергии",
    PLANNED_OUTPUT: "Плановая выработка",
    ACTUAL_OUTPUT: "Фактическая выработка",
  },
  STATION_INDICATORS_ARR: {
    STATION_INDICATORS: "Показатели станции",
    FACT: "Факт",
    FORECAST: "Прогноз",
    PROFIT: "Доход",
    CUF: "КИУМ",
    ENERGY_CONSUMPTION: "Энергопотребление",
    EMISSIONS_REDUCTION: "Снижение выбросов СО2",
  },
  METEODATA_ARR: {
    METEODATA: "Метеоданные",
    TEMPERATURE: "Температура",
    IRRADIANCE: "Иррадиация",
    CLOUDINESS: "Облачность",
    WIND_SPEED: "Скорость ветра",
    PRECIPITATION: "Осадки",
    FACT: "Факт",
    FORECAST: "Прогноз",
  },
  REPORT: {
    ERROR: "Отсутствуют данные за выбранный период",
    INVALID_DATE: "Неверный формат периода",
    TOTAL: "Всего",
    TIME: "Время",
    PLANNED_OUTPUT_INTECH: "Плановая выработка (Intech)",
    PLANNED_OUTPUT_METEOLOGICA: "Плановая выработка (Meteologica)",
    ACTUAL_OUTPUT: "Фактическая выработка",
    OUTPUT_UNIT: "кВт*ч",
    TEMPERATURE_PREDICTION: "Температура (прогноз)",
    TEMPERATURE_FACT: "Температура (факт)",
    IRRADIANCE_PREDICTION: "Иррадиация (прогноз)",
    IRRADIANCE_FACT: "Иррадиация (факт)",
    IRRADIANCE_UNIT: "кВт‧ч/м²",
    CLOUDINESS_PREDICTION: "Облачность (прогноз)",
    CLOUDINESS_FACT: "Облачность (факт)",
    WIND_SPEED_PREDICTION: "Скорость ветра (прогноз)",
    WIND_SPEED_FACT: "Скорость ветра (факт)",
    WIND_SPEED_UNIT: "м/с",
    PRECIPITATION_PREDICTION: "Осадки (прогноз)",
    PRECIPITATION_FACT: "Осадки (факт)",
    PRECIPITATION_UNIT: "мм",
    PROFIT_PREDICTION_INTECH: "Доход (прогноз Intech)",
    PROFIT_PREDICTION_METEOLOGICA: "Доход (прогноз Meteologica)",
    PROFIT_FACT: "Доход (факт)",
    CUF_PREDICTION_INTECH: "КИУМ (прогноз Intech)",
    CUF_PREDICTION_METEOLOGICA: "КИУМ (прогноз Meteologica)",
    CUF_FACT: "КИУМ (факт)",
    ENERGY_CONSUMPTION_PREDICTION_INTECH: "Энергопотребление (прогноз Intech)",
    ENERGY_CONSUMPTION_PREDICTION_METEOLOGICA:
      "Энергопотребление (прогноз Meteologica)",
    ENERGY_CONSUMPTION_FACT: "Энергопотребление (факт)",
    ENERGY_CONSUMPTION_UNIT: "кВт*ч",
    EMISSIONS_REDUCTION_PREDICTION_INTECH:
      "Снижение выбросов СО2 (прогноз Intech)",
    EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA:
      "Снижение выбросов СО2 (прогноз Meteologica)",
    EMISSIONS_REDUCTION_FACT: "Снижение выбросов СО2 (факт)",
    EMISSIONS_REDUCTION_UNIT: "тонн",
  },
  EXPORT: {
    TITLE: "Период экспорта",
    DAY: "День",
    MONTH: "Месяц",
    YEAR: "Год",
    SUBTITLE: "Данные для экспорта",
    EXPORT: "Скачать таблицу",
    START_DATE: "Дата начала",
    END_DATE: "Дата окончания",
  },
};

export default locale;
