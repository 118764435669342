const locale = {
  HEADER: {
    TIME: "Уақыт",
    PREDICTION: "Болжам",
    ACTUAL: "Нақты",
    ACCURACY: "Нақтылық",
  },
  TOTAL: "Барлығы",
};
export default locale;
