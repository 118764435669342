const locale = {
  STATION: {
    MW: "MW",
    NEW_STATION: "New Station",
  },
  NAVBAR: {
    POWER_GENERATION: "Power Generation",
    METEODATA: "Meteodata",
    STATION_PERFORMANCE: "Station Performance",
    MONITORING: "Monitoring",
  },
  ACCOUNT_MENU: {
    PROFILE: "Profile",
    PROFILE_SETTINGS: "Profile Settings",
    STATION_SETTINGS: "Station Settings",
    LOGOUT: "Logout",
  },
};

export default locale;
