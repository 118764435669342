const locale = {
  TITLE: "Профиль параметрлері",
  EMPLOYEES: "Жұмысшылар",
  ACCOUNT_INFO: "Аккаунт мәліметтері",
  ERROR: "Error",
  FULL_NAME: "Аты-жөні",
  POSITION: "Жұмыс дәрежесі",
  PHONE_NO: "Телефон нөмірі",
  NEW_EMPLOYEE: "Жаңа жұмысшы",
  EDIT_EMPLOYEE: "Жұмысшының мәліметтерін өңдеу",
  ADD_NEW_EMPLOYEE: "Жаңа жұмысшыны қосу",
  DELETE_EMPLOYEE: "Жұмысшыны жою",
  DELETE_EMPLOYEE_DESCRIPTION: "Жұмысшы {{full_name}} жойылады",
  CANCEL_BTN: "Болдырмау",
  DELETE_BTN: "Жою",
  ACCOUNT_INFO_TAB: {
    EMAIL: "Email",
    PASSWORD: "Құпия сөз",
    CHANGE: "Өзгерту",
    CHANGE_EMAIL: "Email-ді өзгерту",
    CHANGE_PASSWORD: "Құпия сөзді өзгерту",
  },
  FORM_MODAL: {
    ENTER_FULL_NAME: "Жұмысшының аты-жөнін енгізіңіз",
    SELECT_POSITION: "Жұмыс дәрежесін таңдаңыз",
    DIRECTOR: "Директор",
    ENGINEER: "Инженер",
    OPERATOR: "Оператор",
    DISPATCHER: "Диспетчер",
    SAVE: "Сақтау",
    SEND: "Жіберу",
  },
  FORM_CHANGE: {
    SAVE: "Сақтау",
    EMAIL: {
      NEW: "Жаңа email",
      NEW_PLACEHOLDER: "Жаңа email-ді енгізіңіз",
      CONFIRM: "Email-ді қайталаңыз",
      CONFIRM_PLACEHOLDER: "Жаңа email-ді қайталаңыз",
      CURRENT_PASSWORD: "Аккаунттың құпия сөзі",
      CURRENT_PASSWORD_PLACEHOLDER: "Аккаунттың құпия сөзін енгізіңіз",
    },
    PASSWORD: {
      CURRENT: "Қазіргі құпия сөз",
      CURRENT_PLACEHOLDER: "Құпия сөзді енгізіңіз",
      NEW: "Жаңа құпия сөз",
      NEW_PLACEHOLDER: "Кемінде 8 таңба",
      REPEAT: "Құпия сөзді қайталаңыз",
    },
    VALIDATION: {
      EMAIL: "Email мекен-жайында «@» белгісі болуы керек.",
      REQUIRED: "Міндетті түрде толтырыңыз",
      EMAIL_NO_MATCH: "Email-деректері сәйкес келмейді",
      WRONG_PASSWORD: "Құпия сөз дұрыс емес",
      PASSWORD_LENGTH: "Құпия сөз 8 таңбадан кем болмауы керек",
      PASSWORD_NO_MATCH: "Құпия сөздер сәйкес келмейді",
    },
  },
  UPDATE: {
    LOADING: "Деректерді жаңарту, күте тұрыңыз..",
    SUCCESS: "Деректер сәтті жаңартылды",
    ERROR: "Деректерді жаңарту қатесі",
  },
  ADD: {
    LOADING: "Деректерді қосу, күте тұрыңыз..",
    SUCCESS: "Деректер сәтті қосылды",
    ERROR: "Деректерді қосу қатесі",
  },
  DELETE: {
    LOADING: "Деректерді жою, күте тұрыңыз..",
    SUCCESS: "Деректер сәтті жойылды",
    ERROR: "Деректерді жою қатесі",
  },
  EMAIL_CHANGE: {
    LOADING: "Email-ді өзгерту, күте тұрыңыз..",
    SUCCESS: "Email сәтті өзгертілді",
    ERROR: "Email өзгерту қатесі",
  },
  PASSWORD_CHANGE: {
    LOADING: "Құпия сөзді өзгерту, күте тұрыңыз..",
    SUCCESS: "Құпия сөз сәтті өзгертілді",
    ERROR: "Құпия сөзді өзгерту қатесі",
  },
};
export default locale;
