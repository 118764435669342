import { createSlice } from "@reduxjs/toolkit";
import i18n from "../../../i18n";

const i18nSlice = createSlice({
  name: "i18n",
  initialState: {
    language: i18n.options.lng,
    languages: [
      { id: "en", title: "English", flag: "US" },
      { id: "kz", title: "Kazakh", flag: "KZ" },
      { id: "ru", title: "Russian", flag: "RU" },
    ],
  },
  reducers: {
    languageChanged: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const { languageChanged } = i18nSlice.actions;

export default i18nSlice.reducer;
