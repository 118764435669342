const locale = {
  POWER_GENERATION_ARR: {
    POWER_GENERATION: "Power generation",
    PLANNED_OUTPUT: "Planned output",
    ACTUAL_OUTPUT: "Actual output",
  },
  STATION_INDICATORS_ARR: {
    STATION_INDICATORS: "Station indicators",
    FACT: "Fact",
    FORECAST: "Forecast",
    PROFIT: "Profit",
    CUF: "CUF",
    ENERGY_CONSUMPTION: "Energy consumption",
    EMISSIONS_REDUCTION: "CO2 emissions reduction",
  },
  METEODATA_ARR: {
    METEODATA: "Meteodata",
    TEMPERATURE: "Temperature",
    IRRADIANCE: "Irradiance",
    CLOUDINESS: "Cloudiness",
    WIND_SPEED: "Wind speed",
    PRECIPITATION: "Precipitation",
    FACT: "Fact",
    FORECAST: "Forecast",
  },
  REPORT: {
    ERROR: "No data for the selected period",
    INVALID_DATE: "Invalid date for period",
    TOTAL: "Total",
    TIME: "Time",
    PLANNED_OUTPUT_INTECH: "Planned output (Intech)",
    PLANNED_OUTPUT_METEOLOGICA: "Planned output (Meteologica)",
    ACTUAL_OUTPUT: "Actual output",
    OUTPUT_UNIT: "кВт*ч",
    TEMPERATURE_PREDICTION: "Temperature (forecast)",
    TEMPERATURE_FACT: "Temperature (fact)",
    IRRADIANCE_PREDICTION: "Irradiance (forecast)",
    IRRADIANCE_FACT: "Irradiance (fact)",
    IRRADIANCE_UNIT: "kW‧h/m²",
    CLOUDINESS_PREDICTION: "Cloudiness (forecast)",
    CLOUDINESS_FACT: "Cloudiness (fact)",
    WIND_SPEED_PREDICTION: "Wind speed (forecast)",
    WIND_SPEED_FACT: "Wind speed (fact)",
    WIND_SPEED_UNIT: "m/s",
    PRECIPITATION_PREDICTION: "Precipitation (forecast)",
    PRECIPITATION_FACT: "Precipitation (fact)",
    PRECIPITATION_UNIT: "mm",
    PROFIT_PREDICTION_INTECH: "Profit (Intech forecast)",
    PROFIT_PREDICTION_METEOLOGICA: "Profit (Meteologica forecast)",
    PROFIT_FACT: "Profit (fact)",
    CUF_PREDICTION_INTECH: "CUF (Intech forecast)",
    CUF_PREDICTION_METEOLOGICA: "CUF (Meteologica forecast)",
    CUF_FACT: "CUF (fact)",
    ENERGY_CONSUMPTION_PREDICTION_INTECH:
      "Energy consumption (Intech forecast)",
    ENERGY_CONSUMPTION_PREDICTION_METEOLOGICA:
      "Energy consumption (Meteologica forecast)",
    ENERGY_CONSUMPTION_FACT: "Energy consumption (fact)",
    ENERGY_CONSUMPTION_UNIT: "kW‧h",
    EMISSIONS_REDUCTION_PREDICTION_INTECH:
      "CO2 emissions reduction (Intech forecast)",
    EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA:
      "CO2 emissions reduction (Meteologica forecast)",
    EMISSIONS_REDUCTION_FACT: "CO2 emissions reduction (fact)",
    EMISSIONS_REDUCTION_UNIT: "ton",
  },
  EXPORT: {
    TITLE: "Export period",
    DAY: "Day",
    MONTH: "Month",
    YEAR: "Year",
    SUBTITLE: "Export data",
    EXPORT: "Download report",
    START_DATE: "Start date",
    END_DATE: "End date",
  },
};

export default locale;
