const locale = {
  POWER_GENERATION_ARR: {
    POWER_GENERATION: "Қуат өндірісі",
    PLANNED_OUTPUT: "Жоспарланған өндіріс",
    ACTUAL_OUTPUT: "Нақты өндіріс",
  },
  STATION_INDICATORS_ARR: {
    STATION_INDICATORS: "Станцияның өнімділігі",
    FACT: "Нақты",
    FORECAST: "Болжам",
    PROFIT: "Табыс",
    CUF: "ҚПК",
    ENERGY_CONSUMPTION: "Энергия тұтынуы",
    EMISSIONS_REDUCTION: "СО2 өндірісінің төмендету",
  },
  METEODATA_ARR: {
    METEODATA: "Метеодеректер",
    TEMPERATURE: "Температура",
    IRRADIANCE: "Иррадиация",
    CLOUDINESS: "Бұлттылық",
    WIND_SPEED: "Жел жылдамдығы",
    PRECIPITATION: "Жауын-шашын",
    FACT: "Нақты",
    FORECAST: "Болжам",
  },
  REPORT: {
    ERROR: "Таңдалған уақытқа арналған деректер жоқ",
    INVALID_DATE: "Уақыт аралығын қате көрсеттіңіз",
    TOTAL: "Барлығы",
    TIME: "Уақыт",
    PLANNED_OUTPUT_INTECH: "Жоспарланған өндіріс (Intech)",
    PLANNED_OUTPUT_METEOLOGICA: "Жоспарланған өндіріс (Meteologica)",
    ACTUAL_OUTPUT: "Нақты өндіріс",
    OUTPUT_UNIT: "кВт*сағ",
    TEMPERATURE_PREDICTION: "Температура (болжам)",
    TEMPERATURE_FACT: "Температура (нақты)",
    IRRADIANCE_PREDICTION: "Иррадиация (болжам)",
    IRRADIANCE_FACT: "Иррадиация (нақты)",
    IRRADIANCE_UNIT: "кВт‧сағ/м²",
    CLOUDINESS_PREDICTION: "Бұлттылық (болжам)",
    CLOUDINESS_FACT: "Бұлттылық (нақты)",
    WIND_SPEED_PREDICTION: "Жел жылдамдығы (болжам)",
    WIND_SPEED_FACT: "Жел жылдамдығы (нақты)",
    WIND_SPEED_UNIT: "м/с",
    PRECIPITATION_PREDICTION: "Жауын-шашын (болжам)",
    PRECIPITATION_FACT: "Жауын-шашын (нақты)",
    PRECIPITATION_UNIT: "мм",
    PROFIT_PREDICTION_INTECH: "Табыс (Intech болжам)",
    PROFIT_PREDICTION_METEOLOGICA: "Табыс (Meteologica болжам)",
    PROFIT_FACT: "Табыс (нақты)",
    CUF_PREDICTION_INTECH: "ҚПК (Intech болжам)",
    CUF_PREDICTION_METEOLOGICA: "ҚПК (Meteologica болжам)",
    CUF_FACT: "ҚПК (нақты)",
    ENERGY_CONSUMPTION_PREDICTION_INTECH: "Энего тұтынуы (Intech болжам)",
    ENERGY_CONSUMPTION_PREDICTION_METEOLOGICA:
      "Энего тұтынуы (Meteologica болжам)",
    ENERGY_CONSUMPTION_FACT: "Энего тұтынуы (нақты)",
    ENERGY_CONSUMPTION_UNIT: "кВт*сағ",
    EMISSIONS_REDUCTION_PREDICTION_INTECH:
      "СО2 өндірісінің төмендету (Intech болжам)",
    EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA:
      "СО2 өндірісінің төмендету (Meteologica болжам)",
    EMISSIONS_REDUCTION_FACT: "СО2 өндірісінің төмендету (нақты)",
    EMISSIONS_REDUCTION_UNIT: "тонна",
  },
  EXPORT: {
    TITLE: "Экспорт кезеңі",
    DAY: "Күн",
    MONTH: "Ай",
    YEAR: "Жыл",
    SUBTITLE: "Экспорт туралы мәліметтер",
    EXPORT: "Кестені жүктеу",
    START_DATE: "Басталу күні",
    END_DATE: "Аяқталу күні",
  },
};

export default locale;
