import DiagramLocale from "../../../../UI/Diagram/i18n/en";
import CustomTableLocale from "../../../../UI/CustomTable/i18n/en";
import FormExportLocale from "../../../../UI/FormExport/i18n/en";
const locale = {
  NO_ACTUAL_DATA: "No actual data",
  NO_DATA: "Data unavailable for the selected date. We apologize for any inconvenience.",
  TODAY: "Today",
  TOMORROW: "Tomorrow",
  OTHER_DATE: "Other date",
  CHART: "Chart",
  TABLE: "Table",
  ACCURACY: "Accuracy",
  TITLE: "Meteodata",
  CURRENT_DATA: "Current meteodata",
  NO_CURRENT_DATA: "No current meteodata",
  TABS: {
    TEMPERATURE: "Temperature",
    IRRADIANCE: "Irradiance",
    CLOUDINESS: "Cloudiness",
    WIND_SPEED: "Wind speed",
    PRECIPITATION: "Precipitation",
    TEMPERATURE_PANEL: "Temperature Fem"
  },
  IRRADIANCE_UNIT: "kW‧h/m²",
  WIND_SPEED_UNIT: "m/s",
  PRECIPITATION_UNIT: "mm",
  EXPORT_TABLE: "Export table",
  ...DiagramLocale,
  ...CustomTableLocale,
  ...FormExportLocale,
};
export default locale;
