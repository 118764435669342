import * as yup from "yup";

// Validation schema for Step 1
export const step1Schema = yup.object().shape({
  phone: yup.string().required("VALIDATION.REQUIRED"),
  email: yup
    .string()
    .email("VALIDATION.EMAIL_FORMAT")
    .required("VALIDATION.REQUIRED"),
});
// Validation schema for Step 2
export const step2Schema = yup.object().shape({
  name: yup.string().required("VALIDATION.REQUIRED"),
  nameCompany: yup.string().required("VALIDATION.REQUIRED"),
  location: yup.string().required("VALIDATION.REQUIRED"),
});

// export const step3Schema = yup.object().shape({
//     email: yup.string().email('Invalid email').required('Обязательное поле'),
//     news: yup.boolean(),
//     userAgreement: yup.boolean().oneOf([true], 'Необходимо принять пользовательское соглашение')
// });
