// Path: src\app\auth\services\jwtService\jwtServiceConfig.js
const jwtServiceConfig = {
    signIn: 'api/auth/sign-in',
    accessToken: 'api/auth/access-token',
    deleteTeamMember: 'api/team_member',
    postTeamMember: 'api/team_member',
    patchUserEmail: 'api/user/email',
    patchUserPassword: 'api/user/password',
    getTeamMember: 'api/team_member',
};

export default jwtServiceConfig;
