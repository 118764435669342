import React from "react";
import { Bar } from "react-chartjs-2";

const BarDiagram = ({ data, activeTab, t }) => {
  const labels = data[0]?.series?.map((item) => item.name);
  // const dataActual = data?.map((item) => item?.actual);
  // const dataPrediction = data.map((item) => item.prediction);

  const monthLabels = labels?.map((monthNum) => {
    const months = [
      t("MONTHS.JANUARY"),
      t("MONTHS.FEBRUARY"),
      t("MONTHS.MARCH"),
      t("MONTHS.APRIL"),
      t("MONTHS.MAY"),
      t("MONTHS.JUNE"),
      t("MONTHS.JULY"),
      t("MONTHS.AUGUST"),
      t("MONTHS.SEPTEMBER"),
      t("MONTHS.OCTOBER"),
      t("MONTHS.NOVEMBER"),
      t("MONTHS.DECEMBER"),
    ];
    const monthIndex = monthNum - 1;
    return months[monthIndex];
  });

  const colors = ["#F07048", "#3b819a", "#f52e4b"];
  const generateChartData = () => {
    return data?.map((item, index) => {
      const isActual = item.name === "Actual";

      return {
        label: item.name,
        backgroundColor: isActual ? "#A9ABB8" : colors.at(index - 1),
        data: item.series.map((subItem) => parseInt(subItem.value, 10)),

        tension: 0.1,
      };
    });
  };

  // Function to compute the total of an array
  // Get the totals
  const chartData = generateChartData();

  return (
    <div className="diagram">
      <div className="diagram__header">
        <div className="diagram__header-title header-title-bar">
          {chartData.map((dataset, index) => (
            <div
              key={index}
              className="diagram__header-title"
              style={{
                flexDirection: "column",
              }}
            >
              <div>
                {/* Conditional styling based on dataset type. This can be adjusted as per requirements */}
                <span
                  className="diagram__line"
                  style={{
                    backgroundColor: dataset.backgroundColor,
                  }}
                ></span>
                <span className="diagram__title-name">{dataset.label}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="chart-container">
        <Bar
          data={{
            labels: activeTab === "monthly" ? labels : monthLabels,
            datasets: chartData,
          }}
          options={{
            plugins: {
              legend: {
                display: false,
              },
            },
            responsive: true,
            maintainAspectRatio: false,
          }}
        />
      </div>
    </div>
  );
};

export default BarDiagram;
