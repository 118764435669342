import * as React from "react";
import { useEffect, useState } from "react";
import {
  dailyStateCalendar,
  dailyStateCalendarTab,
  dailyStateCalendarTabCenter,
  dailyStateCalendarTabLeft,
  dailyStateCalendarTabRight,
  datePickerStyle,
} from "../../../../../utils/stylesForMui";
import { Tab, Tabs, Grid, Avatar, Dialog } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ru from "date-fns/locale/ru";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { loadStats } from "../../../../../store/diagram/diagramThunks";
import { useDispatch, useSelector } from "react-redux";
import LineDiagram from "../../../../UI/Diagram/LineDiagram";
import CustomTable from "../../../../UI/CustomTable/CustomTable";
import Spinner from "../../../../UI/Spinner/Spinner";
import Widget from "../../../../UI/Widget/Widget";
import { format } from "date-fns";
import {
  calculateAverageDeviation,
  calculateAverageDeviationInPercentage,
  total,
  getVisibleDays
} from "../../../../../utils/utilsFunc";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { selectCurrentLanguageId } from "../../../../../store/i18n/i18nThunks";
import { useTranslation } from "react-i18next";
import { enUS, kk } from "date-fns/locale";
import ModalBase from "../../../shared-component/ModalBase/ModalBase";
import FormExport from "../../../../UI/FormExport/FormExport";

function DayPowerStats() {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.stats);
  const { mainStationInfo } = useSelector((state) => state.dashboard);
  const currentLanguage = useSelector(selectCurrentLanguageId);
  const { t } = useTranslation("powerGenerationPage");
  const localeMap = {
    en: enUS,
    ru: ru,
    kz: kk,
  };
  const [currentLocale, setCurrentLocale] = useState(
    localeMap[currentLanguage],
  );
  const [activeTab, setActiveTab] = useState("today");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDataPicker, setOpenDataPicker] = useState(false);
  const [typeData, setTypeData] = useState("chart");
  const [openModalExport, setOpenModalExport] = useState(false);
  const [isPlannedChecked, setIsPlannedChecked] = useState(true);
  const [isActualChecked, setIsActualChecked] = useState(true);
  const [isMeteoloficaChecked, setIsMeteologicaChecked] = useState(true);
  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  const showAllWidgets = () => {
    const selectedDateFormatted = format(selectedDate, "yyyy-MM-dd");
    const tomorrowDateFormatted = format(getTomorrowDate(), "yyyy-MM-dd");
    return (
      (activeTab === "tomorrow" ||
        selectedDateFormatted >= tomorrowDateFormatted) &&
      activeTab !== "today"
    );
  };

  const getActiveTabDate = () => {
    if (activeTab === "today") {
      return format(new Date(), "yyyy-MM-dd");
    } else if (activeTab === "tomorrow") {
      return format(getTomorrowDate(), "yyyy-MM-dd");
    } else {
      return format(selectedDate, "yyyy-MM-dd");
    }
  };

  useEffect(() => {
    setCurrentLocale(localeMap[currentLanguage]);

    dispatch(
      loadStats({
        statBlock: "stats",
        statType: activeTab,
        date: getActiveTabDate(),
      }),
    );
  }, [activeTab, selectedDate, dispatch, currentLanguage]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      loadStats({
        statBlock: "stats",
        statType: "otherDate",
        date: format(selectedDate, "yyyy-MM-dd"),
      }),
    );
  };

  const numOfNotFilledActualValues = stats[activeTab].data
    ?.find((item) => item.name === "Actual")
    ?.series.reduce(
      (accumulator, obj) =>
        obj.value !== null ? parseInt(accumulator) + 1 : accumulator,
      0,
    );

  const predictions =
    stats[activeTab] && Array.isArray(stats[activeTab].data)
      ? stats[activeTab].data?.find((item) => item.name === "Intech")
      : null;

  const actualDataPrediction = predictions
    ? predictions.series.slice(0, numOfNotFilledActualValues)
    : null;

  const totalActual = total(stats[activeTab].data, "Actual");
  const totalPrediction = total(stats[activeTab].data, "Intech");
  const totalPredictionForApd = actualDataPrediction?.reduce(
    (acc, val) => acc + parseInt(val.value),
    0,
  );

  const apd =
    totalActual !== 0
      ? Math.abs(
          100 -
            (Math.abs(totalActual - totalPredictionForApd) /
              Math.abs((totalActual + totalPredictionForApd) / 2)) *
              100,
        ).toFixed(2)
      : 0;
  console.log(apd)
  let averageDeviation =
    totalActual !== 0
      ? calculateAverageDeviation(stats[activeTab].data).toFixed(2)
      : 0;
  // averageDeviation = (Math.abs(totalActual - totalPredictionForApd) / totalActual).toFixed(2) * 100;  

  const averageDeviationInPercent =
    totalActual !== 0
      ? calculateAverageDeviationInPercentage(stats[activeTab].data).toFixed(2)
      : 0;

  const renderDataByType = () => {
    return typeData === "chart" ? (
      <LineDiagram
        data={stats[activeTab].data}
        day={showAllWidgets()}
        t={t}
        setIsActualChecked={setIsActualChecked}
        setIsPlannedChecked={setIsPlannedChecked}
        setIsMeteologicaChecked={setIsMeteologicaChecked}
      />
    ) : (
      <CustomTable
        data={stats[activeTab].data}
        totalActual={totalActual}
        totalPrediction={totalPrediction}
        apd={apd}
        day={showAllWidgets()}
        t={t}
      />
    );
  };

  const renderErrorOrTheData = () => {
    return stats[activeTab].error ? (
      <div className="error title">
        <Avatar
          src={"/images/error.jpeg"}
          alt={"error"}
          sx={{ width: 150, height: 150 }}
        />
        <p style={{ color: "#666666" }}>{t("NO_DATA")}</p>
      </div>
    ) : (
      renderDataByType()
    );
  };

  const getDataWidget = () => {
    const coReduceConst = 0.0006;
    const KIUM =
      numOfNotFilledActualValues !== 0 && mainStationInfo.capacity !== 0
        ? (
            ((parseInt(totalActual) /
              (numOfNotFilledActualValues * mainStationInfo.capacity)) *
              100)
          ).toFixed(2)
        : 0;
    // -----------------------------
    const EMISSION = (totalActual * coReduceConst).toFixed(2)
    .toLocaleString("ru-RU");
    const widget = [
      {
        title: t("DAY_ACCURACY"),
        data: apd !== 0 ? `${apd} %` : t("NO_ACTUAL_DATA"),
        dataName: "",
      },
      {
        title: t("AVERAGE_POWER_DEVIATION"),
        data: (totalActual == 0 || totalPredictionForApd == 0) ? t("NO_ACTUAL_DATA") : `${averageDeviation.toLocaleString("ru-RU")} %`,
        dataName: "",
      },
      /*{
        title: t("AVERAGE_POWER_DEVIATION") + " (%)",
        data: averageDeviationInPercent.toLocaleString("ru-RU"),
        dataName: "%",
      },*/
      {
        title: t("CAPACITY_FACTOR"),
        data: KIUM != 0 ? `${KIUM} %` : t("NO_ACTUAL_DATA"),
        dataName: "",
      },
      {
        title: t("REVENUE"),
        // data: (totalActual * mainStationInfo.tariff).toLocaleString("ru-RU"),
        data: totalActual !== 0 ? `${totalActual * 20 * 1000} ₸` : t("NO_ACTUAL_DATA"),
        dataName: "",
        // date: t("REVENUE_DESCRIPTION", { tariff: mainStationInfo.tariff }),
        date: t("REVENUE_DESCRIPTION", { tariff: 20 }),
      },
      {
        title: t("EMISSIONS_REDUCTION"),
        data: totalActual !== 0 ? `${EMISSION} ${t("EMISSIONS_ABBREVIATION")}` : t("NO_ACTUAL_DATA"),
        dataName: "",
      },
    ];

    return widget;
  };

  return (
    <>
      <Grid container spacing={2}>
        {/*------ TABS ------*/}
        <Grid
          item
          xs={12}
          md={stats[activeTab].error || showAllWidgets() ? 12 : 9}
        >
          <div className="tab-block">
            <div className="date-block">
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("TODAY")}
                  value={"today"}
                  style={{
                    backgroundColor: activeTab === "today" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("TOMORROW")}
                  value={"tomorrow"}
                  style={{
                    backgroundColor: activeTab === "tomorrow" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabCenter}
                />
                <Tab
                  label={t("OTHER_DATE")}
                  value={"otherDate"}
                  style={{
                    backgroundColor: activeTab === "otherDate" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>

            {activeTab === "otherDate" && (
              <div className="date-block">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={currentLocale}
                >
                  <DesktopDatePicker
                    sx={datePickerStyle}
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={(date) =>
                      date > new Date(Date.now() + getVisibleDays())
                    }
                    open={openDataPicker}
                    onClose={() => setOpenDataPicker(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setOpenDataPicker(true),
                      },
                      OpenPickerIcon: CalendarTodayIcon,
                    }}
                    orientation="portrait"
                  />
                </LocalizationProvider>
              </div>
            )}

            <div className="view-block">
              <Tabs
                value={typeData}
                onChange={(_, newValue) => setTypeData(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("CHART")}
                  value={"chart"}
                  style={{
                    backgroundColor: typeData === "chart" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("TABLE")}
                  value={"table"}
                  style={{
                    backgroundColor: typeData === "table" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>

            <btn
              className="btn-download"
              onClick={() => setOpenModalExport(true)}
            >
              {t("EXPORT_TABLE")}
            </btn>

            <Dialog
              open={openModalExport}
              onClose={() => setOpenModalExport(false)}
              maxWidth="xs"
            >
              <ModalBase
                onClose={() => setOpenModalExport(false)}
                title={t("EXPORT_TABLE")}
              >
                <FormExport
                  date={selectedDate}
                  activeTab={"selectDate"}
                  isPlannedChecked={isPlannedChecked}
                  isActualChecked={isActualChecked}
                  isMeteologicaChecked={isMeteoloficaChecked}
                  t={t}
                  currentLocale={currentLocale}
                />
              </ModalBase>
            </Dialog>
          </div>

          <div
            className="chart"
            style={{
              background: stats[activeTab].error
                ? ""
                : "rgba(243, 245, 250, 0.4)",
            }}
          >
            {stats[activeTab].loaded ? renderErrorOrTheData() : <Spinner />}
          </div>
        </Grid>
        {/* ---------------------------------------- */}

        {/* --------------- Widgets --------------- */}

        {!showAllWidgets() &&
          stats[activeTab].loaded &&
          !stats[activeTab].error && (
            <Grid item xs={12} md={3}>
              <div className="widgets">
                {getDataWidget(stats[activeTab].data).map((el, i) => (
                  <Widget
                    key={i}
                    title={el.title}
                    data={el.data}
                    dataName={el.dataName}
                    date={el.date}
                  />
                ))}
              </div>
            </Grid>
          )}

        {/* ---------------------------------------- */}
      </Grid>
    </>
  );
}

export default DayPowerStats;
