import React, { useEffect, useState } from "react";
import { Box, FormControl, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";
import { CssTextField } from "../../../../utils/customCssTextField";
import { useTranslation } from "react-i18next";

const Placeholder = ({ children }) => {
  return <div className="selectPlaceholder">{children}</div>;
};

const Step2 = ({ control, errors }) => {
  const [locationData, setLocationData] = useState([]);
  const { t } = useTranslation("signUpPage", { keyPrefix: "STEP_2" });

  useEffect(() => {
    setLocationData([
      { id: 1, name: t("REGIONS.ABAY"), value: "abayskaya" },
      { id: 2, name: t("REGIONS.AKMOLA"), value: "akmolinskaya" },
      { id: 3, name: t("REGIONS.AKTOBE"), value: "aktubiskaya" },
      { id: 4, name: t("REGIONS.ALMATY"), value: "almatynskaya" },
      { id: 5, name: t("REGIONS.ATYRAU"), value: "atyrauskaya" },
      { id: 6, name: t("REGIONS.EAST_KAZAKHSTAN"), value: "vostochnaya" },
      { id: 7, name: t("REGIONS.ZHAMBYL"), value: "zhambylskaya" },
      { id: 8, name: t("REGIONS.ZHETYSSU"), value: "zhetysyiskaya" },
      { id: 9, name: t("REGIONS.WEST_KAZAKHSTAN"), value: "zapadnaya" },
      { id: 10, name: t("REGIONS.KARAGANDA"), value: "karagandinskaya" },
      { id: 11, name: t("REGIONS.KYZYLORDA"), value: "kyzylordinskaya" },
      { id: 12, name: t("REGIONS.MANGYSTAU"), value: "mangystauskaya" },
      { id: 13, name: t("REGIONS.PAVLODAR"), value: "pavlodarskaya" },
      { id: 14, name: t("REGIONS.NORTH_KAZAKHSTAN"), value: "severnaya" },
      { id: 15, name: t("REGIONS.TURKESTAN"), value: "turkestanskaya" },
      { id: 16, name: t("REGIONS.ULYTAU"), value: "ylytayskaya" },
    ]);
  }, [t]);

  return (
    <Box>
      <h1 className={"signIn__subtitle"}>{t("NAME")}</h1>
      <FormControl fullWidth>
        <Controller
          name="name"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CssTextField
              placeholder={t("NAME_PLACEHOLDER")}
              type="text"
              required
              helperText={!!errors.name ? t(errors.name.message) : ""}
              error={Boolean(errors.name)}
              {...field}
            />
          )}
        />
      </FormControl>

      <h1 className={"signIn__subtitle"}>{t("COMPANY")}</h1>
      <FormControl fullWidth>
        <Controller
          name="nameCompany"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <CssTextField
              placeholder={t("COMPANY_PLACEHOLDER")}
              type="text"
              required
              helperText={!!errors.name ? errors.name.message : ""}
              error={Boolean(errors.name)}
              {...field}
            />
          )}
        />
      </FormControl>

      <h1 className={"signIn__subtitle"}>{t("LOCATION")}</h1>
      <FormControl fullWidth>
        <Controller
          control={control}
          defaultValue=""
          name="location"
          render={({ field }) => (
            <Select
              sx={{
                marginBottom: "20px",
                background: "rgba(145, 158, 171, 0.08)",
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: "200px",
                  },
                },
              }}
              labelId="location-label"
              name={field.name}
              value={field.value}
              onChange={field.onChange}
              error={Boolean(errors.location)}
              displayEmpty
              renderValue={
                field.value !== ""
                  ? undefined
                  : () => <Placeholder>{t("LOCATION_PLACEHOLDER")}</Placeholder>
              }
            >
              {locationData.map((item) => (
                <MenuItem key={item.id} value={item.value}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
      </FormControl>
    </Box>
  );
};

export default Step2;
