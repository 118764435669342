const locale = {
  MONTHS: {
    JANUARY: "қаңтар",
    FEBRUARY: "ақпан",
    MARCH: "наурыз",
    APRIL: "сәуір",
    MAY: "мамыр",
    JUNE: "маусым",
    JULY: "шілде",
    AUGUST: "тамыз",
    SEPTEMBER: "қыркүйек",
    OCTOBER: "қазан",
    NOVEMBER: "қараша",
    DECEMBER: "желтоқсан",
  },
  ACTUAL: "Нақты",
  PREDICTION: "Болжамды",
  ACTUAL_METEO: "Нақты",
  IRRADIANCE_UNIT: "кВт‧сағ/м²",
  WIND_SPEED_UNIT: "м/с",
  PRECIPITATION_UNIT: "мм",
  KW_H: " кВт‧сағ",
  MW_H: " мВт‧сағ",
  KW: " кВт",
  SHOW: "Көрсету",
  PLANNED_PRODUCTION: "Жоспарланған өнім",
  ACTUAL_PRODUCTION: "Болжамды өнім",
  TIME: "уақыт",
};

export default locale;
