export const handleRequestPending = (statusField) => (state) => {
  state[statusField] = "loading";
  state.error = null;
};

export const handleRequestFulfilled =
  (statusField, dataField = null) =>
  (state, action) => {
    state[statusField] = "succeeded";
  };

export const handleRequestRejected = (statusField) => (state, action) => {
  state[statusField] = "failed";
  state.error = action.error.message;
};

export const handleStatusChange = (statusField) => {
  return {
    pending: handleRequestPending(statusField),
    fulfilled: handleRequestFulfilled(statusField),
    rejected: handleRequestRejected(statusField),
  };
};
