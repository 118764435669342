const locale = {
  TITLE: "Forgot password?",
  DESCRIPTION: "Fill out the password reset form",
  EMAIL_PLACEHOLDER: "Enter email",
  SEND_LINK: "Send link",
  FOOTER: "Already have an account?",
  SIGN_IN: "Sign in",
};

export default locale;
