const locale = {
  TITLE: "Информация о станции",
  EDIT: "Редактировать",
  SOLAR_POWER_PLANT: "Солнечная электростанция",
  CAPACITY: "Установленная мощность",
  CAPACITY_UNIT: "МВт",
  TARIFF: "Тариф",

  EDIT_INFO: "Редактировать данные",
  DESCRIPTION:
    "Отправьте запрос на изменениие данных или свяжитесь с нами по номеру +7 707 000 00 00",
  SEND: "Отправить",

  SET_STATION: "Добавить новую станцию",
  LOCATION: "Местоположение станции",
  SELECT_LOCATION: "Выберите регион",

  REGIONS: {
    AKMOLA: "Акмолинская область",
    ABAY: "Абайская область",
    AKTOBE: "Актюбинская область",
    ALMATY: "Алматинская область",
  },
};
export default locale;
