// export const selectTypeStation = [
//   { typeEn: "SPP", typeRu: "СЭС", typeKz: "КЭС" },
//   { typeEn: "SP", typeRu: "СЭ", typeKz: "КЭ" },
// ];

export const selectTypeStation = [
  { typeEn: "SPP"},
  { typeEn: "SP"},
];

