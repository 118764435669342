const locale = {
  MONTHS: {
    JANUARY: "январь",
    FEBRUARY: "февраль",
    MARCH: "март",
    APRIL: "апрель",
    MAY: "май",
    JUNE: "июнь",
    JULY: "июль",
    AUGUST: "август",
    SEPTEMBER: "сентябрь",
    OCTOBER: "октябрь",
    NOVEMBER: "ноябрь",
    DECEMBER: "декабрь",
  },
  ACTUAL: "Фактическaя",
  ACTUAL_METEO: "Факт",
  PREDICTION: "Прогноз",

  IRRADIANCE_UNIT: "кВт‧ч/м²",
  WIND_SPEED_UNIT: "м/с",
  PRECIPITATION_UNIT: "мм",
  KW_H: " кВт‧ч",
  MW_H: " МВт‧ч",
  KW: " кВт",
  SHOW: "Показывать",
  PLANNED_PRODUCTION: "Плановое выработка",
  ACTUAL_PRODUCTION: "Фактическая выработка",
  TIME: "время",
};

export default locale;
