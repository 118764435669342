export const allFieldNames = [
  "plannedOutput",
  "actualOutput",
  "meteologicaOutput",

  "profit",
  "profitForecast",
  "profitForecastMeteologica",
  "profitFact",

  "KIOM",
  "KIOMForecast",
  "KIOMForecastMeteologica",
  "KIOMFact",

  "energyConsumption",
  "energyConsumptionForecast",
  "energyConsumptionForecastMeteologica",
  "energyConsumptionFact",

  "reductionOfCO2Emissions",
  "reductionOfCO2EmissionsForecast",
  "reductionOfCO2EmissionsForecastMeteologica",
  "reductionOfCO2EmissionsFact",

  "temperature",
  "tempForecast",
  "tempFact",

  "irradiation",
  "irradForecast",
  "irradFact",

  "cloudy",
  "cloudForecast",
  "cloudFact",

  "windSpeed",
  "wsForecast",
  "wsFact",

  "precipitation",
  "precForecast",
  "precFact",
];

export const months = [
  "MONTHS.JANUARY",
  "MONTHS.FEBRUARY",
  "MONTHS.MARCH",
  "MONTHS.APRIL",
  "MONTHS.MAY",
  "MONTHS.JUNE",
  "MONTHS.JULY",
  "MONTHS.AUGUST",
  "MONTHS.SEPTEMBER",
  "MONTHS.OCTOBER",
  "MONTHS.NOVEMBER",
];

export const units = {
  "REPORT.TIME": "",
  "REPORT.PLANNED_OUTPUT_INTECH": "REPORT.OUTPUT_UNIT",
  "REPORT.PLANNED_OUTPUT_METEOLOGICA": "REPORT.OUTPUT_UNIT",
  "REPORT.ACTUAL_OUTPUT": "REPORT.OUTPUT_UNIT",
  "REPORT.TEMPERATURE_PREDICTION": "°C",
  "REPORT.TEMPERATURE_FACT": "°C",
  "REPORT.IRRADIANCE_PREDICTION": "REPORT.IRRADIANCE_UNIT",
  "REPORT.IRRADIANCE_FACT": "REPORT.IRRADIANCE_UNIT",
  "REPORT.CLOUD_PREDICTION": "%",
  "REPORT.CLOUD_FACT": "%",
  "REPORT.WIND_SPEED_PREDICTION": "REPORT.WIND_SPEED_UNIT",
  "REPORT.WIND_SPEED_FACT": "REPORT.WIND_SPEED_UNIT",
  "REPORT.PRECIPITATION_PREDICTION": "REPORT.PRECIPITATION_UNIT",
  "REPORT.PRECIPITATION_FACT": "REPORT.PRECIPITATION_UNIT",
  "REPORT.PROFIT_FACT": "₸",
  "REPORT.PROFIT_PREDICTION_INTECH": "₸",
  "REPORT.PROFIT_PREDICTION_METEOLOGICA": "₸",
  "REPORT.CUF_FACT": "%",
  "REPORT.CUF_PREDICTION_INTECH": "%",
  "REPORT.CUF_PREDICTION_METEOLOGICA": "%",
  "REPORT.ENERGY_CONSUMPTION_FACT": "REPORT.ENERGY_CONSUMPTION_UNIT",
  "REPORT.ENERGY_CONSUMPTION_PREDICTION_INTECH":
    "REPORT.ENERGY_CONSUMPTION_UNIT",
  "REPORT.ENERGY_CONSUMPTION_PREDICTION_METEOLOGICA":
    "REPORT.ENERGY_CONSUMPTION_UNIT",
  "REPORT.EMISSIONS_REDUCTION_FACT": "REPORT.EMISSIONS_REDUCTION_UNIT",
  "REPORT.EMISSIONS_REDUCTION_PREDICTION_INTECH":
    "REPORT.EMISSIONS_REDUCTION_UNIT",
  "REPORT.EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA":
    "REPORT.EMISSIONS_REDUCTION_UNIT",
};
