import { memo, useContext } from "react";
import { useRoutes } from "react-router-dom";
import AppContext from "../../AppContext";
import { useSelector } from "react-redux";
import { selectFuseCurrentLayoutConfig } from "../../store/settingsSlice";
import { selectUser } from "../../store/user/userThunks";
import NavbarLayout from "./components/NavbarLayout";
import { ThemeProvider } from "@mui/material/styles";
import { customFontFamily } from "../../utils/customFontFamily";

function LayoutMain(props) {
	const user = useSelector(selectUser);

	const config = useSelector(selectFuseCurrentLayoutConfig);
	const appContext = useContext(AppContext);
	const { routes } = appContext;

	return (
		<ThemeProvider theme={customFontFamily}>
			<div className="wrapper">
				{config.navbar.isLoginForm || (
					config.navbar.show && (
						<NavbarLayout isUserAuthenticated={user.role.length > 0} />
					)
				)}

				{useRoutes(routes)}
			</div>
		</ThemeProvider>
	);
}

export default memo(LayoutMain);
