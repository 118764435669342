import { Box, IconButton } from "@mui/material";
import React, { useState } from "react";
import "./modal-set-station.css";
import {
  modalNewStationStyle,
  reactSelectStyle,
} from "../../../../utils/stylesForMui";
import { selectNewStation } from "../../../../utils/contstants/selectNewStation";
import Select from "react-select";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const ModalSetStation = React.forwardRef((props, ref) => {
  const { onClose } = props;
  const { t } = useTranslation("infoStationPage");
  const [station, setStation] = useState("");
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  // Translate the labels for the select options
  const translatedOptions = selectNewStation.map((option) => ({
    ...option,
    label: t(option.label), // Use the translation function to translate each label
  }));

  const handleSelectOpen = () => {
    setIsSelectOpen(true);
  };

  const handleSelectClose = () => {
    setIsSelectOpen(false);
  };

  return (
    <div ref={ref}>
      <Box sx={modalNewStationStyle}>
        <div className="ModalSetStation__closeButton">
          <IconButton onClick={onClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="ModalSetStation__header">
          <p className="ModalSetStation__title">{t("SET_STATION")}</p>
        </div>
        <p className="ModalSetStation__text">{t("DESCRIPTION")}</p>

        <p className="ModalSetStation__subtitle">{t("LOCATION")}</p>

        <form onSubmit={onClose} className={isSelectOpen ? "form-open" : ""}>
          <Select
            styles={reactSelectStyle}
            placeholder={t("SELECT_LOCATION")}
            options={translatedOptions}
            value={station}
            onChange={setStation}
            menuIsOpen={isSelectOpen}
            onMenuOpen={handleSelectOpen}
            onMenuClose={handleSelectClose}
          ></Select>

          <button className="btn-setStation">{t("SEND")}</button>
        </form>
      </Box>
    </div>
  );
});

export default ModalSetStation;
