import { Box, IconButton } from "@mui/material";
import React from "react";
import { modalNewStationStyle } from "../../../../utils/stylesForMui";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";

const ModalEditInfo = React.forwardRef((props, ref) => {
  const { onClose } = props;
  const { t } = useTranslation("infoStationPage");

  return (
    <Box style={modalNewStationStyle}>
      <div className="ModalSetStation__header">
        <p className="ModalSetStation__title"> {t("EDIT_INFO")}</p>
        <IconButton onClick={onClose} sx={{ p: 0 }}>
          <CloseIcon />
        </IconButton>
      </div>

      <p className="ModalSetStation__text">{t("DESCRIPTION")}</p>
      <button onClick={onClose} className="btn-setStation">
        {t("SEND")}
      </button>
    </Box>
  );
});

export default ModalEditInfo;
