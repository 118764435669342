import ExcelJS from "exceljs";
import { units, months } from "../../../utils/contstants/formExport";
import { format } from "date-fns";

export const downloadYearStats = async (date, data, stationTitle, t) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  worksheet.views = [
    {
      state: "frozen",
      ySplit: 4,
    },
  ];

  worksheet.mergeCells("A1", "B2");
  const dateCell = worksheet.getCell("A1");

  // Assuming date is already declared and is a valid Date object

  dateCell.value = format(date, "yyyy");
  dateCell.font = { bold: true, size: 14, name: "Arial" };
  dateCell.alignment = { vertical: "middle" };

  worksheet.mergeCells("C1", "M2");
  const stationNameCell = worksheet.getCell("C1");
  stationNameCell.value = stationTitle;
  stationNameCell.font = { bold: true, size: 14, name: "Arial" };
  stationNameCell.alignment = { vertical: "middle" };

  // Get object keys for header
  const headers = Object.keys(data[0]);

  headers.forEach((header, columnIndex) => {
    const cell = worksheet.getCell(3, columnIndex + 1);
    cell.value = t(header);
    cell.font = { size: 10, name: "Arial" };
    cell.alignment = {
      wrapText: true,
      vertical: "top", // Align text to the top
      horizontal: "left", // Align text to the left
    };
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "F3F3F3" }, // Specify the color in ARGB format
    };

    const unitCell = worksheet.getRow(4).getCell(columnIndex + 1);
    unitCell.value = t(units[header]);
    unitCell.font = { size: 10, name: "Arial", color: { argb: "A6A6A6" } };
    cell.alignment = {
      wrapText: true,
      vertical: "top", // Align text to the top
      horizontal: "left", // Align text to the left
    };
    unitCell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "F3F3F3" }, // Specify the color in ARGB format
    };
  });

  // Add the data to the sheet
  data.forEach((row, rowIndex) => {
    headers.forEach((header, columnIndex) => {
      const cell = worksheet.getCell(rowIndex + 5, columnIndex + 1); // Starting from row 5
      if (header === "REPORT.TIME") {
        cell.value = t(row[header]);

        // Replace 'yourColumnHeader' with the actual header
        cell.alignment = {
          vertical: "bottom", // Align text to the top
          horizontal: "right", // Align text to the left
        };
      } else {
        cell.value =
          row[header] && row[header] !== "NaN" ? Number(row[header]) : 0;
        cell.alignment = {
          vertical: "bottom", // Align text to the top
          horizontal: "left", // Align text to the left
        };
      }
      cell.font = { size: 10, name: "Arial" };

      if (columnIndex === headers.length - 1) {
        cell.border = {
          ...cell.border,
          right: { style: "thin", color: { argb: "000000" } },
        };
      }

      if (rowIndex === data.length - 1) {
        cell.border = {
          ...cell.border,
          bottom: { style: "thin", color: { argb: "000000" } },
        };
      }
    });
  });
  // ... (previous code)

  // Identify columns to sum
  const columnsToSum = [
    "REPORT.PLANNED_OUTPUT_INTECH",
    "REPORT.PLANNED_OUTPUT_METEOLOGICA",
    "REPORT.ACTUAL_OUTPUT",
    "REPORT.PROFIT_FACT",
    "REPORT.PROFIT_PREDICTION_INTECH",
    "REPORT.PROFIT_PREDICTION_METEOLOGICA",
    "PROFIT.ENERGY_CONSUMPTION_PREDICTION_INTECH",
    "PROFIT.ENERGY_CONSUMPTION_PREDICTION_METEOLOGICA",
    "PROFIT.ENERGY_CONSUMPTION_FACT",
    "PROFIT.EMISSIONS_REDUCTION_FACT",
    "PROFIT.EMISSIONS_REDUCTION_PREDICTION_INTECH",
    "PROFIT.EMISSIONS_REDUCTION_PREDICTION_METEOLOGICA",
  ];

  const filteredColumnsToSum = columnsToSum.filter((column) =>
    headers.includes(column),
  );

  if (filteredColumnsToSum.length > 0) {
    const totalRowIndex = worksheet.rowCount + 1; // This will be the index of your total row
    const totalRow = worksheet.addRow();

    filteredColumnsToSum.forEach((header) => {
      const columnIndex = headers.indexOf(header) + 1;
      const startCell = worksheet.getCell(5, columnIndex);

      const endCell = worksheet.getCell(totalRowIndex - 1, columnIndex);
      const totalCell = totalRow.getCell(columnIndex);

      // Set the SUM formula for the cell
      totalCell.value = {
        formula: `SUM(${startCell.address}:${endCell.address})`,
      };

      totalCell.font = { bold: true, size: 10, name: "Arial" };
      totalCell.alignment = {
        vertical: "bottom",
        horizontal: "right",
      };
      totalCell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F3F3F3" },
      };
    });

    for (let col = 1; col <= headers.length; col++) {
      if (!totalRow.getCell(col).value) totalRow.getCell(col).value = ""; // This will create cells if they don't exist
    }

    // Optional: Set 'Total' label for the first cell of the total row
    totalRow.getCell(1).value = t("REPORT.TOTAL");
    totalRow.getCell(1).font = { bold: true, size: 10, name: "Arial" };
    totalRow.getCell(1).alignment = {
      vertical: "bottom",
      horizontal: "left",
    };
    totalRow.eachCell((cell) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "F3F3F3" }, // Set the desired color
      };
      cell.border = {
        ...cell.border,
        top: { style: "thin", color: { argb: "000000" } },
        bottom: { style: "thin", color: { argb: "000000" } },
      };
      if (cell.col === headers.length) {
        cell.border = {
          ...cell.border,
          right: { style: "thin", color: { argb: "000000" } },
        };
      }
    });
  }
  for (let rowIndex = 1; rowIndex <= worksheet.rowCount; rowIndex++) {
    const row = worksheet.getRow(rowIndex);
    row.height = 20; // Set the desired row height in points
  }
  worksheet.getRow(3).height = 40;
  for (
    let columnIndex = 1;
    columnIndex <= worksheet.columnCount;
    columnIndex++
  ) {
    const column = worksheet.getColumn(columnIndex);
    column.width = 10; // Set the desired column width in pixels
  }

  // Generate and download the Excel file
  const blob = await workbook.xlsx.writeBuffer();
  const url = window.URL.createObjectURL(new Blob([blob]));
  const a = document.createElement("a");
  a.href = url;
  a.download = "exported_data.xlsx";
  a.click();
  window.URL.revokeObjectURL(url);
};
