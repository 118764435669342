import React from "react";
import { Box, FormControl} from "@mui/material";
import { Controller } from "react-hook-form";
import { CssTextField } from "../../../../utils/customCssTextField";
import ReactInputMask from "react-input-mask";
import { useTranslation } from "react-i18next";

const Step1 = ({ control, errors }) => {
  const { t } = useTranslation("signUpPage", { keyPrefix: "STEP_1" });
  return (
    <Box>
      <h1 className={"signIn__subtitle"}>{t("PHONE_NO")}</h1>
      <FormControl fullWidth>
        <Controller
          name="phone"
          control={control}
          rules={{ required: t("PHONE_NO_REQUIRED") }}
          render={({ field: { onChange, value } }) => (
            <ReactInputMask
              mask="+7 (999) 999 99 99"
              value={value || ""}
              onChange={onChange}
            >
              {(inputProps) => (
                <CssTextField
                  {...inputProps}
                  autoFocus
                  type="tel"
                  error={Boolean(errors.phone)}
                  helperText={!!errors.phone ? t(errors.phone.message) : ""}
                  variant="outlined"
                  required
                  fullWidth
                  placeholder="+7 (___) ___ __ __"
                />
              )}
            </ReactInputMask>
          )}
        />
      </FormControl>

      <h1 className={"signIn__subtitle"}>Email</h1>
      <Controller
        control={control}
        name="email"
        render={({ field, fieldState }) => (
          <CssTextField
            {...field}
            value={field.value || ""}
            error={!!fieldState.error}
            helperText={!!fieldState.error ? t(fieldState.error.message) : ""}
            fullWidth
            placeholder={t("EMAIL_PLACEHOLDER")}
          />
        )}
      />
    </Box>
  );
};

export default Step1;
