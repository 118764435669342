import axios from "axios";

export const fetchStatsService = async (endpoint, params) => {
  try {
    const resp = await axios.get(endpoint, { params });

    return { data: resp.data, error: "" };
  } catch (err) {
    return { data: [], error: err.message };
  }
};
