const locale = {
  HEADER: {
    TIME: "Время",
    PREDICTION: "Прогноз",
    ACTUAL: "Факт",
    ACCURACY: "Оправдываемость",
  },
  TOTAL: "Всего",
};

export default locale;
