export const getMaxOrMinOutput = (data, findNum) => {
  if (!data || data.length === 0) {
    return undefined;
  }

  let reducedData = data.slice(0, -1); // Исключаем последний объект из массива

  if (findNum) {
    reducedData = reducedData.filter(
      (obj) => parseInt(obj.actual) < parseInt(data[data.length - 1].actual),
    );
  } else {
    reducedData = reducedData.filter(
      (obj) => parseInt(obj.actual) > parseInt(data[data.length - 1].actual),
    );
  }

  if (reducedData.length === 0) {
    return reducedData[reducedData.length - 1]; // Возвращаем последний объект, если все остальные исключены
  }

  return reducedData.reduce((acc, obj) => {
    const numObjActual = parseInt(obj.actual);
    const numAccActual = parseInt(acc.actual);

    if (
      (findNum && numObjActual > numAccActual) ||
      (!findNum && numObjActual < numAccActual)
    ) {
      return obj;
    }

    return acc;
  });
};

export const findObjectWithMaxAction = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // Return null for empty or invalid input
  }

  const dataset = data.find((item) => item.name === "Actual");

  let maxActual = -Infinity;
  let maxActualObject = null;

  for (const item of dataset?.series) {
    const actual = parseFloat(item.value);

    if (!isNaN(actual) && actual > maxActual) {
      maxActual = actual;
      maxActualObject = {
        dataset: dataset.name,
        labels: item.name,
        actual: actual,
      };
    }
  }

  console.log(maxActualObject);

  return maxActualObject;
};

export const findObjectWithMinActual = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return null; // Return null for empty or invalid input
  }

  const dataset = data.find((item) => item.name === "Actual");

  let minActual = Infinity;
  let minActualObject = null;

  for (const item of dataset?.series) {
    const actual = parseFloat(item.value);

    if (!isNaN(actual) && actual !== 0 && actual < minActual ) {
      minActual = actual;
      minActualObject = {
        dataset: dataset.name,
        labels: item.name,
        actual: actual,
      };
    }
  }

  return minActualObject;
};

export const filterObjectsWithNonNullValues = (arr) => {
  if (!Array.isArray(arr)) {
    return [];
  }
  return arr.filter((item) => {
    if (typeof item === "object" && item !== null) {
      const filteredProps = filterObjectsWithNonNullValues(Object.values(item));
      return filteredProps.length === Object.keys(item).length;
    } else {
      return item !== null;
    }
  });
};

export const getArrNotNull = (arr, activeTab, value) => {
  return arr?.map((el) => {
    const updatedObj = { ...el[activeTab] };
    if (updatedObj[value] === null) {
      updatedObj[value] = 0;
    }
    return updatedObj;
  });
};

export const total = (arr, name) => {
  if (!Array.isArray(arr)) {
    return 0;
  }

  const dataset = arr.find((item) => item.name === name);

  if (!dataset || !dataset.series) {
    return 0;
  }

  return dataset.series.reduce((accumulator, obj) => {
    const numericValue = parseInt(obj.value, 10);

    if (!isNaN(numericValue)) {
      return accumulator + numericValue;
    }

    return accumulator;
  }, 0);
};

export const calculateAverageDeviation = (data) => {
  const actualOutput = data?.find((item) => item.name === "Actual").series;
  const predictedOutput = data?.find((item) => item.name === "Intech").series;

  let totalDeviation = 0;
  let hoursWithOutput = 0;

  for (let i = 0; i < actualOutput.length; i++) {
    if (
      Number(actualOutput[i].value) !== 0 &&
      Number(predictedOutput[i].value) !== 0
    ) {
      const deviation = Math.abs(
        Number(actualOutput[i].value) - Number(predictedOutput[i].value)
      ) / actualOutput[i].value;
      hoursWithOutput += 1;
      totalDeviation += deviation;
    }
  }

  return (1 - totalDeviation / hoursWithOutput) * 100;
};

// calculateAverageDeviation in percentage

export const calculateAverageDeviationInPercentage = (data) => {
  console.log(data);
  const actualOutput = data?.find((item) => item.name === "Actual").series;
  const predictedOutput = data?.find((item) => item.name === "Intech").series;

  let totalDeviationPercentage = 0;
  let hoursWithOutput = 0;

  for (let i = 0; i < actualOutput.length; i++) {
    const absoluteDeviation = Math.abs(
      Number(actualOutput[i].value) - Number(predictedOutput[i].value),
    );

    if (
      Number(actualOutput[i].value) !== 0 &&
      Number(predictedOutput[i].value) !== 0
    ) {
      const deviationPercentage =
        (absoluteDeviation / Number(actualOutput[i].value)) * 100;
      totalDeviationPercentage += deviationPercentage;
      hoursWithOutput += 1;
    }
  }

  return totalDeviationPercentage / hoursWithOutput;
};


export const getVisibleDays = () => {
  let today = new Date();

  let dayNumber = today.getDay();

  let visibleDays;

  switch (dayNumber){
    case 5:
      visibleDays = 4 * 86400000;
      break;
    case 6: 
      visibleDays = 3 * 86400000;
      break;
    default:
      visibleDays = 2 * 86400000
  }

  return visibleDays;
}
