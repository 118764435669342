import axios from "axios"

export const fetchComponentsService = async (endpoint) => {
    try {
        const resp = await axios.get(endpoint);

        return { data: resp.data, error: "" };
    } catch (err) {
        return { data: [], error: err.message };
    }
}