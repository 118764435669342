import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  resetLoadState,
  updateLastRequestState,
  updateSelectedPowerPlant,
} from "./diagramSlice";
import { fetchStatsService } from "../../services/diagramService";

const apiEndpoints = {
  stats: {
    today: "/api/v2/power-plant/day-stats",
    tomorrow: "/api/v2/power-plant/day-stats",
    otherDate: "/api/v2/power-plant/day-stats",
  },
  summary: {
    monthly: "/api/v2/power-plant/month-stats",
    yearly: "/api/v2/power-plant/year-stats",
  },
  meteo: {
    today: "/api/v2/meteo/day-stats",
    tomorrow: "/api/v2/meteo/day-stats",
    otherDate: "/api/v2/meteo/day-stats",
  },
  performance: {
    monthly: "/api/v2/power-plant/month-stats",
    yearly: "/api/v2/power-plant/year-stats",
  },
};

const fetchStats = async (endpoint, params) => {
  return await fetchStatsService(endpoint, params);
};

export const changePowerPlant = createAsyncThunk(
  "dashboard/changePowerPlant",
  async (powerPlantId, { dispatch, getState }) => {
    dispatch(updateSelectedPowerPlant(powerPlantId));
 
    // reset all stats
    const state = getState().dashboard;

    console.log(state);
    ["stats", "summary", "meteo", "performance"].forEach((statBlock) => {
      if (statBlock !== "selectedPowerPlant") {
        Object.keys(state[statBlock]).forEach((statType) => {
          dispatch(resetLoadState({ statBlock, statType }));
        });
      }
    });

    // Repeat last request
    const { statBlock, statType, date } = state.lastRequest;

    if (statBlock && statType && date) {
      dispatch(loadStats({ statBlock, statType, date }));
    }
  },
);

export const loadStats = createAsyncThunk(
  "dashboard/loadStats",
  async ({ statBlock, statType, date }, { dispatch, getState }) => {
    dispatch(updateLastRequestState({ statBlock, statType, date }));

    const stats = getState().dashboard[statBlock];
    if (stats[statType]?.date === date) {
      return { statBlock, statType, statData: stats[statType] };
    }

    dispatch(resetLoadState({ statBlock, statType }));
    const fetchedData = await fetchStats(apiEndpoints[statBlock][statType], {
      power_plant_id: getState().dashboard["selectedPowerPlant"],
      timestamp: date,
    });

    console.log("loadStats", statBlock, statType, date);
    console.log("loadStats", fetchedData);

    return {
      statBlock,
      statType,
      statData: { ...fetchedData, date, loaded: true },
    };
  },
);
