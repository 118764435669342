import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import * as yup from "yup";
import _ from "../../../../@lodash";

import { Link } from "react-router-dom";
import "./forgotPassword.css";
import React from "react";
import { CssTextField } from "../../../utils/customCssTextField";
import { CustomButton } from "../../../utils/customButton";
import "../sign-in/sign-in.css";
import LanguageSwitcher from "../../../theme-layouts/layout-main/components/LanguageSwitcher";
/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
  email: yup
    .string()
    .email("Неверный формат email. Пожалуйста, попробуйте еще раз.")
    .required("Обязательное поле"),
});

const defaultValues = {
  email: "",
};

function ForgotPasswordPage() {
  const { control, formState, handleSubmit, reset } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;
  const { t } = useTranslation("forgotPasswordPage");

  function onSubmit() {
    reset(defaultValues);
  }

  return (
    <div className="forgot">
      <div className="forgot__wrap">
        <div className="auth_header">
          <img src="/images/logo.svg" alt="logo" />
          <div style={{ display: "contents" }}>
            <LanguageSwitcher />
          </div>
        </div>
        <div className="forgot__content">
          <h1 className="forgot__title">{t("TITLE")}</h1>
          <p className="forgot__description" style={{ marginBottom: "30px" }}>
            {t("DESCRIPTION")}
          </p>

          <form
            name="forgotForm"
            noValidate
            className="flex flex-col justify-center w-full mt-32"
            onSubmit={handleSubmit(onSubmit)}
          >
            <p className="forgot__subtitle">Email</p>
            <Controller
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <CssTextField
                  {...field}
                  error={!!fieldState.error}
                  helperText={fieldState.error?.message}
                  fullWidth
                  placeholder={t("EMAIL_PLACEHOLDER")}
                />
              )}
            />

            <div className="forgot__btnWrap">
              <CustomButton
                variant="contained"
                aria-label="Forgot password"
                disabled={_.isEmpty(dirtyFields) || !isValid}
                type="submit"
                size="large"
                sx={{
                  fontSize: "15px",
                  lineHeight: "26px",
                  color: "#FFFFFF",
                  background: "#272933",
                  width: "100%",
                  textTransform: "capitalize",
                  padding: "11px 0",
                  "&:hover": {
                    backgroundColor: "#272933",
                  },
                }}
              >
                {t("SEND_LINK")}
              </CustomButton>
            </div>
          </form>
          <div className="line"></div>
          <div className="forgot__footer">
            <p className="forgot__subtitle-footer">{t("FOOTER")}</p>
            <Link className="link_register" to="/">
              {t("SIGN_IN")}
            </Link>
          </div>
        </div>
      </div>
      <img className="bg-img" src="/images/sign-in-bg.png" alt="BG" />
    </div>
  );
}

export default ForgotPasswordPage;
