import React, { useState, useEffect } from 'react';
import './VideoBackground.css';
import { useTranslation } from 'react-i18next';


const VideoBackground = ({t}) => {
	const videoSources = {
		medium: `${process.env.PUBLIC_URL}/video/wind-farm-medium.mp4`,
		large: `${process.env.PUBLIC_URL}/video/wind-farm-large.mp4`
	};

	const [videoUrl, setVideoUrl] = useState(videoSources.large); // default to large
	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		const updateVideoSource = () => {
			if (window.innerWidth < 1025) {
				setVideoUrl(videoSources.medium);
			} else {
				setVideoUrl(videoSources.large);
			}
		};
	
		window.addEventListener('resize', updateVideoSource);
		updateVideoSource(); // Call it initially to set the right video size

		return () => window.removeEventListener('resize', updateVideoSource);
	}, []);

	useEffect(() => {
		const videoElement = document.createElement('video');
		videoElement.src = videoUrl;
		videoElement.onloadeddata = () => {
			setLoaded(true);
		};
		videoElement.load();
	}, [videoUrl]);

	return (
		<div className="video-background-container">
			{loaded && (
				<video autoPlay muted loop className="video-background">
					<source src={videoUrl} type="video/mp4" />
					Your browser does not support the video tag.
				</video>
			)}
			<div className="centered-text">
				<h2>InTech-Forecast</h2>
				<p>{t("PARAGRAPH_1")}</p>
				<br/>
				<p>{t("PARAGRAPH_2")}</p>
			</div>
		</div>
	);
};

export default VideoBackground;
