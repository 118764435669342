import { createSlice } from "@reduxjs/toolkit";
import {
  deleteTeamMember,
  updateTeamMember,
  addTeamMember,
  getTeamMember,
} from "./employeeThunks";
import { handleStatusChange } from "../reduxHelpers";

const initialState = {
  team_members: [],
  getTeamMemberStatus: "idle",
  addTeamMemberStatus: "idle",
  deleteTeamMemberStatus: "idle",
  updateTeamMemberStatus: "idle",
};

const getTeamMemberStatusHandlers = handleStatusChange("getTeamMemberStatus");
const addTeamMemberStatusHandlers = handleStatusChange("addTeamMemberStatus");
const deleteTeamMemberStatusHandlers = handleStatusChange(
  "deleteTeamMemberStatus"
);
const updateTeamMemberStatusHandlers = handleStatusChange(
  "updateTeamMemberStatus"
);

const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    resetTeamMemberStatus: (state) => {
      state.addTeamMemberStatus = "idle";
      state.deleteTeamMemberStatus = "idle";
      state.updateTeamMemberStatus = "idle";
      state.error = null;
    },
  },
  extraReducers: {
    [getTeamMember.fulfilled]: (state, action) => {
      state.team_members = action.payload;
      getTeamMemberStatusHandlers.fulfilled(state, action);
    },
    [getTeamMember.pending]: getTeamMemberStatusHandlers.pending,
    [getTeamMember.rejected]: getTeamMemberStatusHandlers.rejected,
    [deleteTeamMember.fulfilled]: (state, action) => {
      state.team_members = state.team_members.filter(
        (teamMember) => teamMember.id !== action.payload
      );
      deleteTeamMemberStatusHandlers.fulfilled(state, action);
    },
    [deleteTeamMember.pending]: deleteTeamMemberStatusHandlers.pending,
    [deleteTeamMember.rejected]: deleteTeamMemberStatusHandlers.rejected,
    [addTeamMember.fulfilled]: (state, action) => {
      const teamMembers = [...state.team_members, action.payload];
      state.team_members = teamMembers;
      addTeamMemberStatusHandlers.fulfilled(state, action);
    },
    [addTeamMember.pending]: addTeamMemberStatusHandlers.pending,
    [addTeamMember.rejected]: addTeamMemberStatusHandlers.rejected,
    [updateTeamMember.fulfilled]: (state, action) => {
      const index = state.team_members.findIndex(
        (teamMember) => teamMember.id === action.payload.id
      );
      console.log(state.team_members);
      if (index !== -1) {
        state.team_members[index] = action.payload;
      }
      updateTeamMemberStatusHandlers.fulfilled(state, action);
    },
    [updateTeamMember.pending]: updateTeamMemberStatusHandlers.pending,
    [updateTeamMember.rejected]: updateTeamMemberStatusHandlers.rejected,
  },
});

export const { resetTeamMemberStatus } = employeeSlice.actions;

export default employeeSlice.reducer;
