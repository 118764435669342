import { Typography } from "@mui/material";
import { useEffect } from "react";
import JwtService from "../../../auth/services/jwtService";
import "./sign-out-page.css";
import { useTranslation } from "react-i18next";

function SignOutPage() {
  const { t } = useTranslation("signOutPage");
  useEffect(() => {
    setTimeout(() => {
      JwtService.logout();
    }, 1000);
  }, []);

  return (
    <div className={`signOutPage`}>
      <Typography
        component={"h2"}
        sx={{
          color: "#007B55",
          fontWeight: 600,
          fontSize: "40px",
          lineHeight: "56px",
          marginTop: "100px",
        }}
      >
        {t("TITLE")}
      </Typography>
    </div>
  );
}

export default SignOutPage;
