const locale = {
  TITLE: "Вход",
  EMAIL_PLACEHOLDER: "Введите email",
  PASSWORD: "Пароль",
  PASSWORD_PLACEHOLDER: "Введите пароль",
  REMEMBER_ME: "Запомнить меня",
  FORGOT_PASSWORD: "Восстановить пароль",
  SIGN_UP_TEXT:
    "Нет аккаунта? Свяжитесь с нами, чтобы получить доступ или попробовать демо-версию",
  SIGN_UP: "Оставить заявку",
  SIGN_IN: "Войти",
  VALIDATION: {
    EMAIL_FORMAT: "Неверный email. Пожалуйста, попробуйте еще раз.",
    PASSWORD_FORMAT: "Пароль должен содержать не менее 4 символов",
    REQUIRED: "Обязательное поле",
  },
  PARAGRAPH_1:"IT сервис для прогнозирования выработки электроэнергии объектами ВИЭ",
  PARAGRAPH_2:"Повышение эффективности функционирования электроэнергетических систем стран Центральной Азии за счет формирования точных суточных прогнозов производства электроэнергии от ВИЭ"
};
export default locale;
