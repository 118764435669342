const locale = {
  TITLE: "Станция бойынша ақпарат",
  EDIT: "Өңдеу",
  SOLAR_POWER_PLANT: "Күн электр станциясы",
  CAPACITY: "Орнатылған қуаты",
  CAPACITY_UNIT: "МВт",
  TARIFF: "Тариф",

  EDIT_INFO: "Деректерді өңдеу",
  DESCRIPTION:
    "Деректерді өзгерту үшін сұраныс жіберіңіз немесе бізбен  +7 707 000 00 00 нөмірі бойынша байланысыңыз",
  SEND: "Жіберу",

  SET_STATION: "Жаңа станцияны қосу",
  LOCATION: "Станцияның орналасқан жері",
  SELECT_LOCATION: "Обылысты таңдаңыз",

  REGIONS: {
    AKMOLA: "Ақмола облысы",
    ABAY: "Абай облысы",
    AKTOBE: "Ақтөбе облысы",
    ALMATY: "Алматы облысы",
  },
};
export default locale;
