const locale = {
  TITLE: "Profile settings",
  EMPLOYEES: "Employees",
  ACCOUNT_INFO: "Account information",
  ERROR: "Error",
  FULL_NAME: "Full name",
  POSITION: "Position",
  PHONE_NO: "Phone number",
  NEW_EMPLOYEE: "New employee",
  EDIT_EMPLOYEE: "Edit employee data",
  ADD_NEW_EMPLOYEE: "Add new employee",
  DELETE_EMPLOYEE: "Delete employee ?",
  DELETE_EMPLOYEE_DESCRIPTION: "Employee {{full_name}} will be deleted",
  CANCEL_BTN: "Cancel",
  DELETE_BTN: "Delete",
  ACCOUNT_INFO_TAB: {
    EMAIL: "Email",
    PASSWORD: "Password",
    CHANGE: "Change",
    CHANGE_EMAIL: "Change email",
    CHANGE_PASSWORD: "Change password",
  },
  FORM_MODAL: {
    ENTER_FULL_NAME: "Enter employee's full name",
    SELECT_POSITION: "Select employee's position",
    DIRECTOR: "Director",
    ENGINEER: "Engineer",
    OPERATOR: "Operator",
    DISPATCHER: "Dispatcher",
    SAVE: "Save",
    SEND: "Send",
  },
  FORM_CHANGE: {
    SAVE: "Save",
    EMAIL: {
      NEW: "New email",
      NEW_PLACEHOLDER: "Enter new email",
      CONFIRM: "Confirm email",
      CONFIRM_PLACEHOLDER: "Enter new email again",
      CURRENT_PASSWORD: "Password from account",
      CURRENT_PASSWORD_PLACEHOLDER: "Enter password from account",
    },
    PASSWORD: {
      CURRENT: "Current password",
      CURRENT_PLACEHOLDER: "Enter password",
      NEW: "New password",
      NEW_PLACEHOLDER: "At least 8 characters",
      REPEAT: "Repeat password",
    },
    VALIDATION: {
      EMAIL: "Email address must contain the symbol «@».",
      REQUIRED: "Required field",
      EMAIL_NO_MATCH: "Email addresses do not match.",
      WRONG_PASSWORD: "Wrong current password",
      PASSWORD_LENGTH: "Password must contain at least 8 characters",
      PASSWORD_NO_MATCH: "Passwords must match",
    },
  },
  UPDATE: {
    LOADING: "Updating team member info, please wait...",
    SUCCESS: "Team member info updated successfully",
    ERROR: "Error updating team member info",
  },
  ADD: {
    LOADING: "Adding team member info, please wait...",
    SUCCESS: "Team member info added successfully",
    ERROR: "Error adding team member info",
  },
  DELETE: {
    LOADING: "Deleting team member info, please wait...",
    SUCCESS: "Team member info deleted successfully",
    ERROR: "Error deleting team member info",
  },
  EMAIL_CHANGE: {
    LOADING: "Changing email, please wait...",
    SUCCESS: "Email changed successfully",
    ERROR: "Error changing email",
  },
  PASSWORD_CHANGE: {
    LOADING: "Changing password, please wait...",
    SUCCESS: "Password changed successfully",
    ERROR: "Error changing password",
  },
};
export default locale;
