const locale = {
  TITLE: "Тіркелу",
  SUBTITLE:
    "Байланыст ақпараттарды қалдырыңыз. Біздің менеджер жақын арада \n" +
    "сізге хабарласады.",
  BUTTONS: {
    SEND: "Жіберу",
    NEXT: "Келесі",
    GO_BACK: "Артқа",
  },
  FOOTER: "Аккаунтыңыз бар ма?",
  SIGN_IN: "Кіру",
  POLITICAL: {
    TITLE: "«Жіберу» түймесін басу арқылы сіз шарттарды қабылдайсыз",
    CONFIDENTIALITY: "Құпиялылық саясаты",
    AND: "және",
    TERMS: "Пайдаланушы келісімі",
  },

  STEP_1: {
    PHONE_NO: "Телефон нөмірі",
    PHONE_NO_REQUIRED: "Телефон нөмірі міндетті",
    EMAIL_PLACEHOLDER: "Email-ді енгізіңіз",
    VALIDATION: {
      EMAIL_FORMAT: "Email-дің пішімі дұрыс емес.  Қайталап көріңіз.",
      REQUIRED: "Міндетті өріс",
    },
  },
  STEP_2: {
    REGIONS: {
      ABAY: "Абай облысы",
      AKMOLA: "Ақмола облысы",
      AKTOBE: "Ақтөбе облысы",
      ALMATY: "Алматы облысы",
      ATYRAU: "Атырау облысы",
      EAST_KAZAKHSTAN: "Шығыс Қазақстан облысы",
      ZHAMBYL: "Жамбыл облысы",
      ZHETYSSU: "Жетісу облысы",
      WEST_KAZAKHSTAN: "Батыс Қазақстан облысы",
      KARAGANDA: "Қарағанды облысы",
      KYZYLORDA: "Қызылорда облысы",
      MANGYSTAU: "Маңғыстау облысы",
      PAVLODAR: "Павлодар облысы",
      NORTH_KAZAKHSTAN: "Солтүстік Қазақстан облысы",
      TURKESTAN: "Түркістан облысы",
      ULYTAU: "Ұлытау облысы",
    },
    NAME: "Аты-жөніңіз",
    NAME_PLACEHOLDER: "Аты-жөніңізді енгізіңіз",
    COMPANY: "Компания",
    COMPANY_PLACEHOLDER: "Компанияның атауы",
    LOCATION: "Станцияның орналасқан жері",
    LOCATION_PLACEHOLDER: "Облысты таңдаңыз",
    VALIDATION: {
      REQUIRED: "Міндетті өріс",
    },
  },
};
export default locale;
