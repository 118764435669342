import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { CssTextField } from "../../../utils/customCssTextField";
import { IconButton, InputAdornment } from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./form-change.css";
import { useTranslation } from "react-i18next";

const FormChange = (props) => {
  const { password, onClose, change } = props;
  const { t } = useTranslation("infoEmployeesPage", {
    keyPrefix: "FORM_CHANGE",
  });

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickShowCurrentPassword = () =>
    setShowCurrentPassword((show) => !show);
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const schemaEmail = yup.object().shape({
    newEmail: yup
      .string()
      .email("VALIDATION.EMAIL")
      .required("VALIDATION.REQUIRED"),
    confirmEmail: yup
      .string()
      .email("VALIDATION.EMAIL")
      .oneOf([yup.ref("newEmail"), null], "VALIDATION.EMAIL_NO_MATCH")
      .required("VALIDATION.REQUIRED"),
    currentPassword: yup
      .string()
      .required("VALIDATION.REQUIRED")
      .test("match", "VALIDATION.WRONG_PASSWORD", function (value) {
        return value === password;
      }),
  });

  const schemaPassword = yup.object().shape({
    currentPassword: yup
      .string()
      .required("VALIDATION.REQUIRED")
      .test("match", "VALIDATION.WRONG_PASSWORD", function (value) {
        return value === password;
      }),
    newPassword: yup
      .string()
      .min(8, "VALIDATION.PASSWORD_LENGTH")
      .required("VALIDATION.REQUIRED"),
    confirmPassword: yup
      .string()
      .oneOf([yup.ref("newPassword"), null], "VALIDATION.PASSWORD_NO_MATCH")
      .required("VALIDATION.REQUIRED"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm({
    resolver: yupResolver(change === "email" ? schemaEmail : schemaPassword),
  });

  const watchFields = watch(
    change === "email"
      ? ["newEmail", "currentPassword", "confirmEmail"]
      : ["currentPassword", "newPassword", "confirmPassword"],
  );
  const isFormValid = watchFields.every((field) => field);

  const onSubmit = (data) => {
    onClose();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {change === "email" && (
        <>
          <p className="form-subtitle">{t("EMAIL.NEW")}</p>
          <Controller
            control={control}
            name="newEmail"
            render={({ field }) => (
              <CssTextField
                {...field}
                sx={{
                  marginBottom: "0",
                }}
                placeholder={t("EMAIL.NEW_PLACEHOLDER")}
                value={field.value || ""}
                error={!!errors.newEmail}
                helperText={
                  !!errors.newEmail ? t(errors.newEmail?.message) : ""
                }
                fullWidth
              />
            )}
          />

          <p className="form-subtitle">{t("EMAIL.CONFIRM")}</p>
          <Controller
            control={control}
            name="confirmEmail"
            render={({ field }) => (
              <CssTextField
                {...field}
                sx={{
                  marginBottom: "0",
                }}
                placeholder={t("EMAIL.CONFIRM_PLACEHOLDER")}
                value={field.value || ""}
                error={!!errors.confirmEmail}
                helperText={
                  !!errors.confirmEmail ? t(errors.confirmEmail?.message) : ""
                }
                fullWidth
              />
            )}
          />

          <p className="form-subtitle">{t("EMAIL.CURRENT_PASSWORD")}</p>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <CssTextField
                {...field}
                sx={{
                  marginBottom: "0",
                }}
                id="outlined-adornment-currentPassword"
                placeholder={t("EMAIL.CURRENT_PASSWORD_PLACEHOLDER")}
                value={field.value || ""}
                type={showCurrentPassword ? "text" : "password"}
                error={!!errors.currentPassword}
                helperText={
                  !!errors.currentPassword
                    ? t(errors.currentPassword?.message)
                    : ""
                }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle currentPassword visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </>
      )}

      {change === "password" && (
        <>
          <p className="form-subtitle">{t("PASSWORD.CURRENT")}</p>
          <Controller
            name="currentPassword"
            control={control}
            render={({ field }) => (
              <CssTextField
                {...field}
                sx={{
                  marginBottom: "0",
                }}
                id="outlined-adornment-currentPassword"
                placeholder={t("PASSWORD.CURRENT_PLACEHOLDER")}
                value={field.value || ""}
                type={showCurrentPassword ? "text" : "password"}
                error={!!errors.currentPassword}
                helperText={
                  !!errors.currentPassword
                    ? t(errors.currentPassword.message)
                    : ""
                }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle currentPassword visibility"
                        onClick={handleClickShowCurrentPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showCurrentPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <p className="form-subtitle">{t("PASSWORD.NEW")}</p>
          <Controller
            name="newPassword"
            control={control}
            render={({ field }) => (
              <CssTextField
                {...field}
                sx={{
                  marginBottom: "0",
                }}
                id="outlined-adornment-newPassword"
                placeholder={t("PASSWORD.NEW_PLACEHOLDER")}
                type={showNewPassword ? "text" : "password"}
                value={field.value || ""}
                error={!!errors.newPassword}
                helperText={
                  !!errors.newPassword ? t(errors.newPassword.message) : ""
                }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle newPassword visibility"
                        onClick={handleClickShowNewPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showNewPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />

          <p className="form-subtitle">{t("PASSWORD.REPEAT")}</p>
          <Controller
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <CssTextField
                {...field}
                sx={{
                  marginBottom: "0",
                }}
                id="outlined-adornment-confirmPassword"
                placeholder={t("PASSWORD.NEW_PLACEHOLDER")}
                type={showConfirmPassword ? "text" : "password"}
                value={field.value || ""}
                error={!!errors.confirmPassword}
                helperText={
                  !!errors.confirmPassword
                    ? t(errors.confirmPassword.message)
                    : ""
                }
                variant="outlined"
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showConfirmPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
          />
        </>
      )}

      <button
        type="submit"
        disabled={!isFormValid}
        className={!isFormValid ? "btn" : "formChange__valid"}
      >
        {t("SAVE")}
      </button>
    </form>
  );
};

export default FormChange;
