const locale = {
  STATION: {
    MW: "МВт",
    NEW_STATION: "Жаңа станция",
  },
  NAVBAR: {
    POWER_GENERATION: "Қуат өндірici",
    METEODATA: "Метеодеректер",
    STATION_PERFORMANCE: "Станциялардың өнімділігі",
    MONITORING: "Мониторинг",
  },
  ACCOUNT_MENU: {
    PROFILE: "Профиль",
    PROFILE_SETTINGS: "Профиль параметрлері",
    STATION_SETTINGS: "Станция параметрлері",
    LOGOUT: "Шығу",
  },
};

export default locale;
