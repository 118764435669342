import DiagramLocale from "../../../../UI/Diagram/i18n/ru";
import TableLocale from "../../../../UI/CustomTable/i18n/ru";
import FormExportLocale from "../../../../UI/FormExport/i18n/ru";
const locale = {
  NO_ACTUAL_DATA: "Нету актуальных данных",
  NO_DATA: "Данные для выбранной даты недоступны. Приносим извинения за доставленные неудобства.",
  TITLE: "Выработка электроэнергии",
  TODAY: "Сегодня",
  TOMORROW: "Завтра",
  OTHER_DATE: "Другая дата",
  CHART: "График",
  TABLE: "Таблица",
  DAY_ACCURACY: "Суточная оправдываемость",
  AVERAGE_POWER_DEVIATION: "Средняя часовая оправдываемость",
  REVENUE: "Доход",
  CAPACITY_FACTOR: "КИУМ",
  EMISSIONS_REDUCTION: "Снижение выбросов CO2",
  EMISSIONS_ABBREVIATION: "тонн",
  MONTH_ACCURACY: "Месячная оправдываемость",
  YEAR_ACCURACY: "Годовая оправдываемость",
  MONTH_AVERAGE_ACCURACY: "Средняя суточная оправдываемость",
  YEAR_AVERAGE_ACCURACY: "Средняя месячная оправдываемость",
  TOTAL_MONTH: "Всего за месяц",
  TOTAL_YEAR: "Всего за год",
  UNIT: "кВт‧ч",
  MONTH_OR_YEAR_UNIT: "МВт‧ч",
  DEVIATION_UNIT: "МВт‧ч", 
  HIGHEST: "Максимальная выработка",
  REVENUE_DESCRIPTION: "(по тарифу {{tariff}} тенге)",
  LOWEST: "Минимальная выработка",
  TABS: {
    DAILY: "Суточная статистика",
    MONTHLY: "Месячная статистика",
    YEARLY: "Годовая статистика",
  },
  EXPORT_TABLE: "Экспорт таблицы",
  ...DiagramLocale,
  ...TableLocale,
  ...FormExportLocale,
};
export default locale;
