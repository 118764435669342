import DiagramLocale from "../../../../UI/Diagram/i18n/en";
import TableLocale from "../../../../UI/CustomTable/i18n/en";
import FormExportLocale from "../../../../UI/FormExport/i18n/en";
const locale = {
  TITLE: "Station performance",
  NO_DATA: "Data unavailable for the selected date. We apologize for any inconvenience.",
  REVENUE: "Revenue",
  REVENUE_LOWERCASE: "revenue",
  CAPACITY_FACTOR: "CUF",
  EMISSIONS_REDUCTION: "Reduction of CO2 emissions",
  EMISSIONS_ABBREVIATION: "tonnes",
  TOTAL_MONTH: "Total for the month",
  TOTAL_YEAR: "Total for the year",
  HIGHEST_DAILY: "Maximum daily {{title}}",
  HIGHEST_MONTHLY: "Maximum monthly {{title}}",
  REVENUE_DESCRIPTION: "{{date}} (at a rate of 30 tenge)",
  AVERAGE: "Average {{title}}",
  LOWEST_DAILY: "Minimum daily {{title}}",
  LOWEST_MONTHLY: "Minimum monthly {{title}}",
  MONTH: "Month",
  YEAR: "Year",
  EXPORT_TABLE: "Export table",
  CHART: "Chart",
  TABLE: "Table",

  ...DiagramLocale,
  ...TableLocale,
  ...FormExportLocale,
};
export default locale;
