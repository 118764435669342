import { authRoles } from "../../../../auth";
import Performance from "./Performance";
import i18n from "../../../../../i18n";
import kz from "./i18n/kz";
import ru from "./i18n/ru";
import en from "./i18n/en";

i18n.addResourceBundle("kz", "performancePage", kz);
i18n.addResourceBundle("ru", "performancePage", ru);
i18n.addResourceBundle("en", "performancePage", en);

const PerformanceConfig = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {},
    },
  },
  auth: authRoles.admin,
  routes: [
    {
      path: "/user/station-performance",
      element: <Performance />,
    },
  ],
};

export default PerformanceConfig;
