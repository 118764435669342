import ForgotPasswordPage from "./forgotPasswordPage";
import authRoles from "../../../auth/authRoles";
import kz from "./i18n/kz";
import ru from "./i18n/ru";
import en from "./i18n/en";
import i18n from "../../../../i18n";

i18n.addResourceBundle("kz", "forgotPasswordPage", kz);
i18n.addResourceBundle("ru", "forgotPasswordPage", ru);
i18n.addResourceBundle("en", "forgotPasswordPage", en);

const forgotPasswordPagesConfigs = {
  settings: {
    layout: {
      style: "layoutMain",
      config: {
        navbar: {
          show: false,
        },
        footer: false,
      },
    },
  },
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/forgot-password",
      element: <ForgotPasswordPage />,
    },
  ],
};

export default forgotPasswordPagesConfigs;
