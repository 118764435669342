import DiagramLocale from "../../../../UI/Diagram/i18n/en";
import TableLocale from "../../../../UI/CustomTable/i18n/en";
import FormExportLocale from "../../../../UI/FormExport/i18n/en";
const locale = {
  NO_ACTUAL_DATA: "No actual data",
  TITLE: "Power Generation",
  NO_DATA: "Data unavailable for the selected date. We apologize for any inconvenience.",
  TODAY: "Today",
  TOMORROW: "Tomorrow",
  OTHER_DATE: "Other date",
  CHART: "Chart",
  TABLE: "Table",
  DAY_ACCURACY: "Day accuracy",
  AVERAGE_POWER_DEVIATION: "Average hourly accuracy",
  REVENUE: "Revenue",
  CAPACITY_FACTOR: "CUF",
  EMISSIONS_REDUCTION: "Reduction of CO2 emissions",
  EMISSIONS_ABBREVIATION: "tonnes",
  MONTH_ACCURACY: "Month accuracy",
  YEAR_ACCURACY: "Year accuracy",
  MONTH_AVERAGE_ACCURACY: "Average daily accuracy",
  YEAR_AVERAGE_ACCURACY: "Average monthly accuracy",
  TOTAL_MONTH: "Total for the month",
  TOTAL_YEAR: "Total for the year",
  UNIT: "kWh",
  MONTH_OR_YEAR_UNIT: "MWh",
  DEVIATION_UNIT: "MWh", 
  HIGHEST: "Highest output",
  REVENUE_DESCRIPTION: "(at a rate of {{tariff}} tenge)",
  LOWEST: "Lowest output",
  TABS: {
    DAILY: "Daily statistics",
    MONTHLY: "Monthly statistics",
    YEARLY: "Yearly statistics",
  },
  EXPORT_TABLE: "Export table",
  ...DiagramLocale,
  ...TableLocale,
  ...FormExportLocale,
};

export default locale;
