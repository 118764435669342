import { createSlice } from "@reduxjs/toolkit";
import { fetchComponentsAsync } from "./monitoringThunks";

const initialState = {
    components: [],
    loading: false,
    error: null,
  };

const componentSlice = createSlice({
  name: 'components',
  initialState,
  reducers: {
    fetchComponentsRequest: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchComponentsSuccess: (state, action) => {
      state.loading = false;
      state.components = action.payload;
    },
    fetchComponentsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    extraReducers: {
      [fetchComponentsAsync.fulfilled]: (state, action) => {
        console.log(state);
      },
    },
  },
});

export const { fetchComponentsRequest, fetchComponentsSuccess, fetchComponentsFailure } = componentSlice.actions;

export default componentSlice.reducer;