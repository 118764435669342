const locale = {
  STATION: {
    MW: "МВт",
    NEW_STATION: "Новая станция",
  },
  NAVBAR: {
    POWER_GENERATION: "Выработка электроэнергии",
    METEODATA: "Метеоданные",
    STATION_PERFORMANCE: "Показатели станции",
    MONITORING: "Мониторинг",
  },
  ACCOUNT_MENU: {
    PROFILE: "Профиль",
    PROFILE_SETTINGS: "Настройки профиля",
    STATION_SETTINGS: "Настройки станции",
    LOGOUT: "Выйти",
  },
};

export default locale;
