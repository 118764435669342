import * as React from "react";
import { useEffect, useState } from "react";
import {
  dailyStateCalendar,
  dailyStateCalendarTabCenter,
  dailyStateCalendarTabLeft,
  dailyStateCalendarTabRight,
  datePickerStyle,
} from "../../../../../utils/stylesForMui";
import { Avatar, Dialog, Tab, Tabs } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { show } from "../../../../../store/diagram/diagramSlice";
import { loadStats } from "../../../../../store/diagram/diagramThunks";
import { useDispatch, useSelector } from "react-redux";
import LineDiagram from "../../../../UI/Diagram/LineDiagram";
import CustomTable from "../../../../UI/CustomTable/CustomTable";
import Spinner from "../../../../UI/Spinner/Spinner";
import { format } from "date-fns";
import { filterObjectsWithNonNullValues, getVisibleDays } from "../../../../../utils/utilsFunc";
import Widget from "../../../../UI/Widget/Widget";
import Grid from "@mui/material/Grid";
import CardMeteodata from "../../../../UI/CardMeteodata/CardMeteodata";
import { useTranslation } from "react-i18next";
import { enUS, kk, ru } from "date-fns/locale";
import { selectCurrentLanguageId } from "../../../../../store/i18n/i18nThunks";
import ModalBase from "../../../shared-component/ModalBase/ModalBase";
import FormExport from "../../../../UI/FormExport/FormExport";

function MeteoDataStats({ meteodataTab }) {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.meteo);
  const showApd = useSelector((state) => state.dashboard.showApd);

  const filteredArray = filterObjectsWithNonNullValues(stats.today.data);
  const lastObject = filteredArray[filteredArray.length - 1];

  const [openModalExport, setOpenModalExport] = useState(false);
  const [isPlannedChecked, setIsPlannedChecked] = useState(true);
  const [isActualChecked, setIsActualChecked] = useState(true);

  const currentLanguage = useSelector(selectCurrentLanguageId);
  const { t } = useTranslation("meteodataPage");
  const localeMap = {
    en: enUS,
    ru: ru,
    kz: kk,
  };
  const [currentLocale, setCurrentLocale] = useState(
    localeMap[currentLanguage],
  );
  const [activeTab, setActiveTab] = useState("today");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDataPicker, setOpenDataPicker] = useState(false);
  const [typeData, setTypeData] = useState("chart");

  const getMeteodataInfo = stats[activeTab]?.data?.map(
    (el) => el[meteodataTab],
  );

  const actual = getMeteodataInfo
    ?.map((el) => el.actual)
    .filter((val) => val !== null)
    .reduce((acc, val) => acc + parseInt(val), 0);

  const prediction = getMeteodataInfo
    ?.map((el) => el.prediction)
    .slice(0, actual.length)
    .reduce((acc, val) => acc + parseInt(val), 0);
  const apd =
    actual !== 0
      ? Math.abs(
          100 -
            (Math.abs(actual - prediction) /
              Math.abs((actual + prediction) / 2)) *
              100
        ).toFixed(2)
      : 0;
  console.log(prediction, actual);
  const getTomorrowDate = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return tomorrow;
  };

  const showAllWidgets = () => {
    const selectedDateFormatted = format(selectedDate, "yyyy-MM-dd");
    const tomorrowDateFormatted = format(getTomorrowDate(), "yyyy-MM-dd");
    console.log(activeTab);
    // if (activeTab == 'temperature' || activeTab == 'temperature_panel') return false;
    return (
      (activeTab === "tomorrow" ||
        selectedDateFormatted >= tomorrowDateFormatted) &&
      activeTab !== "today"
    );
  };
  const getActiveTabDate = () => {
    if (activeTab === "today") {
      return format(new Date(), "yyyy-MM-dd");
    } else if (activeTab === "tomorrow") {
      return format(getTomorrowDate(), "yyyy-MM-dd");
    } else {
      return format(selectedDate, "yyyy-MM-dd");
    }
  };

  useEffect(() => {
    dispatch(show(showAllWidgets()));
    setCurrentLocale(localeMap[currentLanguage]);

    dispatch(
      loadStats({
        statBlock: "meteo",
        statType: activeTab,
        date: getActiveTabDate(),
      }),
    );
  }, [activeTab, selectedDate, currentLanguage]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      loadStats({
        statBlock: "stats",
        statType: "otherDate",
        date: format(selectedDate, "yyyy-MM-dd"),
      }),
    );
  };

  const renderDataByType = () => {
    return typeData === "chart" ? (
      <LineDiagram
        data={stats[activeTab].data}
        meteodataArr={getMeteodataInfo}
        day={showAllWidgets()}
        meteodataTab={meteodataTab}
        t={t}
        setIsActualChecked={setIsActualChecked}
        setIsPlannedChecked={setIsPlannedChecked}
      />
    ) : (
      <CustomTable
        data={stats[activeTab].data}
        meteodataTab={meteodataTab}
        day={showAllWidgets()}
        t={t}
      />
    );
  };

  const renderErrorOrTheData = () => {
    return stats[activeTab].error ? (
      <div className="error title">
        <Avatar
          src={"/images/error.jpeg"}
          alt={"error"}
          sx={{ width: 150, height: 150 }}
        />
        <p style={{ color: "#666666" }}>{t("NO_DATA")}</p>
      </div>
    ) : (
      renderDataByType()
    );
  };

  return (
    <>
      <Grid container spacing={2} className="block-width">
        <Grid item xs={12} md={9}>
          <div className="tab-block">
            <div className="date-block">
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("TODAY")}
                  value={"today"}
                  style={{
                    backgroundColor: activeTab === "today" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("TOMORROW")}
                  value={"tomorrow"}
                  style={{
                    backgroundColor: activeTab === "tomorrow" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabCenter}
                />
                <Tab
                  label={t("OTHER_DATE")}
                  value={"otherDate"}
                  style={{
                    backgroundColor: activeTab === "otherDate" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>

            {activeTab === "otherDate" && (
              <div className="date-block">
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={currentLocale}
                >
                  <DesktopDatePicker
                    sx={datePickerStyle}
                    value={selectedDate}
                    onChange={handleDateChange}
                    shouldDisableDate={(date) =>
                      date > new Date(Date.now() + getVisibleDays())
                    }
                    open={openDataPicker}
                    onClose={() => setOpenDataPicker(false)}
                    slotProps={{
                      textField: {
                        onClick: () => setOpenDataPicker(true),
                      },
                      OpenPickerIcon: CalendarTodayIcon,
                    }}
                    orientation="portrait"
                  />
                </LocalizationProvider>
              </div>
            )}

            <div className="view-block">
              <Tabs
                value={typeData}
                onChange={(_, newValue) => setTypeData(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("CHART")}
                  value={"chart"}
                  style={{
                    backgroundColor: typeData === "chart" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("TABLE")}
                  value={"table"}
                  style={{
                    backgroundColor: typeData === "table" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>
            {/*<btn*/}
            {/*  className="btn-download"*/}
            {/*  onClick={() => setOpenModalExport(true)}*/}
            {/*>*/}
            {/*  {t("EXPORT_TABLE")}*/}
            {/*</btn>*/}

            {/*<Dialog*/}
            {/*  open={openModalExport}*/}
            {/*  onClose={() => setOpenModalExport(false)}*/}
            {/*  maxWidth="xs"*/}
            {/*>*/}
            {/*  <ModalBase*/}
            {/*    onClose={() => setOpenModalExport(false)}*/}
            {/*    title={t("EXPORT_TABLE")}*/}
            {/*  >*/}
            {/*    <FormExport*/}
            {/*      date={selectedDate}*/}
            {/*      activeTab={"selectDate"}*/}
            {/*      meteodataTab={meteodataTab}*/}
            {/*      isPlannedChecked={isPlannedChecked}*/}
            {/*      isActualChecked={isActualChecked}*/}
            {/*      t={t}*/}
            {/*      currentLocale={currentLocale}*/}
            {/*    />*/}
            {/*  </ModalBase>*/}
            {/*</Dialog>*/}
          </div>

          <div
            className="chart"
            style={{
              background: stats[activeTab].error
                ? ""
                : "rgba(243, 245, 250, 0.4)",
            }}
          >
            {stats[activeTab].loaded ? renderErrorOrTheData() : <Spinner />}
          </div>
        </Grid>
            
        <Grid item xs={12} md={3}>
          {stats[activeTab].loaded && !showApd && (
            <Widget title={t("ACCURACY")} data={(apd !== 0 && !isNaN(apd)) ? `${apd} %` : t('NO_ACTUAL_DATA')} dataName={""} />
          )}
          <div style={{ position: "relative" }}>
            {<CardMeteodata data={lastObject} />}
          </div>
        </Grid>
      </Grid>
    </>
  );
}

export default MeteoDataStats;
