import React from "react";
import "./card-meteodata.css";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";

// const meteoData = [
//   { name: "Температура", value: "4", valueName: "°C" },
//   { name: "Иррадиация", value: "120", valueName: "кВт‧ч/м²" },
//   { name: "Облачность", value: "20", valueName: "%" },
//   { name: "Скорость ветра", value: "4", valueName: "м/c" },
//   { name: "Осадки", value: "1,5", valueName: "мм, снег" },
// ];

const CardMeteodata = ({ data }) => {
  const { t } = useTranslation("meteodataPage");
  // translation key and data value pair in useState

  // ...

  const [meteoData, setMeteoData] = useState([]);

  useEffect(() => {
    const newMeteoData = [
      {
        name: t("TABS.TEMPERATURE"),
        value: data?.temperature?.actual,
        unit: "°C",
      },
      {
        name: t("TABS.IRRADIANCE"),
        value: data?.irradiance?.actual,
        unit: "IRRADIANCE_UNIT",
      },
      {
        name: t("TABS.CLOUDINESS"),
        value: data?.cloudiness?.actual,
        unit: "%",
      },
      {
        name: t("TABS.WIND_SPEED"),
        value: data?.wind_speed?.actual || data?.windSpeed?.actual,
        unit: "WIND_SPEED_UNIT",
      },
      {
        name: t("TABS.PRECIPITATION"),
        value: data?.precipitation?.actual,
        unit: "PERCIPITATION_UNIT",
      },
      {
        name: "TABS.WIND_DIRECTION",
        value: data?.windDirection?.actual,
        unit: "°",
      },
      {
        name: "TABS.AIR_DENSITY",
        value: data?.airDensity?.actual,
        unit: "AIR_DENSITY_UNIT",
      },
      {
        name: "TABS.AIR_TEMPERATURE",
        value: data?.airTemperature?.actual,
        unit: "°C",
      },
      {
        name: "TABS.PRESSURE",
        value: data?.atmosphericPressure?.actual,
        unit: "PRESSURE_UNIT",
      },
    ].filter((item) => item.value !== undefined);

    setMeteoData(newMeteoData);
  }, [data, t]);

  return (
    <div className="cardMeteodata">
      <div className="cardMeteodata__header">
        <p className="cardMeteodata__title">{t("CURRENT_DATA")}</p>
      </div>

      <div className="cardMeteodata__content">
        {data === undefined ? (
          <p className="cardMeteodata__category">{t("NO_CURRENT_DATA")}</p>
        ) : (
          <>
            {meteoData.map((item, index) => (
              <div className="cardMeteodata__item" key={index}>
                <p className="cardMeteodata__category">{item.name}</p>
                <p className="cardMeteodata__value">
                  {item.value.toFixed(2)} {t(item.unit)}
                </p>
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default CardMeteodata;
