const locale = {
  TITLE: "Кіру",
  EMAIL_PLACEHOLDER: "Email-ді енгізіңіз",
  PASSWORD: "Құпия сөз",
  PASSWORD_PLACEHOLDER: "Құпия сөзді енгізіңіз",
  REMEMBER_ME: "Есте сақтау",
  FORGOT_PASSWORD: "Құпия сөзді ұмыттыңыз ба?",
  SIGN_UP_TEXT:
    "Аккаунтыңыз жоқ па? Кіру үшін бізбен байланысыңыз немесе демо-нұсқасын көріңіз",
  SIGN_UP: "Өтініш қалдыру",
  SIGN_IN: "Кіру",
  VALIDATION: {
    EMAIL_FORMAT: "Електрондық поштаңызды дұрыс енгізіңіз",
    PASSWORD_FORMAT: "Құпия сөз тым қысқа - кемінде 4 таңба болуы керек  ",
    REQUIRED: "Міндетті өрісті енгізіңіз",
  },
  PARAGRAPH_1:"ЖЭК объектілерінің электр энергиясын өндіруін болжауға арналған IT сервис",
  PARAGRAPH_2:"ЖЭК-тен электр энергиясын өндірудің нақты тәуліктік болжамдарын қалыптастыру есебінен Орталық Азия елдерінің электр энергетикалық жүйелерінің жұмыс істеу тиімділігін арттыру"
};
export default locale;
