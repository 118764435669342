const locale = {
  TITLE: "Station information",
  EDIT: "Edit",
  SOLAR_POWER_PLANT: "Solar power plant",
  CAPACITY: "Installed capacity",
  CAPACITY_UNIT: "MW",
  TARIFF: "Tariff",

  EDIT_INFO: "Edit data",
  DESCRIPTION:
    "Send a request to change the data or contact us at +7 707 000 00 00",
  SEND: "Send",

  SET_STATION: "Add new station",
  LOCATION: "Station location",
  SELECT_LOCATION: "Select region",

  REGIONS: {
    AKMOLA: "Akmola region",
    ABAY: "Abay region",
    AKTOBE: "Aktobe region",
    ALMATY: "Almaty region",
  },
};
export default locale;
