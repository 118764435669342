import React, { useState, useEffect } from "react";
import "./info-station.css";
import { Dialog } from "@mui/material";
import ModalEditInfo from "../../dashboard/shared-component/ModalEditInfo/ModalEditInfo";
import { useSelector } from "react-redux";
import { selectStationInfo, selectUser } from "../../../store/user/userThunks";
import { useTranslation } from "react-i18next";

const InfoStation = () => {
  const { t, i18n } = useTranslation("infoStationPage");

  const [open, setOpen] = useState(false);
  const user = useSelector(selectUser);
  const stationInfo = useSelector(selectStationInfo);
  const [infoStation, setInfoStation] = useState([]);
  const { mainStationInfo } = useSelector((state) => state.dashboard);

  useEffect(() => {

    // const filteredStation = user.stations.find(
    //   (el) => el.id === stationInfo
    // );
    const filteredStation = user.stations;
    if (filteredStation) {
      const sorted = [];
      setInfoStation(sorted.sort((a, b) => a.id - b.id));
      console.log(sorted);
    }
  }, [stationInfo, user.stations]);

  return (
    <div className="container-inner">
      <div className="infoStation__header">
        <h1 className="title">{t("TITLE")}</h1>
        <button onClick={() => setOpen(true)} className="infoStation__btn">
          {t("EDIT")}
        </button>
      </div>

      <div className="infoStation__body">
        <div className="infoStation__row">
          <p>{t("SOLAR_POWER_PLANT")}</p>
          {mainStationInfo && mainStationInfo.type && mainStationInfo.name ? (
            <p>{mainStationInfo.type.name[i18n.language] + " " + mainStationInfo.name[i18n.language]}</p>
          ) : <></>}
        </div>

        <div className="infoStation__row">
          <p>{t("CAPACITY")}</p>
          <p>
            {mainStationInfo.capacity} {t("CAPACITY_UNIT")}
          </p>
        </div>
        <div className="infoStation__row">
          <p>{t("TARIFF")}</p>
          <p>{mainStationInfo.tariff} ₸</p>
        </div>
        {/* {infoStation
          .filter((el) => {
            if (
              el.name !== "Солнечная электростанция" &&
              el.name !== "Установленная мощность"
            ) {
              return el;
            }
          })
          .map((el, i) => {
            return (
              <div key={i} className="infoStation__row">
                <p>{el.name[i18n.language]}</p>
                <p>{el.value}</p>
              </div>
            );
          })} */}
      </div>
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <ModalEditInfo onClose={() => setOpen(false)} />
      </Dialog>
    </div>
  );
};

export default InfoStation;
