import * as React from "react";
import { useEffect, useState } from "react";
import {
  dailyStateCalendar,
  dailyStateCalendarTabLeft,
  dailyStateCalendarTabRight,
  datePickerStyle,
} from "../../../../../utils/stylesForMui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { enUS, ru, kk } from "date-fns/locale"; // Import date-fns locales
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { loadStats } from "../../../../../store/diagram/diagramThunks";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../UI/Spinner/Spinner";
import Widget from "../../../../UI/Widget/Widget";
import { format } from "date-fns";
import BarDiagram from "../../../../UI/Diagram/BarDiagram";
import {
  findObjectWithMaxAction,
  findObjectWithMinActual,
  total,
} from "../../../../../utils/utilsFunc";
import Grid from "@mui/material/Grid";
import { Avatar, Dialog, Tab, Tabs } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { useTranslation } from "react-i18next";
import { selectCurrentLanguageId } from "../../../../../store/i18n/i18nThunks";
import ModalBase from "../../../shared-component/ModalBase/ModalBase";
import FormExport from "../../../../UI/FormExport/FormExport";
import CustomTable from "../../../../UI/CustomTable/CustomTable";
import { isNumber } from "lodash";


const months = {
  ru: [
    'Январь',  
    'Февраль',  
    'Март',    
    'Апрель',   
    'Май',      
    'Июнь',     
    'Июль',     
    'Август',   
    'Сентябрь', 
    'Октябрь',  
    'Ноябрь',   
    'Декабрь'   
  ],
  kz: [
    'Қаңтар',   
    'Ақпан',    
    'Наурыз',   
    'Сәуір',    
    'Мамыр',    
    'Маусым',   
    'Шілде',    
    'Тамыз',    
    'Қыркүйек', 
    'Қазан',    
    'Қараша',   
    'Желтоқсан'  
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
};

function MonthOrYearPerformance({ dataConverter, tabPerformance }) {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.performance);
  const { t } = useTranslation("performancePage");
  const currentLanguage = useSelector(selectCurrentLanguageId);
  const localeMap = {
    en: enUS,
    ru: ru,
    kz: kk,
  };
  const numberLocaleMap = {
    en: "en-US",
    ru: "ru-RU",
    kz: "kk-KZ",
  };
  const [typeData, setTypeData] = useState("chart");
  const [currentLocale, setCurrentLocale] = useState(
    localeMap[currentLanguage],
  );

  const [currentNumberLocale, setCurrentNumberLocale] = useState(
    numberLocaleMap[currentLanguage],
  );

  // --------------------------------------------------

  const [activeTab, setActiveTab] = useState("monthly");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDataPicker, setOpenDataPicker] = useState(false);

  const [renderData, setRenderData] = useState([]);
  const dateFormatted =
    activeTab === "monthly"
      ? format(selectedDate, "yyyy-MM")
      : format(selectedDate, "yyyy");

  let abbreviation = "";
  let titleWidget = "";
  const [openModalExport, setOpenModalExport] = useState(false);

  if (tabPerformance === "revenue") {
    abbreviation = "₸";
    titleWidget = t("REVENUE_LOWERCASE");
  } else if (tabPerformance === "kium") {
    abbreviation = "%";
    titleWidget = t("CAPACITY_FACTOR");
  } else if (tabPerformance === "emissions") {
    abbreviation = t("EMISSIONS_ABBREVIATION");
    titleWidget = t("EMISSIONS_REDUCTION")
  }

  // --------------------------------------------------
  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      loadStats({
        statBlock: "summary",
        statType: activeTab,
        date: dateFormatted,
      }),
    );
  };

  useEffect(() => {
    if (!stats[activeTab].data) {
      return;
    }
    setCurrentLocale(localeMap[currentLanguage]);
    const transformedData = JSON.parse(JSON.stringify(stats[activeTab].data));
    console.log("statistics", JSON.parse(JSON.stringify(stats[activeTab].data)));
    const actualData = transformedData?.find((item) => item.name == 'Actual');
    let type = 'month';
    if (actualData?.series.length < 13) type = 'year'
    transformedData.forEach((item) => {
      item.series.forEach((point) => {
        point.value = dataConverter(Number(point.value), type);
      });
    });
    setRenderData(transformedData);
  }, [stats[activeTab].data, dataConverter, activeTab, currentLanguage]);

  useEffect(() => {
    dispatch(
      loadStats({
        statBlock: "performance",
        statType: activeTab,
        date: dateFormatted,
      }),
    );
  }, [activeTab, selectedDate, dispatch]);
  // --------------------------------------------------
  const numOfNotFilledActualValues = stats[activeTab].data
    ?.find((item) => item.name === "Actual")
    ?.series.reduce(
      (accumulator, obj) =>
        obj.value !== null ? parseInt(accumulator) + 1 : accumulator,
      0,
    );

  const predictions =
    stats[activeTab] && Array.isArray(stats[activeTab].data)
      ? stats[activeTab].data?.find((item) => item.name === "Intech")
      : null;

  const actualDataPrediction = predictions
    ? predictions.series.slice(0, numOfNotFilledActualValues)
    : null;

  const totalActual = total(stats[activeTab].data, "Actual");
  const totalPrediction = total(stats[activeTab].data, "Intech");
  const totalPredictionForApd = actualDataPrediction?.reduce(
    (acc, val) => acc + parseInt(val.value),
    0,
  );

  const apd =
    totalActual !== 0
      ? Math.max(
          0,
          100 -
            (Math.abs(totalActual - totalPredictionForApd) /
              Math.abs((totalActual + totalPredictionForApd) / 2)) *
              100,
        ).toFixed(2)
      : 0;

  const renderErrorOrTheData = () => {
    return stats[activeTab].error ? (
      <div className="error title">
        <Avatar
          src={"/images/error.jpeg"}
          alt={"error"}
          sx={{ width: 150, height: 150 }}
        />
        <p style={{ color: "#666666" }}>{t("NO_DATA")}</p>
      </div>
    ) : typeData === "table" ? (
      <CustomTable
        data={stats[activeTab].data}
        type={activeTab}
        date={
          activeTab === "monthly"
            ? format(selectedDate, "MM / yyyy")
            : format(selectedDate, "yyyy")
        }
        totalActual={totalActual}
        apd={apd}
        day={false}
        t={t}
      />
    ) : (
      <BarDiagram data={stats[activeTab].data} activeTab={activeTab} t={t} />
    );
  };

  const getDataWidget = () => {
    const total =
      tabPerformance === "emissions"
        ? renderData
            ?.find((item) => item.name === "Actual")
            ?.series.reduce(
              (acc, obj) => parseFloat(acc) + parseFloat(obj.value),
              0,
            )
            .toFixed(2)
        : renderData
            ?.find((item) => item.name === "Actual")
            ?.series.reduce(
              (acc, obj) => parseInt(acc) + parseInt(obj.value),
              0,
            );
    let highestOutput = findObjectWithMaxAction(renderData);
    console.log("high:", renderData);
    let lowestOutput = findObjectWithMinActual(renderData);
    const dateHighestOutput =
      activeTab === "monthly"
        ? `${highestOutput?.labels} ${format(selectedDate, "MMMM", {
            locale: currentLocale,
          })}`
        : months[currentLanguage][highestOutput?.labels - 1];
    const numberOfDaysOrMonths = renderData
        ?.find((item) => item.name === "Actual")
        ?.series.reduce(
          (acc, obj) => parseFloat(obj.value) != 0 ? acc + 1 : acc,
          0,
        )
    console.log(dateHighestOutput);
    const dateLowestOutput =
      activeTab === "monthly"
        ? `${lowestOutput?.labels} ${format(selectedDate, "MMMM", {
            locale: currentLocale,
          })}`
        : months[currentLanguage][lowestOutput?.labels - 1]
    let widget = [
      tabPerformance != 'kium' ? {
        title: activeTab === "monthly" ? t("TOTAL_MONTH") : t("TOTAL_YEAR"),
        data: total?.toLocaleString(currentNumberLocale),
        dataName: abbreviation,
      } : null,
      {
        title: t(activeTab == 'monthly' ? "HIGHEST_DAILY" : "HIGHEST_MONTHLY", { ending: tabPerformance == "emissions" ? "ое" : "ый", title: titleWidget}),
        data: Number(highestOutput?.actual).toLocaleString(currentNumberLocale),
        dataName: abbreviation,
        date:
          tabPerformance === "revenue"
            ? t("REVENUE_DESCRIPTION", { date: dateHighestOutput })
            : dateHighestOutput,
      },
      {
        title: t("AVERAGE", { ending: tabPerformance == "emissions" ? "ее" : "ий", title: titleWidget}),
        data:
          total &&
          (total / numberOfDaysOrMonths).toLocaleString(
            currentNumberLocale,
          ),
        dataName: abbreviation,
        date:
          tabPerformance === "revenue" &&
          t("REVENUE_DESCRIPTION", { date: "" }),
      },
      {
        title: t(activeTab == 'monthly' ? "LOWEST_DAILY" : "LOWEST_MONTHLY", { ending: tabPerformance == "emissions" ? "ое" : "ый", title: titleWidget}),
        data: Number(lowestOutput?.actual).toLocaleString(currentNumberLocale),
        dataName: abbreviation,
        date:
          tabPerformance === "revenue"
            ? t("REVENUE_DESCRIPTION", { date: dateLowestOutput })
            : dateLowestOutput,
      },
    ].filter(item => item !== null);

    widget =
      tabPerformance === "kium"
        ? widget.filter((el) => el.title !== t("TOTAL_MONTH"))
        : widget;
    console.log(widget);
    return widget;
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9}>
          {/* --------------- Tabs --------------- */}

          <div className="tab-block">
            <div className="date-block">
              <Tabs
                value={activeTab}
                onChange={(_, newValue) => setActiveTab(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("MONTH")}
                  value={"monthly"}
                  style={{
                    backgroundColor: activeTab === "monthly" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("YEAR")}
                  value={"yearly"}
                  style={{
                    backgroundColor: activeTab === "yearly" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>

            <div className="date-block" style={{ width: "155px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={currentLocale}
              >
                <DesktopDatePicker
                  sx={datePickerStyle}
                  views={activeTab === "monthly" ? ["month", "year"] : ["year"]}
                  value={selectedDate}
                  onYearChange={handleDateChange}
                  minDate={new Date("2021-01-01")}
                  maxDate={new Date()}
                  open={openDataPicker}
                  onClose={() => setOpenDataPicker(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenDataPicker(true),
                    },
                    OpenPickerIcon: CalendarTodayIcon,
                  }}
                  orientation="portrait"
                />
              </LocalizationProvider>
            </div>

            <div className="view-block">
              <Tabs
                value={typeData}
                onChange={(_, newValue) => setTypeData(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("CHART")}
                  value={"chart"}
                  style={{
                    backgroundColor: typeData === "chart" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("TABLE")}
                  value={"table"}
                  style={{
                    backgroundColor: typeData === "table" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>

            <btn
              className="btn-download"
              onClick={() => setOpenModalExport(true)}
            >
              {t("EXPORT_TABLE")}
            </btn>

            <Dialog
              open={openModalExport}
              onClose={() => setOpenModalExport(false)}
              maxWidth="xs"
            >
              <ModalBase
                onClose={() => setOpenModalExport(false)}
                title={t("EXPORT_TABLE")}
              >
                <FormExport
                  date={selectedDate}
                  activeTab={
                    activeTab === "monthly" ? "selectMonth" : "selectYear"
                  }
                  performanceTab={tabPerformance}
                  t={t}
                  currentLocale={currentLocale}
                />
              </ModalBase>
            </Dialog>
          </div>

          {/* ------------------------------------ */}

          {/* --------------- Diagram --------------- */}

          <div
            className="chart"
            style={{
              background: stats[activeTab].error
                ? ""
                : "rgba(243, 245, 250, 0.4)",
            }}
          >
            {stats[activeTab].loaded ? renderErrorOrTheData() : <Spinner />}
          </div>
        </Grid>
        {/* ---------------------------------------- */}

        {/* --------------- Widgets --------------- */}
        <Grid item xs={12} md={3}>
          <div className="widgets">
            {stats[activeTab].loaded &&
              (stats[activeTab].error
                ? null
                : getDataWidget().map((el, i) => (
                    <Widget
                      key={i}
                      title={el.title}
                      data={el.data}
                      dataName={el.dataName}
                      date={el.date}
                    />
                  )))}
          </div>
        </Grid>

        {/* ---------------------------------------- */}
      </Grid>
    </>
  );
}

export default MonthOrYearPerformance;
