import React, {useEffect} from "react";
import {yupResolver} from "@hookform/resolvers/yup";
import {Controller, useForm} from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Typography from "@mui/material/Typography";
import {Link} from "react-router-dom";
import * as yup from "yup";
import _ from "../../../../@lodash";
import "./sign-in.css";
import {IconButton, InputAdornment} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {CssTextField} from "../../../utils/customCssTextField";
import {CustomButton} from "../../../utils/customButton";
import jwtService from "../../../auth/services/jwtService";
import LanguageSwitcher from "../../../theme-layouts/layout-main/components/LanguageSwitcher";
import {useTranslation} from "react-i18next";
import VideoBackground from "../../UI/VideoBackground/VideoBackground";

/**
 * Form Validation Schema
 */
const schema = yup.object().shape({
    email: yup
        .string()
        .email("VALIDATION.EMAIL_FORMAT")
        .required("VALIDATION.REQUIRED"),
    // .matches(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im, 'Invalid phone number.'),
    password: yup
        .string()
        .required("VALIDATION.REQUIRED")
        .min(4, "VALIDATION.PASSWORD_FORMAT"),
});

const defaultValues = {
    phone: "demo@tgs-energy.kz",
    password: "admin",
    remember: true,
};

function SignInPage() {
    const [showPassword, setShowPassword] = React.useState(false);
    const {t} = useTranslation("signInPage");

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const {control, formState, handleSubmit, setError, setValue} = useForm({
        mode: "onChange",
        defaultValues,
        resolver: yupResolver(schema),
    });

    const {isValid, dirtyFields, errors} = formState;

    useEffect(() => {
        setValue("email", "demo@tgs-energy.kz", {
            shouldDirty: true,
            shouldValidate: true,
        });
        setValue("password", "admin", {shouldDirty: true, shouldValidate: true});
    }, [setValue]);

    function onSubmit({email, password}) {
        console.log("onSubmit signInWithEmailAndPassword");
        jwtService.signInWithEmailAndPassword(email, password).catch((error) => {
            console.log("onSubmit signInWithEmailAndPassword error: ", error);
            setError("password", {
                type: "manual",
                message: error,
            });
        });
    }

    return (
        <div className="signIn">
            <div className="sighIn__wrap">
                <div className="auth_header">
                    <img src="/images/logo.svg" alt="logo"/>
                    <div style={{display: "contents"}}>
                        <LanguageSwitcher/>
                    </div>
                </div>
                <div className="sighIn__content">
                    <h1 className={"signIn__title"}> {t("TITLE")}</h1>

                    <form
                        name="loginForm"
                        noValidate
                        className="flex flex-col justify-center w-full mt-32"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <p className={"signIn__subtitle"}>Email</p>
                        <Controller
                            control={control}
                            name="email"
                            render={({field, fieldState}) => (
                                <CssTextField
                                    {...field}
                                    error={!!fieldState.error}
                                    helperText={
                                        !!fieldState.error ? t(fieldState.error.message) : ""
                                    }
                                    fullWidth
                                    placeholder={t("EMAIL_PLACEHOLDER")}
                                />
                            )}
                        />

                        <p className={"signIn__subtitle"}>{t("PASSWORD")}</p>
                        <Controller
                            name="password"
                            control={control}
                            render={({field}) => (
                                <CssTextField
                                    {...field}
                                    sx={{
                                        marginBottom: "32px",
                                    }}
                                    id="outlined-adornment-password"
                                    placeholder="Введите пароль"
                                    type={showPassword ? "text" : "password"}
                                    error={!!errors.password}
                                    helperText={
                                        !!errors.password ? t(errors.password.message) : ""
                                    }
                                    variant="outlined"
                                    required
                                    fullWidth
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility/> : <VisibilityOff/>}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            )}
                        />

                        <div className="form__rememberMe">
                            <div style={{display: "flex", alignItems: "center"}}>
                                <Controller
                                    name="remember"
                                    control={control}
                                    render={({field}) => (
                                        <FormControl>
                                            <FormControlLabel
                                                label={t("REMEMBER_ME")}
                                                control={
                                                    <Checkbox
                                                        size="small"
                                                        {...field}
                                                        style={{color: "#F07048"}}
                                                    />
                                                }
                                                sx={{
                                                    "& .MuiFormControlLabel-label": {
                                                        fontWeight: 400,
                                                        fontSize: "16px",
                                                        lineHeight: "24px",
                                                    },
                                                }}
                                            />
                                        </FormControl>
                                    )}
                                />
                            </div>

                            <Link
                                className="form__forgot"
                                to="/forgot-password"
                                style={{
                                    color: "#737486",
                                    fontWeight: 400,
                                    fontSize: "16px",
                                    lineHeight: "24px",
                                }}
                            >
                                {t("FORGOT_PASSWORD")}
                            </Link>
                        </div>

                        <CustomButton
                            variant="contained"
                            aria-label="Sign in"
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            type="submit"
                            size="large"
                            sx={{
                                fontSize: "15px",
                                lineHeight: "26px",
                                color: "#FFFFFF",
                                background: "#272933",
                                width: "100%",
                                textTransform: "capitalize",
                                padding: "11px 0",
                                "&:hover": {
                                    backgroundColor: "#272933",
                                },
                            }}
                        >
                            {t("SIGN_IN")}
                        </CustomButton>
                    </form>

                    <div className="line"></div>

                    <div className="signUp__wrap">
                        <Typography
                            sx={{
                                fontSize: "16px",
                                lineHeight: "24px",
                                color: "#212B36",
                                mb: "20px",
                            }}
                        >
                            {t("SIGN_UP_TEXT")}
                        </Typography>
                        <Link
                            className="link_register"
                            to="/sign-up"
                            style={{
                                color: "#F07048",
                                fontWeight: 500,
                                fontSize: "16px",
                                lineHeight: "24px",
                            }}
                        >
                            {t("SIGN_UP")}
                        </Link>
                    </div>
                </div>
            </div>
            <VideoBackground t = {t}/>
            {/*<img className="bg-img" src="/images/sign-in-bg.png" alt="BG"/>*/}
        </div>
    );
}

export default SignInPage;
