import * as React from "react";
import { useEffect, useState } from "react";
import {
  dailyStateCalendar,
  dailyStateCalendarTabLeft,
  dailyStateCalendarTabRight,
  datePickerStyle,
} from "../../../../../utils/stylesForMui";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import ru from "date-fns/locale/ru";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { loadStats } from "../../../../../store/diagram/diagramThunks";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../../../../UI/Spinner/Spinner";
import Widget from "../../../../UI/Widget/Widget";
import { format } from "date-fns";
import BarDiagram from "../../../../UI/Diagram/BarDiagram";
import Grid from "@mui/material/Grid";
import {
  findObjectWithMaxAction,
  findObjectWithMinActual,
  total,
  calculateAverageDeviation
} from "../../../../../utils/utilsFunc";
import { Avatar, Dialog, Tab, Tabs } from "@mui/material";
import { selectCurrentLanguageId } from "../../../../../store/i18n/i18nThunks";
import { useTranslation } from "react-i18next";
import { enUS, kk } from "date-fns/locale";
import ModalBase from "../../../shared-component/ModalBase/ModalBase";
import FormExport from "../../../../UI/FormExport/FormExport";
import CustomTable from "../../../../UI/CustomTable/CustomTable";

function MonthOrYearPowerStats({ activeTab }) {
  const dispatch = useDispatch();
  const stats = useSelector((state) => state.dashboard.summary);
  const [typeData, setTypeData] = useState("chart");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openDataPicker, setOpenDataPicker] = useState(false);
  const currentLanguage = useSelector(selectCurrentLanguageId);
  const { t } = useTranslation("powerGenerationPage");
  const localeMap = {
    en: enUS,
    ru: ru,
    kz: kk,
  };

  const numberLocaleMap = {
    en: "en-US",
    ru: "ru-RU",
    kz: "kk-KZ",
  };

  const [calendarLocale, setCalendarLocale] = useState(
    localeMap[currentLanguage],
  );
  const [numberLocale, setNumberLocale] = useState(
    numberLocaleMap[currentLanguage],
  );
  const [openModalExport, setOpenModalExport] = useState(false);

  useEffect(() => {
    setCalendarLocale(localeMap[currentLanguage]);

    dispatch(
      loadStats({
        statBlock: "summary",
        statType: activeTab,
        date:
          activeTab === "monthly"
            ? format(selectedDate, "yyyy-MM")
            : format(selectedDate, "yyyy"),
      }),
    );
  }, [activeTab, selectedDate, dispatch, currentLanguage]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    dispatch(
      loadStats({
        statBlock: "summary",
        statType: activeTab,
        date:
          activeTab === "monthly"
            ? format(selectedDate, "yyyy-MM")
            : format(selectedDate, "yyyy"),
      }),
    );
  };

  const numOfNotFilledActualValues = stats[activeTab].data
    ?.find((item) => item.name === "Actual")
    ?.series.reduce(
      (accumulator, obj) =>
        obj.value !== null ? parseInt(accumulator) + 1 : accumulator,
      0,
    );

  const predictions =
    stats[activeTab] && Array.isArray(stats[activeTab].data)
      ? stats[activeTab].data?.find((item) => item.name === "Intech")
      : null;

  const actualDataPrediction = predictions
    ? predictions.series.slice(0, numOfNotFilledActualValues)
    : null;

  const totalActual = total(stats[activeTab].data, "Actual");
  const totalPrediction = total(stats[activeTab].data, "Intech");
  const totalPredictionForApd = actualDataPrediction?.reduce(
    (acc, val) => acc + parseInt(val.value),
    0,
  );

  const apd =
    totalActual !== 0
      ? Math.max(
          0,
          100 -
            (Math.abs(totalActual - totalPredictionForApd) /
              Math.abs((totalActual + totalPredictionForApd) / 2)) *
              100,
        ).toFixed(2)
      : 0;

  const renderErrorOrTheData = () => {
    return stats[activeTab].error ? (
      <div className="error title">
        <Avatar
          src={"/images/error.jpeg"}
          alt={"error"}
          sx={{ width: 150, height: 150 }}
        />
        <p style={{ color: "#666666" }}>{t("NO_DATA")}</p>
      </div>
    ) : typeData === "table" ? (
      <CustomTable
        data={stats[activeTab].data}
        type={activeTab}
        date={
          activeTab === "monthly"
            ? format(selectedDate, "MM / yyyy")
            : format(selectedDate, "yyyy")
        }
        totalActual={totalActual}
        apd={apd}
        day={false}
        t={t}
      />
    ) : (
      <BarDiagram data={stats[activeTab].data} activeTab={activeTab} t={t} />
    );
  };

  const getDataWidget = () => {
    const coReduceConst = 0.0006;
    const rate = 20;

    const data = stats[activeTab]?.data;

    let totalActual = 0;
    let validIntech = 0;
    let validActual = 0;
    const actualData = data?.find((item) => item.name === "Actual");
    const intechData = data?.find((item) => item.name === "Intech");
    if (actualData) {
      for (let i = 0; i < actualData.series.length; i++) {
        if (actualData.series[i]?.value != 0 && intechData.series[i]?.value != 0) {
          validIntech += parseFloat(intechData.series[i]?.value);
          validActual += parseFloat(actualData.series[i]?.value);
        }
        totalActual += parseFloat(actualData.series[i]?.value);
      }
    }
    let highestOutput = findObjectWithMaxAction(data);
    let lowestOutput = findObjectWithMinActual(data);

    const dateHighestOutput =
      activeTab === "monthly"
        ? `${highestOutput?.labels} ${format(selectedDate, "MMMM", {
            locale: calendarLocale,
          })}`
        : format(new Date(0, (highestOutput?.labels || null) - 1), "MMMM", {
            locale: calendarLocale,
          });

    const datelowestOutput =
      activeTab === "monthly"
        ? `${lowestOutput?.labels} ${format(selectedDate, "MMMM", {
            locale: calendarLocale,
          })}`
        : format(new Date(0, (lowestOutput?.labels || null) - 1), "MMMM", {
            locale: calendarLocale,
          });
    
    const accuracy = (1 - (Math.abs(validActual - validIntech) / validIntech)).toFixed(2) * 100;    
    
    let averageDeviation =
    totalActual !== 0
      ? calculateAverageDeviation(data).toFixed(2)
      : 0;

    return [
      {
        title: activeTab === "monthly" ? t("MONTH_ACCURACY") : t("YEAR_ACCURACY"),
        data: accuracy != 0 && !isNaN(accuracy) ? `${accuracy} %` : t('NO_ACTUAL_DATA'),
        dataName: ""
      },
      {
        title: activeTab === "monthly" ? t("MONTH_AVERAGE_ACCURACY") : t("YEAR_AVERAGE_ACCURACY"),
        data: averageDeviation != 0 && !isNaN(averageDeviation) ? `${Math.abs(averageDeviation)} %` : t('NO_ACTUAL_DATA'),
        dataName: ""
      },
      {
        title: activeTab === "monthly" ? t("TOTAL_MONTH") : t("TOTAL_YEAR"),
        data: totalActual?.toLocaleString(numberLocale),
        dataName: t("MONTH_OR_YEAR_UNIT"),
      },
      {
        title: t("HIGHEST"),
        data: Number(highestOutput?.actual).toLocaleString(numberLocale),
        dataName: t("MONTH_OR_YEAR_UNIT"),
        date: dateHighestOutput,
      },
      {
        title: t("LOWEST"),
        data: Number(lowestOutput?.actual).toLocaleString(numberLocale),
        dataName: t("MONTH_OR_YEAR_UNIT"),
        date: datelowestOutput,
      },
      {
        title: t("REVENUE"),
        data: (totalActual * rate * 1000).toLocaleString(numberLocale),
        dataName: "₸",
        date: t("REVENUE_DESCRIPTION", { tariff: "20" }),
      },
      {
        title: t("EMISSIONS_REDUCTION"),
        data: (totalActual * coReduceConst).toFixed(2).toLocaleString(
          numberLocale,
        ),
        dataName: t("EMISSIONS_ABBREVIATION"),
      },
    ];
  };

  return (
    <>
      <Grid container spacing={2}>
        {/* --------------- Tabs --------------- */}
        <Grid item xs={12} md={9}>
          <div className="tab-block" style={{ overflow: "auto" }}>
            <div className="date-block" style={{ width: "155px" }}>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={calendarLocale}
              >
                <DesktopDatePicker
                  sx={datePickerStyle}
                  views={activeTab === "monthly" ? ["month", "year"] : ["year"]}
                  value={selectedDate}
                  onYearChange={handleDateChange}
                  minDate={new Date("2021-01-01")}
                  maxDate={new Date()}
                  open={openDataPicker}
                  onClose={() => setOpenDataPicker(false)}
                  slotProps={{
                    textField: {
                      onClick: () => setOpenDataPicker(true),
                    },
                    OpenPickerIcon: CalendarTodayIcon,
                  }}
                  orientation="portrait"
                />
              </LocalizationProvider>
            </div>

            <div className="view-block">
              <Tabs
                value={typeData}
                onChange={(_, newValue) => setTypeData(newValue)}
                sx={dailyStateCalendar}
              >
                <Tab
                  label={t("CHART")}
                  value={"chart"}
                  style={{
                    backgroundColor: typeData === "chart" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabRight}
                />
                <Tab
                  label={t("TABLE")}
                  value={"table"}
                  style={{
                    backgroundColor: typeData === "table" ? "#E7EAF2" : "",
                  }}
                  sx={dailyStateCalendarTabLeft}
                />
              </Tabs>
            </div>
            <btn
              className="btn-download"
              onClick={() => setOpenModalExport(true)}
            >
              {t("EXPORT_TABLE")}
            </btn>

            <Dialog
              open={openModalExport}
              onClose={() => setOpenModalExport(false)}
              maxWidth="xs"
            >
              <ModalBase
                onClose={() => setOpenModalExport(false)}
                title={t("EXPORT_TABLE")}
              >
                <FormExport
                  date={selectedDate}
                  activeTab={
                    activeTab === "monthly" ? "selectMonth" : "selectYear"
                  }
                  isPlannedChecked={true}
                  isActualChecked={true}
                  isMeteologicaChecked={true}
                  t={t}
                  currentLocale={calendarLocale}
                />
              </ModalBase>
            </Dialog>
          </div>
          {/* ------------------------------------ */}

          {/* --------------- Diagram --------------- */}

          <div
            className="chart"
            style={{
              background: stats[activeTab].error
                ? ""
                : "rgba(243, 245, 250, 0.4)",
            }}
          >
            {stats[activeTab].loaded ? renderErrorOrTheData() : <Spinner />}
          </div>
        </Grid>
        {/* ---------------------------------------- */}

        {/* --------------- Widgets --------------- */}
        <Grid item xs={12} md={3}>
          <div className="widgets">
            {stats[activeTab].error
              ? null
              : getDataWidget().map((el, i) => {
                  return (
                    <Widget
                      key={i}
                      title={el.title}
                      data={el.data}
                      dataName={el.dataName}
                      date={el.date}
                    />
                  );
                })}
          </div>
        </Grid>

        {/* ---------------------------------------- */}
      </Grid>
    </>
  );
}

export default MonthOrYearPowerStats;
